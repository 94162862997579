import cx from 'classnames';
import { ChangeEvent } from 'react';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import styles from '../sass/components/EditorSelect.module.scss';

interface SelectProps {
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  errorMessage?: string;
  label: string;
  id: string;
  value: string;
  placeholder: string;
  loading: boolean;
  children: React.ReactNode;
  className?: string;
}

export default function CustomSelect({
  onChange,
  disabled,
  errorMessage,
  label,
  id,
  value,
  placeholder,
  children,
  loading,
  className,
}: SelectProps) {
  return (
    <label
      className={cx(styles.select, className)}
      htmlFor={id}
      style={{ width: '65%', marginTop: '2.5%' }}
    >
      <span className="label">
        {label}
      </span>
      <div className={cx(
        styles.selectWrapper,
        {
          [styles.disabled]: disabled,
          [styles.error]: errorMessage,

        },
      )}
      >
        <select
          id="model"
          value={value}
          disabled={disabled}
          className={cx({
            [styles.disabled]: disabled,
            [styles.error]: errorMessage,
          })}
          onChange={onChange}
        >
          <optgroup>
            <option
              value=""
              disabled
              key={placeholder}
            >
              {placeholder}
            </option>
            { loading && (
              <option value="loading">
                Loading...
              </option>
            )}
            {children}
          </optgroup>
        </select>
        <div className={styles.caretWrapper}>
          <Arrow className={cx(
            {
              [styles.disabled]: disabled,
            },
            styles.caret,
          )}
          />
        </div>
      </div>
      {errorMessage && (
        <div className={cx(styles.errorMessage, 'smallLabel')}>
          {errorMessage}
        </div>
      )}
    </label>
  );
}
