import { ListRowProps } from '../interfaces/Components';
import styles from '../sass/components/ListRow.module.scss';

export default function ListRow({
  title, children, list,
}: ListRowProps) {
  return (
    <div className={styles.detailsRowContainer}>
      <div className={styles.content}>
        <h4>{title}</h4>
        <ul>
          {list && list.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      {children}
    </div>
  );
}
