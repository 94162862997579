import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InvoiceState {
  id: number | null
}

const initialState: InvoiceState = {
  id: null,
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoiceId: (state, action: PayloadAction<number>) => ({
      ...state,
      id: action.payload,
    }),
  },
});

export const {
  setInvoiceId,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
