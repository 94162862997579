import cx from 'classnames';
import { ReactNode } from 'react';
import styles from '../sass/components/Tab.module.scss';

interface TabContainerProps {
  children?: ReactNode;
}

function TabContainer({ children }: TabContainerProps) {
  return (
    <div
      className={cx(styles.tabContainer, 'container--fluid', 'label')}
    >
      {children}
    </div>
  );
}

export default TabContainer;
