import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WorkOrderState {
  id: number | null
  unavailabilityReason: string | undefined
}

const initialState: WorkOrderState = {
  id: null,
  unavailabilityReason: undefined,
};

export const workOrderSlice = createSlice({
  name: 'workOrder',
  initialState,
  reducers: {
    setWorkOrderId: (state, action: PayloadAction<number>) => ({
      ...state,
      id: action.payload,
    }),
    setWorkOrderUnavailabilityReason:
    (state, action: PayloadAction<string>) => ({
      ...state,
      unavailabilityReason: action.payload,
    }),
  },
});

export const {
  setWorkOrderId,
  setWorkOrderUnavailabilityReason,
} = workOrderSlice.actions;

export default workOrderSlice.reducer;
