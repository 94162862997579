import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  ServiceType,
  addService,
  clearAllSelectedServices,
  removeService,
} from '../features/serviceTypes/serviceTypesSlice';
import styles from '../sass/components/RegularServiceList.module.scss';
import { GET_SERVICE_PRICES } from '../util/gql';
import { sortServices } from '../util/sortServices';
import Button from './Button';
import Loader from './Loader';
import ProductCard from './ProductCard';

export interface ServiceListProps {
  disabled: boolean,
}

export default function RegularServiceList() {
  const dispatch = useAppDispatch();
  const { id: vehicleId } = useAppSelector((state) => state.car);
  const countryCode = useAppSelector(
    (state) => state.serviceLocation.serviceAddress?.countryCode,
  );
  const selectedServices = useAppSelector((state) => (
    state.serviceTypes.services
  ));
  const { id: customerId } = useAppSelector((state) => state.customer);

  const [loadPrices, {
    loading, error, data, refetch,
  }] = useLazyQuery(GET_SERVICE_PRICES);

  useEffect(() => {
    if (countryCode !== '' && vehicleId !== null) {
      refetch({ vehicleId, countryCode });
      loadPrices({
        variables: {
          vehicleId,
          countryCode,
          customerId,
        },
      });
    }
  }, [countryCode, vehicleId, loadPrices, refetch, customerId]);

  const add = (service: ServiceType) => {
    dispatch(clearAllSelectedServices());
    dispatch(addService(service));
  };

  if (!vehicleId) {
    return (
      <div className={styles.empty}>
        Please select vehicle
      </div>
    );
  }

  if (loading) {
    return (
      <div className={styles.empty}>
        <Loader />
        Loading
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.empty}>
        Whoops, it looks like something went wrong.
        <Button
          className={styles.editButton}
          onClick={() => {
            loadPrices({
              variables: {
                countryCode,
                vehicleId,
              },
            });
          }}
        >
          Refresh
        </Button>
      </div>
    );
  }

  if (data?.getServicePrices?.length) {
    const sortedServices = sortServices(data.getServicePrices);
    return (
      <>
        {
        sortedServices.map((item: ServiceType) => (
          <ProductCard
            key={item.id}
            details={item.description}
            title={item.name}
            price={item.value}
            isEstimate={item.isEstimate}
            selected={selectedServices.some(
              (service) => service.id === item.id,
            )}
            onSelect={() => add(item)}
            onRemove={() => dispatch(removeService(item.id))}
            discountedPrice={item.discountedValue}
          />
        ))
        }
      </>
    );
  }

  return <div>theres nothing here!</div>;
}
