/* eslint-disable max-len */
export const ROUTE_ACCOUNT_ACCOUNT_PASSWORD = '/account/account-password';
export const ROUTE_ACCOUNT_APPOINTMENTS = '/account/appointments';
export const ROUTE_ACCOUNT_APPOINTMENTS_DETAIL = '/account/appointments/:id';
export const ROUTE_ACCOUNT_CARS = '/account/cars';
export const ROUTE_ACCOUNT_CUSTOMER_INFORMATION = '/account/customer-information';
export const ROUTE_ACCOUNT_ROOT = '/account';
export const ROUTE_AUTH_FORGOT_PASSWORD = '/auth/forgot-password';
export const ROUTE_AUTH_LOGIN = '/auth/login';
export const ROUTE_BOOK_ADD_ONS = '/book/select-add-ons';
export const ROUTE_BOOK_CONFIRMATION = '/book/confirmation';
export const ROUTE_BOOK_LOCATION = '/book/location';
export const ROUTE_BOOK_ROOT = '/book';
export const ROUTE_BOOK_SCHEDULE = '/book/schedule-book';
export const ROUTE_BOOK_SERVICES = '/book/select-services';
export const ROUTE_ROOT = '/';
export const ROUTE_FRANCHISE_MANAGER = '/account/franchisee';
export const ROUTE_FRANCHISE_MANAGER_WORK_ORDERS = '/account/franchisee/work-orders';
export const ROUTE_FRANCHISE_MANAGER_CUSTOMERS = '/account/franchisee/customers';
export const ROUTE_FRANCHISE_MANAGER_FRANCHISES = '/account/franchisee/franchises';
export const ROUTE_FRANCHISE_MANAGER_WORK_ORDER_DETAIL = '/account/franchisee/work-orders/:id';
export const ROUTE_FRANCHISE_MANAGER_CUSTOMER_DETAIL = '/account/franchisee/customers/:id';
export const ROUTE_FRANCHISE_MANAGER_INVOICES = '/account/franchisee/invoices';
export const ROUTE_FRANCHISE_MANAGER_INVOICE_DETAIL = '/account/franchisee/invoices/:id';
export const ROUTE_FRANCHISE_MANAGER_PAYOUTS = '/account/franchisee/payout';
export const ROUTE_FRANCHISE_MANAGER_PAYOUT_DETAIL = '/account/franchisee/payout/:id';
