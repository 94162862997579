import { ElementType, useState } from 'react';
import styles from '../sass/components/TableRow.module.scss';

interface TypeWithId {
  id: number;
}

export default function TableRow<Type extends TypeWithId>(props: {
  data: Type,
  index: number,
  isEditable?: boolean,
  RowViewComponent: ElementType,
  RowEditComponent: ElementType,
}) {
  const {
    data,
    index,
    isEditable,
    RowViewComponent,
    RowEditComponent,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean | undefined>(undefined);

  return (
    <div className={styles.wrapper}>
      {((isEditable && isEditing) || (!data.id && isEditing !== false))
          && (
          <RowEditComponent
            data={data}
            index={index}
            setIsEditing={() => setIsEditing(false)}
          />
          )}
      {data.id && (!isEditable || isEditing !== true)
          && (
          <RowViewComponent
            data={data}
            index={index}
            setIsEditing={() => setIsEditing(true)}
          />
          )}
    </div>
  );
}
