import Modal from '@mui/material/Modal';
import { ReactNode } from 'react';
import styles from '../sass/components/Modal.module.scss';

interface ModalProps {
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  disableBackdropClick?: boolean;
}

function WrappedModal({
  children,
  open,
  onClose,
  disableBackdropClick = false,
}: ModalProps) {
  const handleClose = (reason: string) => {
    if (disableBackdropClick) {
      if (reason !== 'backdropClick') {
        onClose();
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={(_event, reason) => handleClose(reason)}
    >
      <div className={styles.modal}>
        {children}
      </div>
    </Modal>
  );
}

export default WrappedModal;
