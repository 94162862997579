import Button from './Button';
import Modal from './Modal';
import ModalCloseButton from './ModalCloseButton';
import styles from '../sass/components/ErrorModal.module.scss';

interface ErrorModalProps {
  open: boolean
  onClose: () => void
  title: string
  message: string
}

export default function ErrorModal({
  open, onClose, title, message,
}: ErrorModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <div className={styles.modal}>
        <ModalCloseButton onClose={onClose} />
        <div className={styles.body}>
          <h4>{title}</h4>
          <p>{message}</p>
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.button}
              onClick={onClose}
              variant="primary"
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
