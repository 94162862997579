import { ElementType, useEffect, useState } from 'react';
import styles from '../sass/components/Table.module.scss';
import Button from './Button';
import TableRow from './TableRow';

interface TypeWithId {
  id: number;
}

export default function Table<Type extends TypeWithId>(props: {
  name: string,
  data: Type[],
  isEditable?: boolean,
  addAnother?: boolean,
  RowViewComponent: ElementType,
  RowEditComponent: ElementType,
}) {
  const {
    name, data, isEditable, RowViewComponent, RowEditComponent, addAnother,
  } = props;

  const [internalData, setInternalData] = useState<Type[]>(data);

  useEffect(() => {
    setInternalData(data);
  }, [data]);

  return (
    <div>
      {internalData?.map((d: Type, index: number) => (
        <TableRow
          key={d.id}
          data={d}
          index={index}
          isEditable={isEditable}
          RowViewComponent={RowViewComponent}
          RowEditComponent={RowEditComponent}
        />
      ))}
      {addAnother && (
      <div className={styles.tableAction} key="add-new-button">
        <Button
          variant="tertiary"
          onClick={() => setInternalData([...internalData, {} as Type])}
          key="new"
        >
          {`+ Add another ${name}`}
        </Button>
      </div>
      )}
    </div>
  );
}
