import { FormEvent, useState } from 'react';
import styles from '../sass/components/PasswordInput.module.scss';
import Button from './Button';
import TextInput from './TextInput';

export interface PasswordInputProps {
  autocomplete?: string;
  className?: string;
  errorMessage?: string;
  hint?: string;
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  title?: string;
  value?: string;
  disabled?: boolean;
}

export function PasswordInput({
  autocomplete,
  className,
  errorMessage,
  hint,
  onChange,
  placeholder,
  title,
  value,
  disabled,
}: PasswordInputProps) {
  const [show, setShow] = useState(false);

  const passwordShow = (
    <Button
      className={styles.passwordShow}
      onClick={() => setShow(!show)}
    >
      {show ? 'hide' : 'show'}
    </Button>
  );

  return (
    <TextInput
      autocomplete={autocomplete}
      className={className}
      errorMessage={errorMessage}
      hint={hint}
      onChange={onChange}
      placeholder={placeholder}
      rightButton={passwordShow}
      title={title}
      type={show ? 'text' : 'password'}
      value={value}
      disabled={disabled}
    />
  );
}
