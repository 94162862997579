import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  condenseTaxInformation, TaxInformation, taxMapper,
} from '../../util/taxCalculatorHelpers';

export interface AddOn {
  id: number;
  name: string;
  value: number;
  isEstimate: boolean;
  description: string;
  sortOrder: number | null;
  taxName: string;
  taxPercent: number;
  taxId: number;
  serviceTypeId: number;
  discountedValue?: number
}
export interface AddOnsState {
  addOns: AddOn[];
}

const initialState: AddOnsState = {
  addOns: [],
};

export const addOnsSlice = createSlice({
  name: 'serviceTypes',
  initialState,
  reducers: {
    addAddOn: (state, action: PayloadAction<AddOn>) => {
      state.addOns.push(action.payload);
    },
    removeAddOn: (state, action: PayloadAction<number>) => (
      {
        ...state,
        addOns: state
          .addOns
          .filter((addon) => addon.id !== action.payload),
      }
    ),
    clearAllAddOns: (state) => (
      {
        ...state,
        addOns: [],
      }
    ),
  },
});

export const selectAddOnTotal = (
  state: RootState,
) => (state.addOns.addOns.length
  ? state.addOns.addOns
    .map((addOn) => (
      addOn.discountedValue && Number.isFinite(addOn.discountedValue)
        ? addOn.discountedValue
        : addOn.value
    ))
    .reduce((prev, current) => prev + current)
  : 0);

export const selectAddOnTaxTypesAndAmounts = (
  state: RootState,
) => {
  const mappedTaxes: TaxInformation[] = state.addOns.addOns.length
    ? state.addOns.addOns.map((item) => taxMapper(item))
    : [];

  return condenseTaxInformation(mappedTaxes);
};

export const {
  addAddOn,
  removeAddOn,
  clearAllAddOns,
} = addOnsSlice.actions;

export const selectAddOns = (
  state: RootState,
) => state.addOns.addOns;

export default addOnsSlice.reducer;
