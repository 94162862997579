import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CustomerState {
  id?: number;
  name?: string;
}

const initialState: CustomerState = {
  name: '',
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerId: (state, action: PayloadAction<number>) => ({
      ...state,
      id: action.payload,
    }),
    setCustomerName: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      name: action.payload,
    }),
  },
});

export const {
  setCustomerId,
  setCustomerName,
} = customerSlice.actions;

export default customerSlice.reducer;
