import cx from 'classnames';
import { useEffect, useState } from 'react';
import { InvoiceDiscountProps } from '../interfaces/Components';
import styles from '../sass/components/InvoiceDiscount.module.scss';
import DiscountContainer from './DiscountContainer';
import ModalButtonsContainer from './ModalButtonsContainer';
import { LineItem } from '../interfaces/WorkOrder';

export default function InvoiceDiscount(
  {
    totalOriginal,
    workOrderDetails,
    setInvoiceDiscountDetails,
    onBack,
    onClick,
    selectedSubscription,
  }: InvoiceDiscountProps,
) {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1200);
  const [discountApplied, setDiscountApplied] = useState<boolean>(false);
  const [totalDiscounted, setTotalDiscounted] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string>(
    workOrderDetails?.userDiscountType
      ? workOrderDetails?.userDiscountType : '',
  );
  const [discountNum, setDiscountNum] = useState<number>(
    workOrderDetails?.userDiscountAmount
      ? parseInt(workOrderDetails?.userDiscountAmount, 10) : 0,
  );
  useEffect(() => {
    const resize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  const calculateDiscount = () => {
    let totalAfterDiscount = 0;
    if (discountType === 'percent') {
      const discountedItems = (
        totalOriginal * ((100 - (discountNum / 100)) / 100)
      );
      if (selectedSubscription) {
        const newTotal = discountedItems + (selectedSubscription.price / 100);
        totalAfterDiscount = parseFloat((newTotal).toFixed(2));
      } else {
        totalAfterDiscount = parseFloat(discountedItems.toFixed(2));
      }
      setTotalDiscounted(totalAfterDiscount);
    } else {
      setDiscountType('dollar');
      const discountedItems = totalOriginal - (discountNum / 100);
      totalAfterDiscount = parseFloat((discountedItems).toFixed(2));
      if (selectedSubscription) {
        const newTotal = discountedItems + (selectedSubscription.price / 100);
        totalAfterDiscount = parseFloat((newTotal).toFixed(2));
      }
      setTotalDiscounted(totalAfterDiscount);
    }
    if (discountNum > 0 && totalAfterDiscount >= 0) {
      setDiscountApplied(true);
      setInvoiceDiscountDetails(discountType, discountNum);
    }
  };

  const removeDiscount = () => {
    setDiscountApplied(false);
    setDiscountType(workOrderDetails?.userDiscountType
      ? workOrderDetails?.userDiscountType : '');
    setDiscountNum(workOrderDetails?.userDiscountAmount
      ? parseInt(workOrderDetails?.userDiscountAmount, 10) : 0);
    setInvoiceDiscountDetails('', 0);
  };

  const updatedTotal = () => {
    if (!selectedSubscription) return totalOriginal;
    return totalOriginal + (selectedSubscription.price / 100);
  };

  const getServicePrice = (
    { price, discountedPrice }: LineItem,
  ) => (
    discountedPrice && Number.isFinite(discountedPrice)
      ? discountedPrice / 100
      : price / 100
  );

  useEffect(() => {
    if (workOrderDetails?.userDiscountAmount
        && workOrderDetails?.userDiscountType) {
      calculateDiscount();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <h4>Step 6/6: Complete Order and Invoice</h4>
        <p>
          Completing this order will generate an invoice and send it
          to the customer. Do you wish to continue?
        </p>
      </div>
      <div className={styles.cardContainer}>
        <p className={styles.title}>
          Services performed
          {!isMobile && workOrderDetails?.vin ? `${' for '}
        ${workOrderDetails?.vin}` : ''}
        </p>
        <div className={cx('label', styles.cardContent)}>
          {
            workOrderDetails?.lineitems.map((item) => (
              <div key={item.name} className={styles.lineItem}>
                <p>{item.name}</p>
                <p className={styles.price}>
                  $
                  {getServicePrice(item)}
                </p>
              </div>
            ))
          }
          {selectedSubscription && (
            <div className={styles.lineItem}>
              <p>{selectedSubscription.aliasName}</p>
              <p className={styles.price}>
                $
                {selectedSubscription.price / 100}
              </p>
            </div>
          )}
        </div>
        <div className={cx('label', styles.cardContent)}>
          <div className={styles.lineItem_total}>
            <p>
              Total (before tax)
            </p>
            <p className={styles.price_total}>
              $
              {discountApplied ? totalDiscounted : updatedTotal()}
            </p>
          </div>
        </div>
        <DiscountContainer
          discountApplied={discountApplied}
          totalOriginal={totalOriginal}
          setDiscountNum={(e) => setDiscountNum(e)}
          discountType={discountType}
          discountNum={discountNum}
          setDiscountType={(e) => setDiscountType(e)}
          calculateDiscount={calculateDiscount}
          removeDiscount={removeDiscount}
          settingDefaultDiscount={false}
        />
      </div>
      <ModalButtonsContainer
        onClose={onBack}
        onClick={onClick}
        label="Complete"
      />
    </div>
  );
}
