import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import Badge from '../components/Badge';
import Button from '../components/Button';
import CarViewer from '../components/CarViewer';
import OrderSummary from '../components/OrderSummary';
import ServiceSelector from '../components/ServiceSelector';
import useAuthentication from '../hooks/useAuthentication';
import styles from '../sass/components/Services.module.scss';
import { ROUTE_BOOK_SCHEDULE, ROUTE_BOOK_SERVICES } from '../util/constants';

export default function Services() {
  const navigate = useNavigate();
  const { handleLogin, loggedIn } = useAuthentication();

  const selectedAddOns = useAppSelector((state) => (
    state.addOns.addOns
  ));

  const handleBack = () => {
    navigate(ROUTE_BOOK_SERVICES);
  };

  const handleNextClick = () => {
    navigate(ROUTE_BOOK_SCHEDULE);
  };

  return (
    <div className={styles.services}>
      <div className={styles.header}>
        <h1 className={styles.headerDesktop}>Select Add-Ons</h1>
        <h4 className={styles.headerMobile}>Select Add-Ons</h4>
        <Button
          variant="secondary"
          onClick={handleLogin}
        >
          {loggedIn ? 'Log Out' : 'Log In'}
        </Button>
      </div>
      <div className={cx(styles.body, 'container--fluid')}>
        <div className={cx(styles.bodyLeft, 'container__col-xs-4')}>
          <div className={styles.carSelectorBox}>
            <CarViewer />
          </div>
          <div className={styles.selectedServicesBox}>
            <OrderSummary onlyServices />
          </div>
        </div>
        <div className={cx(
          styles.bodyRight,
          'container__col-xs-8',
        )}
        >
          <ServiceSelector type="secondary" />
        </div>
      </div>
      <div className={styles.footer}>
        <Badge
          variant="secondary"
          className={styles.countBadge}
        >
          {selectedAddOns.length}
          {' '}
          Add-ons Selected
        </Badge>
        <Button
          className={styles.editButton}
          onClick={handleBack}
          variant="tertiary"
        >
          Back
        </Button>
        <Button
          className={styles.editButton}
          variant="secondary"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
