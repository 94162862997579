import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PayoutReportState {
  id: number | null
  unavailabilityReason: string | undefined
}

const initialState: PayoutReportState = {
  id: null,
  unavailabilityReason: undefined,
};

export const payoutReportSlice = createSlice({
  name: 'payoutReport',
  initialState,
  reducers: {
    setPayoutReportId: (state, action: PayloadAction<number>) => ({
      ...state,
      id: action.payload,
    }),
  },
});

export const {
  setPayoutReportId,
} = payoutReportSlice.actions;

export default payoutReportSlice.reducer;
