import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import QRCode from 'qrcode.react';
import { GetCashAppPaymentProps } from '../interfaces/Components';
import styles from '../sass/components/SendPaymentToTerminal.module.scss';
import { PAY_CASH_APP, GET_INVOICE } from '../util/gql';
import Button from './Button';
import Loader from './Loader';
import Modal from './Modal';
import ModalCloseButton from './ModalCloseButton';

export default function GetCashAppPayment({
  invoiceDetails,
  onClose,
  setErrorModal,
  open,
  isPaid,
  setIsPaid,
  setSuccessModal,
}: GetCashAppPaymentProps) {
  const [cashAppModal, setCashAppModal] = useState<boolean>(false);
  const [currentQRCode, setCurrentQRCode] = useState<string>('');
  const [getCode, setGetCode] = useState<boolean>(false);
  const invoiceId = invoiceDetails?.id;
  const [payCashApp, { loading: payCashAppLoading }] = useMutation(PAY_CASH_APP, {
    onCompleted: (data) => {
      if (data && data.payCashApp) {
        setCurrentQRCode(data.payCashApp.label);
        setCashAppModal(true);
      }
    },
    onError() {
      setErrorModal();
    },
    fetchPolicy: 'no-cache',
  });

  const getQRCode = () => {
    setGetCode(true);
    payCashApp({
      variables: { invoiceId: invoiceDetails?.id },
    });
  };

  const [getInvoiceStatus] = useLazyQuery(GET_INVOICE, {
    onCompleted: (data) => {
      if (data && data.getInvoiceById.status === 'PAID') {
        setIsPaid(true);
        onClose();
        setSuccessModal();
      }
    },
    onError() {
      onClose();
      setErrorModal();
    },
    fetchPolicy: 'network-only',
  });
  const handleSubmit = () => {
    getInvoiceStatus({ variables: { invoiceId } });
  };

  const ModalContainer = () => (
    <div className={styles.modalContainer}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Cash App Payment</h4>
        <p>
          {' '}
          Please wait until the customer confirms
          payment before clicking on check pament
        </p>
        {getCode === true && (
          <>
            <div className={styles.qrCodeContainer} />
            <div className={styles.qrCodeContainer}>
              <QRCode value={currentQRCode} />
            </div>
          </>
        )}
        {getCode === false && (
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.buttonQRcode}
              variant="secondary"
              onClick={getQRCode}
            >
              Get QR Code
            </Button>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.button}
              onClick={onClose}
              variant="tertiary"
            >
              Close
            </Button>
            <Button className={styles.button} type="submit">
              Check Payment Status
            </Button>
          </div>
        </form>

      </div>
    </div>
  );

  const SendPaymentToTerminalForm = () => (
    <Modal open={open} onClose={onClose} disableBackdropClick>
      {payCashAppLoading ? <Loader /> : <ModalContainer />}
    </Modal>
  );

  return <SendPaymentToTerminalForm />;
}
