import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  currentUser: {
    [key: string]: any,
  };
  isAuthenticated: boolean;
  error: string;
}

const initialState: AuthState = {
  currentUser: {},
  isAuthenticated: false,
  error: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<any>) => ({
      ...state,
      currentUser: action.payload,
      isAuthenticated: true,
    }),
    logout: (state) => ({
      ...state,
      isAuthenticated: false,
      currentUser: {},
      error: '',
    }),
  },
});

export const {
  login,
  logout,
} = authSlice.actions;

export default authSlice.reducer;
