import { PropsWithChildren } from 'react';
import styles from '../sass/components/Field.module.scss';

export default function Field(
  {
    id,
    label,
    children,
  }: PropsWithChildren<{
    id: string;
    label: string;
  }>,
) {
  return (
    <label htmlFor={id} className={styles.field}>
      <span className={styles.label}>{label}</span>
      {children}
    </label>
  );
}
