/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { EditVehicleEngineProps } from '../interfaces/Components';
import styles from '../sass/components/LineItemEditor.module.scss';
import ModalButtonsContainer from './ModalButtonsContainer';
import Select from './EditorSelect';

export default function VehicleEngineEditor({
  onClose,
  onSubmit,
  workOrderDetails,
  setWorkOrderDetails,
  vehicles,
  vehicleId,
  setVehicleId,
}: EditVehicleEngineProps) {
  return (
    <div className={styles.body}>
      <h4>Edit Vehicle Engine</h4>
      <p>Engines:</p>
      <div className={styles.container}>
        <Select
          label=""
          value={vehicleId.toString()}
          loading={false}
          id="engine-input"
          placeholder="Select engine."
          onChange={(e) => setVehicleId(parseInt(e.currentTarget.value, 10))}
        >
          {vehicles.map((item) => (
            <option key={item.id} value={item.id}>
              {item.engine}
            </option>
          ))}
        </Select>
      </div>
      <br />
      <ModalButtonsContainer
        onClose={onClose}
        onClick={onSubmit}
        label="Submit"
      />
    </div>
  );
}
