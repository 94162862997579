import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import styles from '../sass/components/VehicleInformation.module.scss';
import { FIND_ALL_USER_VEHICLES_BY_CURRENT_USER } from '../util/gql';
import Table from './Table';
import VehicleRowEdit from './VehicleRowEdit';
import VehicleRowView from './VehicleRowView';
import { Vehicle } from '../interfaces/Vehicle';
import { UserVehicle } from '../interfaces/UserVehicle';

export default function VehicleInformation() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);

  const { data } = useQuery(FIND_ALL_USER_VEHICLES_BY_CURRENT_USER, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      const arr: Vehicle[] = [];
      data.findAllUserVehiclesByCurrentUser.forEach((item:UserVehicle) => {
        const v: Vehicle = {
          id: item.vehicle.id,
          make: item.vehicle.make,
          model: item.vehicle.model,
          year: item.vehicle.year,
          userVehicleId: item.id,
          vin: item.vin,
          name: item.name,
          recommendedOil: item.vehicle.recommendedOil,
          oilVolume: item.vehicle.oilVolume,
          ...item.mileage
            && item.mileage.length > 0
            && { mileage: item.mileage[0].mileage },
        };
        arr.push(v);
      });
      setVehicles(arr);
    }
  }, [data]);

  return (
    <div className={styles.vehicleInformation}>
      <div className={styles.header}>
        <h4>Vehicle Information</h4>
        <p>
          Add your vehicle information to get an exact quote for our services.
        </p>
      </div>
      <Table
        name="vehicle"
        data={vehicles}
        RowViewComponent={VehicleRowView}
        RowEditComponent={VehicleRowEdit}
        addAnother
      />
    </div>
  );
}
