import { AuthState } from '../features/auth/authSlice';
import { PostalCodeState } from '../features/postalCode/postalCodeSlice';

export const loadState = () => {
  try {
    const serilaizedState = localStorage.getItem('state');
    if (serilaizedState === null) {
      return undefined;
    }
    return JSON.parse(serilaizedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (
  state: {
    postalCode: PostalCodeState,
    auth: AuthState
  },
) => {
  try {
    const serilaizedState = JSON.stringify(state);
    localStorage.setItem('state', serilaizedState);
  } catch (error) {
    // ignore, maybe log
  }
};
