import cx from 'classnames';
import { ReactNode } from 'react';
import styles from '../sass/components/Button.module.scss';
import Loader from './Loader';

interface ButtonProps {
  onClick?: () => void;
  size?: 'small' | 'large';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'icon' | 'link',
  children?: ReactNode;
  loading?: boolean;
  inactive?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  error?: boolean;
}

interface ButtonClusterProps {
  children?: ReactNode;
}

export function ButtonCluster({ children }: ButtonClusterProps) {
  return <div className={styles.buttonGroup}>{children}</div>;
}

function Button({
  onClick,
  size = 'small',
  variant = 'primary',
  error = false,
  children,
  loading,
  inactive,
  type = 'button',
  className,
}: ButtonProps) {
  return (
    <button
      className={cx(
        styles.button,
        styles[variant],
        styles[size],
        {
          [styles.inactive]: inactive,
          [styles.error]: error,
        },
        'label',
        className,
      )}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={inactive}
    >
      {loading && <Loader />}
      {children}
    </button>
  );
}

export default Button;
