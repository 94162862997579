/* eslint-disable jsx-a11y/label-has-associated-control */
import cx from 'classnames';
import _uniqueId from 'lodash/uniqueId';
import { useState } from 'react';
import styles from '../sass/components/Radio.module.scss';

interface RadioProps {
  subtitleLabels?: string[],
  name: string,
  value: string,
  id: string,
  label: string,
  onChange: (value: string) => void
  checked?: boolean | undefined,
  className?: string,
  disabled?: boolean,
  radioLabel?: string
}

export default function CustomRadio({
  subtitleLabels,
  name,
  value,
  id,
  label,
  onChange,
  checked,
  className,
  disabled,
  radioLabel,
}: RadioProps) {
  const [radioId] = useState(_uniqueId('radio-'));
  return (
    <div className={cx(styles.radioItem, className)}>
      <input
        type="radio"
        id={`${radioId}-${id}`}
        name={name}
        value={value}
        onChange={() => onChange(value)}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={`${radioId}-${id}`} className={radioLabel}>
        {label}
      </label>
      <p className={styles.radioItem_subtitle}>
        {(subtitleLabels ? subtitleLabels[parseInt(value, 10)] : '')}
      </p>
    </div>
  );
}
