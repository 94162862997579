import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthentication from '../hooks/useAuthentication';
import { Appointment } from '../interfaces/WorkOrder';
import styles from '../sass/components/UpcomingAppointments.module.scss';
import { ROUTE_ROOT } from '../util/constants';
import { GET_WORK_ORDERS } from '../util/gql';
import AppointmentRow from './ApptRow';
import Button from './Button';
import EmptyList from './EmptyList';

export default function UpcomingAppointments() {
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const navigate = useNavigate();
  const { loggedIn: isLoggedIn } = useAuthentication();

  const [getWorkOrders, { loading }] = useLazyQuery(GET_WORK_ORDERS, {
    onCompleted: (data) => {
      setAppointments(data.getWorkOrders);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isLoggedIn) {
      getWorkOrders({ variables: { status: 'upcoming' } });
    }
  }, [isLoggedIn, getWorkOrders]);

  const content = appointments.length > 0 ? (
    <div>
      {appointments.map((appt) => (
        <div key={appt.id}>
          <AppointmentRow
            workOrderId={appt.id}
            vehicle={`${
              appt.vehicle.year} ${
              appt.vehicle.make} ${
              appt.vehicle.model}`}
            scheduledAt={appt.scheduledAt}
            items={appt.lineItems}
            tab="upcoming"
          />
        </div>

      ))}
    </div>
  ) : (
    <EmptyList msg="No upcoming appointments.">
      <div className={styles.buttonWrapper}>
        <Button
          className={styles.bookButton}
          onClick={() => navigate(ROUTE_ROOT)}
        >
          Book A Service
        </Button>
      </div>
    </EmptyList>
  );

  return (
    <div className={styles.upcomingAppts}>
      {loading ? <p className={styles.loading}>Loading...</p> : content}
    </div>
  );
}
