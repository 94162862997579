import { useQuery } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { User } from '../../interfaces/User';
import styles from '../../sass/components/AddPaymentMethodWidget.module.scss';
import { GET_CUSTOMER_PAYMENT_METHODS } from '../../util/gql';
import Button from '../Button';
import Modal from '../Modal';
import CreditCardElement from './stripeWidget/CreditCardElement';

interface CustomerVendor {
  id?: number;
  externalCustomerVendorId?: string;
}

interface Card {
  last4: string;
  expYear: number;
  brand: string;
}

interface AddPaymentMethodWidgetProps {
  userData: User,
  customerVendor: CustomerVendor,
  countryCode: string
}

export default function AddPaymentMethodWidget(
  props: AddPaymentMethodWidgetProps,
) {
  const { userData, customerVendor, countryCode } = props;
  const [paymentMethodModal, setPaymentMethodModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [apiKey, setApiKey] = useState<string | null>();
  const promise = apiKey && loadStripe(apiKey);

  const getPaymentMethods = useQuery(GET_CUSTOMER_PAYMENT_METHODS, {
    skip: customerVendor === null,
    variables: {
      customerVendorId: customerVendor?.externalCustomerVendorId,
      countryAccount: countryCode,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (countryCode === 'CA') {
      setApiKey(process.env.REACT_APP_CANADA_STRIPE_API_KEY);
    }
    if (countryCode === 'US') {
      setApiKey(process.env.REACT_APP_USA_STRIPE_API_KEY);
    }
  }, [countryCode]);

  useEffect(() => {
    if (getPaymentMethods?.data) {
      setPaymentMethods(getPaymentMethods?.data?.getCustomerPaymentMethods);
    }
  }, [getPaymentMethods]);

  return (
    <div>
      {customerVendor && (paymentMethods.length !== 0)
        ? (
          <div>
            {paymentMethods.length !== 0
              && paymentMethods.map((card: Card) => (
                <span id={card.last4}>
                  {card.brand.toUpperCase()}
                  {' '}
                  {`****${card.last4}`}
                  {' - '}
                  {' '}
                  Exp:
                  {card.expYear}
                </span>
              ))}
          </div>
        )
        : (
          <Button
            variant="tertiary"
            onClick={() => setPaymentMethodModal(true)}
          >
            + Add payment method
          </Button>
        )}
      <Modal
        open={paymentMethodModal}
        onClose={() => setPaymentMethodModal(false)}
        disableBackdropClick
      >
        <div className={styles.paymentModalBody}>
          <div className={styles.paymentModalHeader}>
            <h4>Add payment method</h4>
          </div>
          <div>
            {apiKey && userData && promise && (
            <Elements stripe={promise}>
              <CreditCardElement
                userData={userData}
                countryCode={countryCode}
              />
            </Elements>
            )}
            <div className={styles.cancelContainer}>
              <Button
                onClick={() => setPaymentMethodModal(false)}
                variant="tertiary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
