import cx from 'classnames';
import { ChangeEventHandler } from 'react';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import styles from '../sass/components/Selector.module.scss';

const Selector = ({
  label,
  id,
  disabled,
  value,
  options,
  onChange,
  name,
  isRequired,
}: {
  label: string;
  id: string;
  disabled?: boolean;
  value: string | number | undefined;
  options: { value: string | number | undefined; label: string | number }[];
  onChange: ChangeEventHandler<HTMLSelectElement>
  name?: string
  isRequired?: boolean
}) => (
  <div className={styles.select}>
    <div className={cx(
      styles.selectWrapper,
      {
        [styles.disabled]: disabled,
      },
    )}
    >
      <select
        id={id}
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={cx({
          [styles.disabled]: disabled,
        })}
        name={name}
        required={isRequired}
      >
        <optgroup>
          <option key={`select-${id}`} value="" disabled>
            Select
            {' '}
            {label}
          </option>
          {options.map((o) => (
            <option
              key={o.value}
              value={o.value}
            >
              {o.label}
            </option>
          ))}
        </optgroup>
      </select>
      <div className={styles.caretWrapper}>
        <Arrow className={cx(
          styles.caret,
          {
            [styles.disabled]: disabled,
          },
        )}
        />
      </div>
    </div>
  </div>
);

export default Selector;
