import styles from '../sass/components/ModalButtonsContainer.module.scss';
import Button from './Button';

interface ModalButtonsContainerProps {
  onClose: () => void
  onClick: () => void
  inactive?: boolean
  label?: string
}

export default function ModalButtonsContainer(
  {
    onClose, onClick, inactive, label,
  }: ModalButtonsContainerProps,
) {
  return (
    <div className={styles.buttonsContainer}>
      <Button
        className={styles.button}
        variant="tertiary"
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        className={styles.button}
        variant="primary"
        onClick={onClick}
        inactive={inactive}
      >
        {label}
      </Button>
    </div>
  );
}
