import ChevronLeft from '@mui/icons-material/ChevronLeft';
import styles from '../sass/components/MessageSentModal.module.scss';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';

interface MessageSentModalProps {
  onClose: () => void;
}

export default function MessageSentModal({ onClose }: MessageSentModalProps) {
  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.header}>
        <Button
          variant="tertiary"
          onClick={onClose}
        >
          <ChevronLeft />
          Back to Booking
        </Button>
      </div>
      <div className={styles.body}>
        <h4>Message successfully sent!</h4>
        <p>
          The team at Go Oil will get in touch
          with you soon to see what we can do.
        </p>
        <Button className={styles.button} onClick={onClose}>
          Back to Booking
        </Button>
      </div>
    </div>
  );
}
