import { useMutation } from '@apollo/client';
import { SuccessModalProps } from '../interfaces/Components';
import styles from '../sass/components/ErrorModal.module.scss';
import Button from './Button';
import Modal from './Modal';
import ModalCloseButton from './ModalCloseButton';

export default function SuccessModal({
  onClose, title, message, open, invoiceId,
}: SuccessModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <div className={styles.modal}>
        <ModalCloseButton onClose={onClose} />
        <div className={styles.body}>
          <h4>{title}</h4>
          <p>{message}</p>
        </div>
      </div>
    </Modal>
  );
}
