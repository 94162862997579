import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import Plus from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Search } from '../images/search.svg';
import { User } from '../interfaces/User';
import styles from '../sass/components/CustomersInformation.module.scss';
import { GET_CUSTOMERS_OF_FRANCHISE_MANAGER } from '../util/gql';
import CustomerRowView from './CustomerRowView';
import Table from './Table';
import TextInput from './TextInput';
import VehicleRowEdit from './VehicleRowEdit';
import { useAppSelector } from '../app/hooks';
import Button from './Button';
import Modal from './Modal';
import { ROUTE_BOOK_LOCATION } from '../util/constants';

export default function CustomersInformation() {
  const [customers, setCustomers] = useState<User[]>([]);
  const [filter, setFilter] = useState<string>('');
  const franchiseIdSelect = useAppSelector((state) => state.franchise.id);

  const { franchiseId } = useAppSelector((state) => state.auth.currentUser);

  const navigate = useNavigate();
  const {
    data,
  } = useQuery(GET_CUSTOMERS_OF_FRANCHISE_MANAGER, {
    variables: { franchiseId: franchiseIdSelect || franchiseId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data) return;
    if (!filter) {
      setCustomers(data.getCustomersOfFranchiseManager);
      return;
    }
    const filteredUsers = data.getCustomersOfFranchiseManager.filter(
      (user: User) => user.name.toLowerCase().includes(
        filter.toLowerCase(),
      ),
    );
    setCustomers(filteredUsers);
  }, [data, filter]);

  const handleNewBooking = () => {
    navigate(ROUTE_BOOK_LOCATION);
  };

  return (
    <div className={styles.customersInformation}>
      <div className={styles.headerContainer}>
        <div className={styles.searchBar}>
          <div className={styles.magnifyingGlass}>
            <Search />
          </div>
          <TextInput
            placeholder="Search Customer Name"
            onChange={(e) => setFilter(e.currentTarget.value)}
          />
        </div>

        <Button
          variant="secondary"
          className={styles.addCustomer}
          onClick={() => handleNewBooking()}
        >
          <Plus />
          {' '}
          Book New Customer
        </Button>
      </div>
      <Table
        name="user"
        data={customers}
        addAnother={false}
        RowViewComponent={CustomerRowView}
        RowEditComponent={VehicleRowEdit}
      />
    </div>
  );
}
