import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import useAuthentication from '../hooks/useAuthentication';
import { Appointment } from '../interfaces/WorkOrder';
import styles from '../sass/components/PastAppointments.module.scss';
import { GET_WORK_ORDERS } from '../util/gql';
import ApptRow from './ApptRow';
import EmptyList from './EmptyList';

export default function PastAppointments() {
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const { loggedIn: isLoggedIn } = useAuthentication();

  const [getWorkOrders, { loading }] = useLazyQuery(GET_WORK_ORDERS, {
    onCompleted: (data) => {
      setAppointments(data.getWorkOrders);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isLoggedIn) {
      getWorkOrders({ variables: { status: 'past' } });
    }
  }, [isLoggedIn, getWorkOrders]);

  const content = appointments.length > 0 ? (
    <div className={styles.appointment}>
      {appointments.map((appt) => (
        <div className={styles.apptRow}>
          <ApptRow
            workOrderId={appt.id}
            vehicle={`${
              appt.vehicle.year} ${
              appt.vehicle.make} ${
              appt.vehicle.model}`}
            scheduledAt={appt.scheduledAt}
            items={appt.lineItems}
            tab="past"
          />
        </div>
      ))}
    </div>
  ) : (
    <EmptyList msg="No past appointments." />
  );

  return (
    <div className={styles.pastAppts}>
      {loading
        ? <p className={styles.loading}>Loading...</p> : content}
    </div>
  );
}
