import cx from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

export function Container(
  {
    fluid,
    children,
  }: PropsWithChildren<{
    fluid?: boolean;
  }>,
) {
  return (
    <div
      className={cx(
        'container',
        { 'container--fluid': fluid },
      )}
    >
      {children}
    </div>
  );
}

export function Row(
  {
    children,
  }: { children: ReactNode },
) {
  return (
    <div
      className="container__row"
    >
      {children}
    </div>
  );
}

export function Column(
  {
    xs,
    sm,
    md,
    lg,
    xl,
    xsOffset,
    smOffset,
    mdOffset,
    lgOffset,
    xlOffset,
    hiddenDown,
    hiddenUp,
    children,
  }: PropsWithChildren<{
    xs?:number;
    sm?:number;
    md?: number;
    lg?: number;
    xl?: number;
    xsOffset?: number;
    smOffset?: number;
    mdOffset?: number;
    lgOffset?: number;
    xlOffset?: number;
    hiddenDown?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    hiddenUp?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  }>,
) {
  return (
    <div
      className={cx({
        [`container__col-xs-${xs}`]: xs,
        [`container__col-sm-${sm}`]: sm,
        [`container__col-md-${md}`]: md,
        [`container__col-lg-${lg}`]: lg,
        [`container__col-xl-${xl}`]: xl,
        [`container__col-xs-offset-${xsOffset}`]: xsOffset,
        [`container__col-sm-offset-${smOffset}`]: smOffset,
        [`container__col-md-offset-${mdOffset}`]: mdOffset,
        [`container__col-lg-offset-${lgOffset}`]: lgOffset,
        [`container__col-xl-offset-${xlOffset}`]: xlOffset,
        [`hidden-${hiddenDown}-down`]: hiddenDown,
        [`hidden-${hiddenUp}-up`]: hiddenUp,
      })}
    >
      {children}
    </div>
  );
}
