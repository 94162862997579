import { TabDictionary } from '../interfaces/TabDictionary';
import InformationSection from './InformationSection';
import PastAppointments from './PastAppointments';
import UpcomingAppointments from './UpcomingAppointments';

export default function AppointmentsInformation() {
  const tabs: TabDictionary = {
    upcomingAppts: {
      desktopValue: 'Upcoming Appointments',
      mobileValue: 'Upcoming',
      component: <UpcomingAppointments />,
    },
    pastAppts: {
      desktopValue: 'Past Appointments',
      mobileValue: 'Past',
      component: <PastAppointments />,
    },
  };

  return <InformationSection tabs={tabs} initialState="upcomingAppts" />;
}
