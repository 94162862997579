import { ApolloError, useMutation } from '@apollo/client';
import {
  AddressElement,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import useAuthentication from '../../../hooks/useAuthentication';
import { User } from '../../../interfaces/User';
import styles from '../../../sass/components/CreditCardElement.module.scss';
import {
  CREATE_PAYMENT_METHOD_VENDOR,
  FIND_OR_CREATE_STRIPE_CUSTOMER,
} from '../../../util/gql';
import Alert from '../../Alert';
import Button from '../../Button';

interface CustomerBilling {
  name: string;
  address: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    // eslint-disable-next-line camelcase
    postal_code: string;
    state: string;
  }
}

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

interface CreditCardElementProps {
  userData: User,
  countryCode: string
}

export default function CreditCardElement(props: CreditCardElementProps) {
  const { userData, countryCode } = props;
  const { loggedIn: isLoggedIn } = useAuthentication();
  const [
    customerBilling,
    setCustomerBilling,
  ] = useState<CustomerBilling | null>(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<any>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  const handleCardElement = async (event:any) => {
    if (event.complete) {
      setCardComplete(true);
    } else {
      setCardComplete(false);
    }
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleAddress = (event: any) => {
    if (event.complete) {
      setCustomerBilling(event.value);
    } else {
      setCustomerBilling(null);
    }
  };

  const catchApolloErrors = (ex: any) => {
    if (ex instanceof ApolloError) {
      const {
        statusCode,
        err,
      } = ex.graphQLErrors[0].extensions.response;
      if (statusCode === 422 || statusCode === 400) {
        setError(err);
      }
    }
  };

  const [findOrCreateStripeCustomer] = useMutation(
    FIND_OR_CREATE_STRIPE_CUSTOMER,
  );

  const [createPaymentMethodVendor] = useMutation(CREATE_PAYMENT_METHOD_VENDOR);

  // eslint-disable-next-line consistent-return
  const handleSaveCustomerProfile = async () => {
    try {
      const stripeCustomerData = await findOrCreateStripeCustomer({
        variables: {
          customerVendorInput: {
            email: userData.email,
            name: customerBilling?.name,
            vendorName: 'stripe',
            postalCode: customerBilling?.address.postal_code,
            city: customerBilling?.address.city,
            state: customerBilling?.address.state,
            country: customerBilling?.address.country,
          },
          userId: userData.id,
        },
      });
      if (stripeCustomerData) {
        return stripeCustomerData;
      }
    } catch (ex) {
      catchApolloErrors(ex);
    }
  };

  const handleSavePaymentMethod = async (
    externalTokenId: string,
    externalCustomerId: string,
  ) => {
    try {
      await createPaymentMethodVendor({
        variables: {
          paymentMethodVendorInput: {
            externalTokenId,
            externalCustomerId,
          },
          userId: userData.id,
        },
      });
    } catch (ex) {
      catchApolloErrors(ex);
    }
  };

  const handleSaveCard = async () => {
    setProcessing(true);
    const stripeCustomerData = await handleSaveCustomerProfile();
    const cardElement = elements?.getElement(CardElement);

    try {
      if (cardElement) {
        const tokenData = await stripe?.createToken(cardElement);
        if (tokenData?.error) {
          setError(tokenData.error);
          setProcessing(false);
          setSucceeded(false);
        } else if (tokenData) {
          await handleSavePaymentMethod(
            tokenData?.token.id,
            stripeCustomerData?.data
              .findOrCreateStripeCustomer
              .externalCustomerVendorId,
          );
          setError(null);
          setProcessing(false);
          setSucceeded(true);
          window.location.reload();
        }
      }
    } catch (err: any) {
      setError(err?.message);
    }
  };

  return (
    <form id="payment-form">
      {isLoggedIn && (
        <div>
          <AddressElement
            onChange={(event) => handleAddress(event)}
            options={{
              mode: 'billing',
              allowedCountries: [countryCode],
            }}
          />
          <br />
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleCardElement}
          />
          {error && (
          <div>
            <Alert message={error.message} />
          </div>
          )}
          <br />
          <span className={styles.saveButton}>
            <Button
              type="button"
              variant="primary"
              inactive={
                processing
                || disabled
                || succeeded
                || customerBilling === null
                || cardComplete === false
              }
              onClick={() => handleSaveCard()}
            >
              <span id="button-text">
                {processing ? (
                  <div className="spinner" id="spinner" />
                ) : (
                  'Save Card'
                )}
              </span>
            </Button>
          </span>
        </div>
      )}
    </form>
  );
}
