import cx from 'classnames';
import { useAppSelector } from '../app/hooks';
import { selectAddOnTotal } from '../features/addOns/addOnsSlice';
import {
  selectServicesTotal,
} from '../features/serviceTypes/serviceTypesSlice';
import styles from '../sass/components/Invoice.module.scss';

interface InvoiceProps {
  className?: string;
  showDisclaimer?: boolean;
  condense?: boolean;
  onlyServices?: boolean;
}

export default function Invoice({
  className,
  showDisclaimer = true,
  condense,
  onlyServices = false,
}: InvoiceProps) {
  const setServiceValue = (
    value: number, discountedValue?: number,
  ) => (
    discountedValue && Number.isFinite(discountedValue)
      ? discountedValue
      : value
  );

  const selectedServices = (useAppSelector((state) => (
    state.serviceTypes.services
  )))
    .map((selectedService) => ({
      ...selectedService,
      value: setServiceValue(
        selectedService.value,
        selectedService.discountedValue,
      ),
    }));

  const selectedAddOns = useAppSelector((state) => (
    state.addOns.addOns
  )).map((selectedAddOn) => ({
    ...selectedAddOn,
    value: setServiceValue(selectedAddOn.value, selectedAddOn.discountedValue),
  }));

  const serviceTotal = useAppSelector((
    state,
  ) => selectServicesTotal(state));

  const addOnTotal = useAppSelector((state) => selectAddOnTotal(state));

  const make = useAppSelector((state) => (
    state.car.make
  ));
  const model = useAppSelector((state) => (
    state.car.model
  ));
  const year = useAppSelector((state) => (
    state.car.year
  ));

  return (
    <div key="invoice" className={cx(styles.invoice, className)}>
      <div
        key="summary"
        className={cx(styles.summarySection, styles.card, {
          [styles.condenseSections]: condense,
        })}
      >
        { !!selectedServices.length && (
          <div key="services" className={styles.cardSection}>
            {!onlyServices
              && (
              <span
                className={cx('label', styles.cardHeader)}
              >
                Services:
              </span>
              )}
            <div className={cx('label', styles.cardContent)}>
              {
                selectedServices.map((service) => (
                  <div key={service.id} className={styles.lineItem}>
                    <p>
                      &bull;
                      &nbsp;
                      &nbsp;
                      {service.name}
                    </p>
                    <p>
                      $
                      {service.value.toFixed(2)}
                    </p>
                  </div>
                ))
              }
            </div>
          </div>
        )}
        { !onlyServices && !!selectedAddOns.length && (
          <div key="addons" className={styles.cardSection}>
            <span className={cx('label', styles.cardHeader)}>Add Ons:</span>
            <div className={cx('label', styles.cardContent)}>
              {
                selectedAddOns.map((addOn) => (
                  <div key={addOn.name} className={styles.lineItem}>
                    <p>
                      &bull;
                      &nbsp;
                      &nbsp;
                      {addOn.name}
                    </p>
                    <p>
                      $
                      {addOn.value.toFixed(2)}
                    </p>
                  </div>
                ))
              }
            </div>
          </div>
        )}
      </div>
      {!onlyServices ? (
        <div
          key="totals"
          className={cx(styles.summarySection, {
            [styles.condenseSections]: condense,
            [styles.excludeHeaders]: onlyServices,
          })}
        >
          <div key="total" className={styles.lineItem}>
            <span className="label">Total</span>
            <span>
              {`$${(+addOnTotal + serviceTotal).toFixed(2)}`}
              { (!make || !model || !year) && '*' }
            </span>
          </div>
          {(!make || !model || !year) && showDisclaimer && (
          <div className={styles.lineItem}>
            <span>
              <span className="label">
                Your total cost may be higher.&nbsp;
              </span>
              Without full vehicle information,
              we are only able to provide a baseline quote.
              <span>
                Tax is not included in the price.
              </span>
            </span>
          </div>
          )}
        </div>
      ) : (
        <div key="hint" className={styles.summarySection}>
          <p>
            *Total calculated at the next step.
          </p>
        </div>
      )}
    </div>
  );
}
