/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import cx from 'classnames';
import { ChangeEvent, useState } from 'react';
import AWS from 'aws-sdk';
import { VehicleInspectionPage2Props } from '../interfaces/Components';
import styles from '../sass/components/VehicleInspection.module.scss';
import ModalButtonsContainer from './ModalButtonsContainer';
import TextInput from './TextInput';
import Select from './Select';
import Checkbox from './Checkbox';

const bucketName = 'gooil-development-bucket';
const s3 = new AWS.S3({
  region: process.env.REGION,
  accessKeyId: 'AKIAV5T2EPUEFQJ5LOPN',
  secretAccessKey: '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',
  sslEnabled: true,
  s3ForcePathStyle: false,
});

export default function VehicleInspectionPage2({
  onClose,
  onClick,
  inspectionData,
  setInspectionData,
  isContinueButtonInactive,
}: VehicleInspectionPage2Props) {
  return (
    <div className={styles.body}>
      <h4>Step 2/6: Enter vehicle inspection data</h4>
      <p>
        Before completing this order, please enter the vehicle inspection data.
        Once the information is filled,
        continue to enter services.
      </p>
      <br />
      <div className={styles.individualTireWrapper}>
        <label className={styles.title}>Additional Information</label>
        <TextInput
          title="Tire Size"
          placeholder="Tire Size"
          type="number"
          className={styles.additionalInfo}
          onChange={(e) => setInspectionData({
            ...inspectionData,
            tireSize: Number(e.currentTarget.value),
          })}
          value={
            inspectionData?.tireSize
              ? inspectionData.tireSize.toString()
              : ''
            }
        />
        <TextInput
          title="Engine Hours"
          placeholder="Engine Hours"
          type="number"
          className={styles.additionalInfo}
          onChange={(e) => setInspectionData({
            ...inspectionData,
            engineHours: Number(e.currentTarget.value),
          })}
          value={
            inspectionData?.engineHours
              ? inspectionData.engineHours.toString()
              : ''
            }
        />
      </div>
      <br />
      <Select
        label="Windshield condition"
        id="windshield"
        placeholder="Select windshield condition..."
        loading={false}
        onChange={(e) => setInspectionData({
          ...inspectionData,
          windshieldsCondition: Number(e.currentTarget.value),
        })}
        value={
          inspectionData?.windshieldsCondition
            ? inspectionData.windshieldsCondition.toString()
            : ''
          }
      >
        <option key="5" value="5">Perfect Condition</option>
        <option key="4" value="4">Minor Imperfection</option>
        <option key="3" value="3">Moderate Damage</option>
        <option key="2" value="2">Significant Damage</option>
        <option key="1" value="1">Safety Hazard</option>
      </Select>
      <br />
      <TextInput
        title="Notes"
        placeholder="Enter inspection notes"
        min={0}
        onChange={(e) => setInspectionData({
          ...inspectionData,
          notes: e.currentTarget.value,
        })}
        value={
          inspectionData?.notes
            ? inspectionData.notes.toString()
            : ''
          }
      />
      <ModalButtonsContainer
        onClose={onClose}
        onClick={onClick}
        inactive={isContinueButtonInactive}
        label="Continue"
      />
    </div>
  );
}
