import { ConfirmationModalProps } from '../interfaces/Components';
import styles from '../sass/components/ErrorModal.module.scss';
import Button from './Button';
import Modal from './Modal';
import ModalCloseButton from './ModalCloseButton';

export default function ConfirmationModal({
  onClose, title, message, open, onSubmit,
}: ConfirmationModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <div className={styles.modal}>
        <ModalCloseButton onClose={onClose} />
        <div className={styles.body}>
          <h4>{title}</h4>
          <p>{message}</p>
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.button}
              onClick={onClose}
              variant="tertiary"
            >
              Cancel
            </Button>
            {onSubmit && (
            <Button
              className={styles.button}
              onClick={onSubmit}
              variant="primary"
            >
              Confirm
            </Button>
            )}

          </div>
        </div>
      </div>
    </Modal>
  );
}
