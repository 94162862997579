import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useAppDispatch } from '../app/hooks';
import { selectNewVehicle } from '../features/car/carSlice';
import styles from '../sass/components/VehicleRowEdit.module.scss';
import {
  ADD_VEHICLE,
  GET_MAKES_BY_YEAR,
  GET_MODELS_BY_YEAR_AND_MAKE,
  GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR,
  FIND_ALL_USER_VEHICLES_BY_CURRENT_USER,
  GET_YEARS,
} from '../util/gql';
import Button from './Button';
import Field from './Field';
import Selector from './Selector';

export default function VehicleRowEdit({
  setIsEditing,
}: {
  setIsEditing: () => void;
}) {
  const dispatch = useAppDispatch();

  const [vehicle, setVehicle] = useState<{
    id: number | null; make: string, model: string; year: string;
  }>({
    id: null, make: '', model: '', year: '',
  });

  const [addVehicle] = useMutation(ADD_VEHICLE);

  const { data: yearData } = useQuery(GET_YEARS);

  const [loadModelData, {
    data: modelData,
  }] = useLazyQuery(GET_MODELS_BY_YEAR_AND_MAKE);

  useEffect(() => {
    if (vehicle.make !== '') {
      loadModelData({
        variables: {
          year: vehicle.year,
          make: vehicle.make,
        },
      });
    }
  }, [vehicle.make, vehicle.year, loadModelData]);

  const [loadMakeData, { data: makeData }] = useLazyQuery(GET_MAKES_BY_YEAR);

  useEffect(() => {
    if (vehicle.year !== '') {
      loadMakeData({
        variables: {
          year: vehicle.year,
        },
      });
    }
  }, [vehicle.year, loadMakeData]);

  const [loadVehicleData, {
    data: vehicleData,
  }] = useLazyQuery(GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR);

  useEffect(() => {
    if (vehicle.make !== '' && vehicle.model !== '' && vehicle.year !== '') {
      loadVehicleData({
        variables: {
          make: vehicle.make,
          model: vehicle.model,
          year: parseInt(vehicle.year, 10),
        },
      });
    }
  }, [vehicle.make, vehicle.model, vehicle.year, loadVehicleData]);

  const selectMake = useCallback((make) => {
    if (make !== vehicle.make) {
      setVehicle({
        ...vehicle,
        make,
        id: null,
      });
    }
  }, [vehicle, setVehicle]);

  const handleMakeChange = (event: FormEvent<HTMLSelectElement>) => {
    selectMake(event.currentTarget.value);
  };

  const selectModel = useCallback((model) => {
    if (model !== vehicle.model) {
      setVehicle({
        ...vehicle,
        model,
        id: null,
      });
    }
  }, [vehicle, setVehicle]);

  const handleModelChange = (event: FormEvent<HTMLSelectElement>) => {
    selectModel(event.currentTarget.value);
  };

  const selectYear = useCallback((year) => {
    if (year !== vehicle.year) {
      setVehicle({
        ...vehicle,
        year,
        make: '',
        model: '',
        id: null,
      });
    }
  }, [vehicle, setVehicle]);

  const handleYearChange = (event: FormEvent<HTMLSelectElement>) => {
    selectYear(event.currentTarget.value);
  };

  const selectEngine = useCallback((id) => {
    if (id !== vehicle.id) {
      setVehicle({
        ...vehicle,
        id,
      });
    }
  }, [vehicle, setVehicle]);

  const handleEngineChange = (event: FormEvent<HTMLSelectElement>) => {
    selectEngine(parseInt(event.currentTarget.value, 10));
  };

  const handleSave = () => {
    addVehicle({
      variables: {
        vehicleId: vehicle.id,
      },
      refetchQueries: [
        {
          query: FIND_ALL_USER_VEHICLES_BY_CURRENT_USER,
        },
      ],
    });
    dispatch(selectNewVehicle);
  };

  return (
    <form>
      <div className={styles.selectors}>
        <Field
          id="field-year"
          label="Year"
        >
          <Selector
            id="selector-year"
            label="Year"
            value={vehicle.year}
            onChange={handleYearChange}
            options={yearData?.getAllVehicleYears?.map((y: {
              year: string;
            }) => ({
              value: y.year,
              label: y.year,
            })) || []}
          />
        </Field>
        <Field
          id="make"
          label="Make"
        >
          <Selector
            id="make"
            label="Make"
            value={vehicle.make}
            disabled={vehicle.year === ''}
            onChange={handleMakeChange}
            options={makeData?.getMakesByYear?.map((m: {
              make: string;
            }) => ({
              value: m.make,
              label: m.make,
            })) || []}
          />
        </Field>
        <Field
          id="model"
          label="Model"
        >
          <Selector
            id="model"
            label="Model"
            value={vehicle.model}
            disabled={vehicle.make === ''}
            onChange={handleModelChange}
            options={modelData?.getModelsByYearAndMake?.map((m: {
              model: string;
            }) => ({
              value: m.model,
              label: m.model,
            })) || []}
          />
        </Field>
        <Field
          id="engine"
          label="Engine"
        >
          <Selector
            id="engine"
            label="Engine"
            value={vehicle.id || ''}
            disabled={
              vehicle.make === '' || vehicle.model === '' || vehicle.year === ''
            }
            onChange={handleEngineChange}
            options={vehicleData?.getVehiclesByMakeModelAndYear?.map((v: {
              id: number;
              engine: string;
            }) => ({
              value: v.id,
              label: v.engine,
            })) || []}
          />
        </Field>
      </div>
      <div className={styles.buttons}>
        <Button
          variant="tertiary"
          onClick={() => setIsEditing()}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          size="large"
          inactive={!vehicle.id}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </form>
  );
}
