/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import {
  EditPoNumberModalProps,
  EditPoNumberModals,
  editPoNumberModalsList,
} from '../interfaces/Components';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';

import ModalCloseButton from './ModalCloseButton';
import PoNumberEditor from './PoNumberEditor';
import {
  PoNumber,
} from '../interfaces/WorkOrder';

export default function EditLineItemsModal(
  {
    workOrderDetails, setWorkOrderDetails, onClose, onSubmit,
  }: EditPoNumberModalProps,
) {
  const [poNumber, setPoNumber] = useState<string>('');

  if (workOrderDetails) {
    useEffect(() => {
      if (workOrderDetails?.poNumber) {
        setPoNumber(workOrderDetails?.poNumber);
      } else {
        setPoNumber('');
      }
    }, [workOrderDetails]);
  }
  const [
    activeModal,
    setActiveModal,
  ] = useState<EditPoNumberModals>('poNumber');
  const handleSubmit = () => {
    onSubmit(
      poNumber,
    );
    onClose();
  };

  const handleChangeActiveModal = (
    modalName: EditPoNumberModals,
  ) => setActiveModal(modalName);

  const handleModalContinue = () => {
    const currentModalIndex = editPoNumberModalsList.indexOf(activeModal);
    const nextModal = editPoNumberModalsList[currentModalIndex + 1];
    handleChangeActiveModal(nextModal);
  };

  const handleModalBack = () => {
    const currentModalIndex = editPoNumberModalsList.indexOf(activeModal);
    const nextModal = editPoNumberModalsList[currentModalIndex - 1];
    handleChangeActiveModal(nextModal);
  };

  return (
    <div className={styles.container}>
      <ModalCloseButton onClose={onClose} />
      {activeModal === 'poNumber' && workOrderDetails && (
        <PoNumberEditor
          workOrderDetails={workOrderDetails}
          setWorkOrderDetails={setWorkOrderDetails}
          poNumber={poNumber}
          setPoNumber={setPoNumber}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}
