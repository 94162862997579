/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  EditVehicleEngineModalProps,
  EditVehicleEngineModals,
  editVehicleEngineModalsList,
  Vehicles,
} from '../interfaces/Components';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import {
  GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR,
} from '../util/gql';

import ModalCloseButton from './ModalCloseButton';
import VehicleEngineEditor from './VehicleEngineEditor';

export default function EditVehicleEngineModal(
  {
    workOrderDetails, setWorkOrderDetails, onClose, onSubmit,
  }: EditVehicleEngineModalProps,
) {
  const [vehicleId, setVehicleId] = useState<number>(0);
  const [vehicles, setVehicles] = useState<Vehicles[]>([]);

  const { data, loading } = useQuery(GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR, {
    variables: {
      make: workOrderDetails?.make,
      model: workOrderDetails?.model,
      year: parseInt(workOrderDetails?.year || '', 10),
    },
    onCompleted: () => {
      if (data.getVehiclesByMakeModelAndYear) {
        setVehicles(data.getVehiclesByMakeModelAndYear);
      }
    },
    fetchPolicy: 'network-only',
  });

  if (workOrderDetails) {
    useEffect(() => {
      if (workOrderDetails?.vehicleId) {
        const id = workOrderDetails?.vehicleId;
        setVehicleId(id);
      }
    }, [workOrderDetails]);
  }
  const [
    activeModal,
    setActiveModal,
  ] = useState<EditVehicleEngineModals>('vehicle');
  const handleSubmit = () => {
    onSubmit(
      vehicleId,
    );
    onClose();
  };

  const handleChangeActiveModal = (
    modalName: EditVehicleEngineModals,
  ) => setActiveModal(modalName);

  const handleModalContinue = () => {
    const currentModalIndex = editVehicleEngineModalsList.indexOf(activeModal);
    const nextModal = editVehicleEngineModalsList[currentModalIndex + 1];
    handleChangeActiveModal(nextModal);
  };

  const handleModalBack = () => {
    const currentModalIndex = editVehicleEngineModalsList.indexOf(activeModal);
    const nextModal = editVehicleEngineModalsList[currentModalIndex - 1];
    handleChangeActiveModal(nextModal);
  };

  return (
    <div className={styles.container}>
      <ModalCloseButton onClose={onClose} />
      {activeModal === 'vehicle' && workOrderDetails && (
        <VehicleEngineEditor
          workOrderDetails={workOrderDetails}
          setWorkOrderDetails={setWorkOrderDetails}
          vehicleId={vehicleId}
          setVehicleId={setVehicleId}
          vehicles={vehicles}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}
