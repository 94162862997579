import {
  ApolloClient, ApolloLink, from, HttpLink, InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useAppSelector } from '../app/hooks';
import useAuthentication from './useAuthentication';

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
});

// Keep this outside of the hook.
const cache = new InMemoryCache();

function authMiddleware(authToken: string | null) {
  return new ApolloLink((operation, forward) => {
    if (authToken !== null) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation);
  });
}

function useAuthenticatedApolloClient() {
  const loggedIn = useAppSelector((state) => state.auth.isAuthenticated);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const { logOut } = useAuthentication();

  const logoutLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length) {
      const unauthenticated = graphQLErrors.find(((err) => (
        err?.extensions?.response?.statusCode === 401)));

      if (unauthenticated && loggedIn) logOut();
    }
  });

  return new ApolloClient({
    link: from([
      authMiddleware(loggedIn ? currentUser.token : null),
      logoutLink,
      httpLink,
    ]),
    cache,
  });
}

export default useAuthenticatedApolloClient;
