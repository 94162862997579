import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { useAppSelector } from '../app/hooks';
import { InvoiceProps, PayInvoicePayload } from '../interfaces/Invoice';
import { methods } from '../lang/en/payment.json';
import styles from '../sass/components/PayInvoiceModal.module.scss';
import {
  GET_INVOICE, PAY_BILL,
  PAY_BILL_LATER,
  GET_VENDOR_BY_ID,
} from '../util/gql';
import Button from './Button';
import ConfirmationModal from './ConfirmationModal';
import Field from './Field';
import Modal from './Modal';
import ModalCloseButton from './ModalCloseButton';
import Selector from './Selector';
import TextInput from './TextInput';
import { Vendor } from '../interfaces/Customer';

interface ManualPaymentInvoiceModalProps {
  invoiceDetails?: InvoiceProps;
  payInvoiceModal: boolean;
  onClose: () => void;
  setErrorModal: (status: boolean) => void;
}

interface SelectOption {
  value: string;
  label: string;
}

export default function PayInvoiceModal(
  {
    invoiceDetails,
    payInvoiceModal,
    onClose,
    setErrorModal,
  }: ManualPaymentInvoiceModalProps,
) {
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const [values, setValues] = useState({
    paymentMethod: '',
    cardType: '',
    cardFourDigit: '',
    expiryMonth: '',
    expiryYear: '',
    chequeNumber: '',
    transitNumber: '',
    notes: '',
    externalConfirmationNumber: '',
    amount: '',
  });

  const [payLaterModal, setPayLaterModal] = useState<boolean>(false);
  const [vendorDetails, setVendorDetails] = useState<Vendor>();
  const [payButtonDisabled, setPayButtonDisabled] = useState<boolean>(true);
  const [initialAmount, setInitialAmount] = useState<number>(0);

  const [remittanceFee, setRemittanceFee] = useState<number | null>(null);

  useEffect(() => {
    if (invoiceDetails) {
      const amount = invoiceDetails.remainingAmount > 0
        ? invoiceDetails.remainingAmount
        : invoiceDetails.billedAmount;
      setValues((prevValues) => ({
        ...prevValues,
        amount: (amount / 100).toFixed(2),
      }));
      setInitialAmount(amount / 100);
    }
  }, [invoiceDetails]);

  const paymentMethods: SelectOption[] = Object.values(methods)
    .filter((method) => {
      if (method === 'Pay Later') {
        return invoiceDetails?.payment?.length === 0;
      }
      return true;
    })
    .map((method) => ({
      label: method,
      value: method,
    }));

  useEffect(() => {
    const parsedAmount = parseFloat(values.amount);
    // eslint-disable-next-line max-len
    if (parsedAmount > initialAmount || parsedAmount <= 0 || !values.paymentMethod || !values.amount) {
      setPayButtonDisabled(true);
    } else {
      setPayButtonDisabled(false);
    }

    if (vendorDetails) {
      let amountCalculateOn;

      if (vendorDetails.isRemittanceOnSubTotal) {
        amountCalculateOn = (invoiceDetails?.discountSubTotal
          || invoiceDetails?.subTotal || 0) / 100;
      } else {
        amountCalculateOn = parsedAmount;
      }
      // eslint-disable-next-line max-len
      const remittanceAmount = ((vendorDetails?.remittance || 0) / 100) * amountCalculateOn;
      setRemittanceFee(remittanceAmount);
    }
  }, [values.amount, initialAmount, values.paymentMethod, vendorDetails]);

  const [payInvoice] = useMutation(PAY_BILL, {
    onCompleted: () => {
      onClose();
    },
    onError: () => {
      onClose();
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target;

    if (name === 'paymentMethod' && value === 'Pay Later') {
      if (invoiceDetails) {
        const amount = invoiceDetails.remainingAmount > 0
          ? invoiceDetails.remainingAmount
          : invoiceDetails.billedAmount;
        setValues((prevValues) => ({
          ...prevValues,
          paymentMethod: value,
          amount: (amount / 100).toFixed(2),
        }));
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleInputChange = (
    e: FormEvent<HTMLInputElement>,
  ): void => {
    const { name, value } = e.currentTarget as HTMLInputElement;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const paidAmount = parseFloat(values?.amount) - (remittanceFee || 0);

  const assembleInvoicePayload = (): PayInvoicePayload => ({
    invoiceId: invoiceDetails?.id,
    userId: invoiceDetails?.userId,
    authorId: currentUser.id,
    franchiseId: invoiceDetails
      ? invoiceDetails.franchiseId : currentUser.franchiseId,
    amount: Math.round(parseFloat(values.amount) * 100),
    paymentMethod: values.paymentMethod,
    ...values.paymentMethod === methods.cheque
      && { chequeNumber: values.chequeNumber },
    ...values.notes && { notes: values.notes },
  });

  const [payBillLater] = useMutation(PAY_BILL_LATER, {
    onCompleted: () => onClose(),
    onError: () => {
      onClose();
      setPayLaterModal(false);
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const handlePayBillLater = (): void => {
    payBillLater({
      variables: { invoiceId: invoiceDetails?.id },
      refetchQueries: () => [{
        query: GET_INVOICE,
        variables: { invoiceId: invoiceDetails?.id },
      }],
    });
  };

  const [getVendorDetails, { error }] = useLazyQuery(GET_VENDOR_BY_ID, {
    onCompleted: (data: any) => {
      setVendorDetails({
        vendorId: data?.getVendorById.id,
        name: data?.getVendorById.name,
        remittance: data?.getVendorById.remittance,
        isRemittanceOnSubTotal: data?.getVendorById.isRemittanceOnSubTotal,
      });
    },
    onError: (err) => {
      console.error('Error fetching vendor details:', err);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (invoiceDetails?.vendorId) {
      getVendorDetails({ variables: { vendorId: invoiceDetails.vendorId } });
    }
  }, [getVendorDetails, invoiceDetails?.vendorId]);

  const handleSubmit = (): void => {
    if (values.paymentMethod === 'Pay Later') {
      setPayLaterModal(true);
    } else {
      payInvoice(
        {
          variables: assembleInvoicePayload(),
          refetchQueries: () => [{
            query: GET_INVOICE,
            variables: {
              invoiceId: invoiceDetails?.id,
            },
          }],
        },
      );
    }
  };

  if (payLaterModal) {
    return (
      <ConfirmationModal
        message="A payment link will be sent to the customer by e-mail.
        Do you wish to proceed?"
        onSubmit={handlePayBillLater}
        title="Pay invoice later"
        onClose={onClose}
        open={payLaterModal}
      />
    );
  }

  return (
    <Modal
      open={payInvoiceModal}
      onClose={onClose}
      disableBackdropClick
    >
      <div className={styles.modal}>
        <ModalCloseButton onClose={onClose} />
        <div className={styles.body}>
          <h4>Pay Invoice</h4>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          >
            <Field id="amount" label="Amount to Pay">
              <TextInput
                type="number"
                name="amount"
                value={values.amount}
                onChange={handleInputChange}
                placeholder="Enter the amount you want to pay"
                isRequired
                // eslint-disable-next-line max-len
                disabled={!!(values.paymentMethod === 'Pay Later' || (vendorDetails?.remittance && vendorDetails?.isRemittanceOnSubTotal))}
              />
            </Field>
            {vendorDetails && (
            <>
              <Field id="vendor-fees" label="Deducted Vendor Fees">
                <TextInput
                  value={remittanceFee?.toFixed(2)}
                  disabled
                />
              </Field>
              {vendorDetails.remittance && paidAmount && (
                <Field id="vendor-fees" label="Paid Amount">
                  <TextInput
                    value={paidAmount?.toFixed(2)}
                    disabled
                  />
                </Field>
              )}
            </>
            )}
            <Field
              id="paymentMethod"
              label="Payment Method - Accepted by Franchisee"
            >
              <Selector
                name="paymentMethod"
                onChange={handleSelectChange}
                value={values.paymentMethod}
                id="paymentMethod"
                label="Payment Method"
                options={paymentMethods}
                isRequired
              />
            </Field>
            <Field id="notes" label="Notes">
              <TextInput
                name="notes"
                onChange={handleInputChange}
                value={values.notes}
                id="notes"
                placeholder="Notes"
              />
            </Field>
            <div className={styles.buttonsContainer}>
              <Button
                className={styles.button}
                onClick={onClose}
                variant="tertiary"
              >
                Cancel
              </Button>
              <Button
                className={styles.button}
                type="submit"
                inactive={payButtonDisabled}
              >
                Pay
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
