import styles from '../sass/components/Loader.module.scss';

export interface LoaderProps {
  wrapperClass?: string;
}

const Loader = ({
  wrapperClass,
}: LoaderProps) => (
  <div className={wrapperClass || styles.spinnerWrapper}>
    <div className={styles.spinner} />
  </div>
);

export default Loader;
