import cx from 'classnames';
import { useState } from 'react';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import styles from '../sass/components/ProductCard.module.scss';
import Badge from './Badge';
import Button from './Button';
import { ProductCardProps } from '../interfaces/Components';

function ProductCard({
  title,
  price,
  isEstimate,
  description,
  details,
  onSelect,
  selected,
  onRemove,
  disabled,
  discountedPrice,
}: ProductCardProps) {
  const [expanded, setExpanded] = useState(false);

  const getProductFinalPrice = () => {
    if (discountedPrice === 0) {
      return '$0.00';
    }
    return `${isEstimate ? 'from ' : ''}$${
      discountedPrice ? discountedPrice.toFixed(2) : price.toFixed(2)
    }`;
  };

  const productOriginalPrice = Number.isFinite(discountedPrice)
  && discountedPrice !== price
    ? `Original price $${price.toFixed(2)}`
    : null;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.mainInfoContainer}>
        <div
          className={
            cx([
              styles.leftInfoContainer,
              {
                [styles.expanded]: expanded,
              },
            ])
          }
        >
          <Button
            className={styles.selectButton}
            variant="icon"
            onClick={() => setExpanded(!expanded)}
            inactive={disabled}
          >
            <Arrow className={cx(
              { [styles.expandedArrow]: expanded },
            )}
            />
          </Button>
        </div>
        <div className={styles.description}>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
        <div className={styles.rightInfoContainer}>
          {getProductFinalPrice()}
          {productOriginalPrice && (
            <p className={styles.originalPrice}>{productOriginalPrice }</p>
          )}
          <Badge variant="secondary"><span>+ Applicable Taxes</span></Badge>
          <div className={styles.rightAddServiceToggle}>
            {selected && (
              <Button
                className={styles.selectButton}
                onClick={onRemove}
                variant="tertiary"
                error
                inactive={disabled}
              >
                Remove
              </Button>
            )}
            <Button
              // className={styles.selectButton}
              variant="primary"
              onClick={onSelect}
              inactive={selected || disabled}
            >
              Select
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.mobileButtons}>
        <div className={styles.mobileSelectButtons}>
          {selected && (
            <Button
              // className={styles.selectButton}
              onClick={onRemove}
              variant="tertiary"
              error
              inactive={disabled}
            >
              Remove
            </Button>
          )}
          <Button
            variant="primary"
            onClick={onSelect}
            inactive={selected || disabled}
          >
            Select
          </Button>
        </div>
        <Button
          variant="secondary"
          onClick={() => setExpanded(!expanded)}
          inactive={disabled}
        >
          {expanded ? 'Less Info' : 'More Info'}
        </Button>
      </div>
      <div className={cx(
        { [styles.containerExpanded]: expanded },
        styles.expandedInfoContainer,
      )}
      >
        <div className={styles.detailsCopy}>
          {
            details
            && details.split('\\n').map((item) => (
              <span key={`${item}`}>
                {item}
                <br />
              </span>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
