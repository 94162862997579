import { AddOn } from '../features/addOns/addOnsSlice';
import { ServiceType } from '../features/serviceTypes/serviceTypesSlice';

export interface TaxInformation {
  taxName: string;
  taxPercent: number;
  taxId: number;
  total: number;
}

export interface TaxTotals {
  [taxId: number]: {
    taxName: string,
    taxPercent: number;
    summedTaxes: number,
  }
}

const getTaxAmount = (percent: number, value: number) => (
  (percent * 0.01) * value
);

export const taxMapper = (item: AddOn | ServiceType): TaxInformation => ({
  taxPercent: item.taxPercent,
  taxName: item.taxName,
  taxId: item.taxId,
  total: getTaxAmount(item.taxPercent, item.value),
});

export const condenseTaxInformation = (
  mappedTaxes: TaxInformation[],
): TaxTotals => {
  const taxCategories: TaxTotals = {};

  mappedTaxes.forEach((taxItem) => {
    if (taxCategories[taxItem.taxId]) {
      taxCategories[taxItem.taxId].summedTaxes += taxItem.total;
    } else {
      taxCategories[taxItem.taxId] = {
        taxPercent: taxItem.taxPercent,
        taxName: taxItem.taxName,
        summedTaxes: taxItem.total,
      };
    }
  });

  return taxCategories;
};

export const mergeTaxInformation = (sliceTaxes: TaxTotals[]): TaxTotals => {
  const taxCategories: TaxTotals = {};

  sliceTaxes.forEach((sliceTax) => {
    Object.keys(sliceTax).forEach((key: string) => {
      const categoryKey = parseInt(key, 10);
      if (taxCategories[categoryKey]) {
        taxCategories[categoryKey]
          .summedTaxes += sliceTax[categoryKey].summedTaxes;
      } else {
        taxCategories[categoryKey] = {
          taxPercent: sliceTax[categoryKey].taxPercent,
          taxName: sliceTax[categoryKey].taxName,
          summedTaxes: sliceTax[categoryKey].summedTaxes,
        };
      }
    });
  });

  return taxCategories;
};
