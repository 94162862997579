import DatePicker from '@mui/lab/DatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { PickersDayProps } from '@mui/lab/PickersDay';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import styles from '../sass/components/DatePicker.module.scss';
import TextInput from './TextInput';

interface DatePickerProps {
  label: string;
  value: Date | null | undefined;
  id: string;
  onChange: (date: Date | null) => void;
  onMonthChange?: (date: Date) => void;
  disabled?: boolean;
  minDate?: Date;
  disablePast?: boolean;
  loading?: boolean;
  popout?: boolean;
  open?: boolean;
  mobile?: boolean;
  renderDay?: ((
    day: Date,
    selectedDates: (Date | null)[],
    pickersDayProps: PickersDayProps<Date>) => JSX.Element) | undefined
}

const Toolbar = () => <div />;
export default function Picker({
  label,
  value,
  onChange,
  id,
  minDate,
  disablePast,
  renderDay,
  disabled,
  onMonthChange,
  loading,
  popout = true,
  mobile = false,
  open,
}: DatePickerProps) {
  if (!popout && !mobile) {
    return (
      <StaticDatePicker
        label={label}
        value={value}
        onChange={onChange}
        onMonthChange={onMonthChange}
        minDate={minDate}
        disablePast={disablePast}
        renderDay={renderDay}
        open
        ToolbarComponent={Toolbar}
        disabled={disabled}
        loading={loading}
        renderInput={() => (
          <input />
        )}
      />
    );
  }
  if (mobile) {
    return (
      <MobileDatePicker
        label={label}
        value={value}
        onChange={onChange}
        onMonthChange={onMonthChange}
        minDate={minDate}
        disablePast={disablePast}
        renderDay={renderDay}
        open={open}
        ToolbarComponent={Toolbar}
        disabled={disabled}
        loading={loading}
        renderInput={({ inputRef, inputProps }) => (
          <div className={styles.wrapper}>
            <TextInput
              ref={inputRef}
              id={`date-picker-${id}`}
              title={label}
              defaultValue={value}
              onChange={() => undefined}
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
            />
          </div>
        )}
      />
    );
  }
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={onChange}
      onMonthChange={onMonthChange}
      minDate={minDate}
      disablePast={disablePast}
      renderDay={renderDay}
      open={open}
      ToolbarComponent={Toolbar}
      disabled={disabled}
      loading={loading}
      renderInput={({ inputRef, inputProps, InputProps }) => (
        <div className={styles.wrapper}>
          <TextInput
            ref={inputRef}
            id={`date-picker-${id}`}
            title={label}
            rightIcon={InputProps?.endAdornment}
            defaultValue={value}
            onChange={() => undefined}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
          />
        </div>
      )}
    />
  );
}
