import { useQuery } from '@apollo/client';
import styles
  from '../sass/components/CustomerInformationManagement.module.scss';
import {
  GET_ADDRESSES,
  GET_CONTACTS,
  GET_CURRENT_USER,
} from '../util/gql';
import AddressRowEdit from './AddressRowEdit';
import AddressRowView from './AddressRowView';
import ContactRowEdit from './ContactRowEdit';
import ContactRowView from './ContactRowView';
import AddPaymentMethodWidget
  from './paymentMethodWidget/AddPaymentMethodWidget';
import Table from './Table';

export default function CustomerInformationManagement() {
  const { data } = useQuery(GET_CONTACTS, {
    fetchPolicy: 'no-cache',
  });

  const addressData = useQuery(GET_ADDRESSES, {
    fetchPolicy: 'no-cache',
  });

  const userData = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
  });

  return (
    <div className={styles.customerInformation}>
      <div className={styles.contacts}>
        <h4 className={styles.header}> Contact Information </h4>
        <Table
          name="contact"
          data={data?.getContacts}
          isEditable
          RowViewComponent={ContactRowView}
          RowEditComponent={ContactRowEdit}
        />
      </div>
      <h4 className={styles.header}> Address </h4>
      <Table
        name="address"
        data={addressData.data?.getAddresses}
        isEditable
        RowViewComponent={AddressRowView}
        RowEditComponent={AddressRowEdit}
      />
      <br />
      <h4 className={styles.paymentHeader}>
        Payment Method
      </h4>
      <br />
      {addressData.data && userData.data && (
      <AddPaymentMethodWidget
        countryCode={addressData.data?.getAddresses[0].countryCode}
        userData={userData.data?.getCurrentUser.user}
        customerVendor={userData?.data.getCurrentUser.customerVendor}
      />
      )}
    </div>
  );
}
