import { useMutation } from '@apollo/client';
import { CompleteModalProps } from '../interfaces/Components';
import styles from '../sass/components/ErrorModal.module.scss';
import Button from './Button';
import Modal from './Modal';
import ModalCloseButton from './ModalCloseButton';
import { CANCEL_TERMINAL_PAYMENT } from '../util/gql';

export default function CompleteModal({
  onClose, title, message, open, currentReader, invoiceId,
}: CompleteModalProps) {
  const [cancelTerminalPayment] = useMutation(CANCEL_TERMINAL_PAYMENT, {
    fetchPolicy: 'network-only',
  });

  const handleCancelTerminalPayment = async () => {
    const canceledTerminalId = await cancelTerminalPayment({
      variables: {
        readerId: currentReader,
        invoiceId,
      },
    });
    if (canceledTerminalId) {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <div className={styles.modal}>
        <ModalCloseButton onClose={onClose} />
        <div className={styles.body}>
          <h4>{title}</h4>
          <p>{message}</p>
          <div className={styles.buttonsContainer}>
            {currentReader && invoiceId
              && (
              <Button
                className={styles.button}
                onClick={handleCancelTerminalPayment}
                variant="primary"
              >
                Cancel Terminal Payment
              </Button>
              )}
            <Button
              className={styles.button}
              onClick={onClose}
              variant="primary"
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
