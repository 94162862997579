import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Selection = 'unset' | 'new' | number;

export interface CarState {
  make: string;
  model: string;
  year: string;
  id: number | null;
  name?: string;
  userVehicleId?: number;
  vin?: string;
  // 'unset' is like 'new', but 'new' is explicitly set by the user
  selected: Selection;
}

const initialState: CarState = {
  make: '',
  model: '',
  year: '',
  id: null,
  name: '',
  selected: 'unset',
};

export const carSlice = createSlice({
  name: 'car',
  initialState,
  // When we select a higher category, clear the values for the lower ones so
  // that it correctly disables the service selection page until a car has
  // actually been selected.
  reducers: {
    setYear: (state, action: PayloadAction<string>) => ({
      ...state,
      year: action.payload,
      make: '',
      model: '',
      id: null,
    }),
    setMake: (state, action: PayloadAction<string>) => ({
      ...state,
      make: action.payload,
      model: '',
      id: null,
    }),
    setModel: (state, action: PayloadAction<string>) => ({
      ...state,
      model: action.payload,
      id: null,
    }),
    setId: (state, action: PayloadAction<number>) => ({
      ...state,
      id: action.payload,
    }),
    setName: (state, action: PayloadAction<string>) => ({
      ...state,
      name: action.payload,
    }),
    setCar: (state, action: PayloadAction<CarState>) => ({
      ...action.payload,
    }),
    selectNewVehicle: (state) => ({
      ...initialState,
      selected: 'new',
    }),
  },
});

export const {
  setMake,
  setModel,
  setYear,
  setId,
  setName,
  setCar,
  selectNewVehicle,
} = carSlice.actions;

export default carSlice.reducer;
