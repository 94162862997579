import Close from '@mui/icons-material/Close';
import styles from '../sass/components/ModalCloseButton.module.scss';

interface ModalCloseButtonProps {
  onClose: () => void;
}

export default function ModalCloseButton({ onClose }: ModalCloseButtonProps) {
  return (
    <div className={styles.modalCloseSection}>
      <button
        type="button"
        onClick={onClose}
        className={styles.button}
      >
        <Close />
      </button>
    </div>
  );
}
