import { useMutation } from '@apollo/client';
import { useMemo, useState } from 'react';
import styles from '../sass/components/MileageModal.module.scss';
import {
  CREATE_MILEAGE,
  FIND_ALL_USER_VEHICLES_BY_CURRENT_USER,
} from '../util/gql';
import Button from './Button';
import Modal from './Modal';
import ModalCloseButton from './ModalCloseButton';
import TextInput from './TextInput';

interface MileageModalProps {
  mileageModal: boolean
  onClose: () => void
  currentMileage?: number
  onError: () => void
  userVehicleId: number
}

export default function MileageModal({
  mileageModal, onClose, currentMileage, onError, userVehicleId,
}: MileageModalProps) {
  const [mileage, setMileage] = useState<number | undefined>(currentMileage);

  const currentMileageNumber = useMemo(
    () => Number(currentMileage), [currentMileage],
  );

  const [createMileage] = useMutation(CREATE_MILEAGE, {
    onCompleted: onClose,
    onError,
    fetchPolicy: 'network-only',
  });

  const handleSubmit = () => {
    createMileage({
      variables: {
        userVehicleId,
        workOrderId: 1,
        mileage: Number(mileage),
      },
      refetchQueries: () => [{ query: FIND_ALL_USER_VEHICLES_BY_CURRENT_USER }],
    });
  };

  const shouldDisableSubmit = !mileage || mileage === 0;

  return (
    <Modal
      open={mileageModal}
      onClose={onClose}
      disableBackdropClick
    >
      <div className={styles.container}>
        <ModalCloseButton onClose={onClose} />
        <div className={styles.body}>
          <h4>Enter vehicle mileage</h4>
          <TextInput
            title="Vehicle Mileage"
            placeholder="Enter vehicle mileage"
            onChange={(e) => setMileage(Number(e.currentTarget.value))}
            type="number"
            isRequired
            value={mileage?.toString()}
            min={currentMileageNumber || 0}
          />
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.button}
              onClick={onClose}
              variant="tertiary"
            >
              Cancel
            </Button>
            <Button
              className={styles.button}
              variant="primary"
              onClick={handleSubmit}
              inactive={shouldDisableSubmit}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
