import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface BookingState {
  success: boolean;
}

const initialState: BookingState = {
  success: false,
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookingSuccess: (state, action: PayloadAction<boolean>) => ({
      success: action.payload,
    }),
  },
});

export const { setBookingSuccess } = bookingSlice.actions;

export const selectBookingSucessState = (
  state: RootState,
) => state.booking.success;

export default bookingSlice.reducer;
