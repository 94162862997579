import cx from 'classnames';
import { ReactNode } from 'react';
import styles from '../sass/components/Tab.module.scss';

interface TabProps {
  children?: ReactNode;
  selected: boolean;
  className?: string;
  onSelect?: () => void;
  step?: boolean;
  tab?: boolean;
  disabled?: boolean;
  presentational?: boolean;
}

function Tab({
  children,
  selected = false,
  onSelect,
  className,
  step,
  tab,
  disabled = false,
  presentational,
}: TabProps) {
  return (
    <div
      onClick={() => !disabled && onSelect && onSelect()}
      onKeyPress={() => undefined}
      tabIndex={0}
      role="menuitem"
      className={cx(
        {
          [styles.selected]: selected,
          [styles.tab]: tab,
          [styles.step]: step,
          [styles.disabled]: disabled,
          [styles.presentational]: presentational,
        },
        className,
        'label',
      )}
    >
      {children}
    </div>
  );
}

export default Tab;
