import { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  AssignWorkOrderModalProps,
  AssignWorkOrderModals,
} from '../interfaces/Components';
import { Franchise } from '../interfaces/WorkOrder';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import { GET_FRANCHISES_OF_FRANCHISE_MANAGER } from '../util/gql';
import AssignWorkOrder from './AssignWorkOrder';

export default function AssignWorkOrderContainer({
  onClose,
  franchiseId,
  onSubmit,
}: AssignWorkOrderModalProps) {
  const [assignedFranchise, setAssignedFranchise] = useState<number>(0);
  const [franchises, setFranchises] = useState<Franchise[]>([]);
  const [
    activeModal,
    setActiveModal,
  ] = useState<AssignWorkOrderModals>('assign');

  const { data, loading } = useQuery(GET_FRANCHISES_OF_FRANCHISE_MANAGER, {
    onCompleted: () => {
      if (data.getFranchisesOfFranchiseManager) {
        setFranchises(data.getFranchisesOfFranchiseManager);
      }
    },
    fetchPolicy: 'network-only',
  });

  const handleSubmit = () => {
    onSubmit(
      assignedFranchise,
    );
    onClose();
  };

  return (
    <div className={styles.container}>
      {/* <ModalCloseButton onClose={onClose} /> */}
      {activeModal === 'assign' && (
      <AssignWorkOrder
        franchiseId={franchiseId}
        franchises={franchises}
        assignedFranchise={assignedFranchise}
        setAssignedFranchise={setAssignedFranchise}
        onClose={onClose}
        onClick={handleSubmit}
      />
      )}
    </div>

  );
}
