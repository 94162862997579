import React from 'react';
import cx from 'classnames';
import styles from '../sass/components/TextArea.module.scss';

interface TextAreaProps {
  title?: string;
  disabled?: boolean;
  id: string;
  value?: string;
  onChange?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  onEnter?: () => void;
  placeholder?: string;
  cols?: number;
  rows?: number;
}

const TextInput = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((
  props, ref,
) => {
  const {
    title,
    disabled = false,
    id,
    value,
    onChange,
    placeholder,
    onEnter,
    cols,
    rows,
  } = props;

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onEnter) {
      event.preventDefault();
      onEnter();
    }
  };

  return (
    <div className={styles.textInputWrapper}>
      {title && (
        <label
          htmlFor={id}
          className={cx({
            [styles.disabled]: disabled,
          })}
        >
          {title}
        </label>
      )}
      <div className={styles.inputWrapper}>
        <textarea
          ref={ref}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          id={id}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          cols={cols}
          rows={rows}
          className={cx({
            [styles.inputDisabled]: disabled,
          })}
        />
      </div>
    </div>
  );
});

export default TextInput;
