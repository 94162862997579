import React, { KeyboardEventHandler } from 'react';
import cx from 'classnames';
import styles from '../sass/components/TextInput.module.scss';
import Alert from './Alert';

export interface TextInputProps {
  title?: string;
  disabled?: boolean;
  errorMessage?: string;
  success?: boolean;
  id?: string;
  value?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  rightIcon?: React.ReactNode;
  rightButton?: React.ReactNode;
  type?: string;
  className?: string;
  hint?: string;
  autocomplete?: string;
  name?: string
  max?: number
  min?: number
  maxlength?: number
  minlength?: number
  onKeyDown?: KeyboardEventHandler
  isRequired?: boolean
}

const TextInput = React.forwardRef<HTMLInputElement | null, TextInputProps>((
  props, ref,
) => {
  const {
    title,
    disabled = false,
    errorMessage,
    success,
    id,
    value,
    onChange,
    onBlur,
    placeholder,
    rightIcon,
    rightButton,
    type = 'text',
    onEnter,
    onClick,
    className,
    hint,
    autocomplete,
    name,
    max,
    min,
    maxlength,
    minlength,
    onKeyDown,
    isRequired,
  } = props;

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onEnter) {
      event.preventDefault();
      onEnter();
    }
  };

  return (
    <div className={cx(styles.textInputWrapper, className)}>
      {title && (
        <label
          htmlFor={id}
          className={cx({
            [styles.disabled]: disabled,
          })}
        >
          {title}
        </label>
      )}
      <div className={cx(styles.inputWrapper,
        { [styles.inputHasRightIcon]: !!rightIcon })}
      >
        <input
          ref={ref}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          onClick={onClick}
          onBlur={onBlur}
          id={id}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autocomplete}
          className={cx({
            [styles.inputDisabled]: disabled,
            [styles.error]: errorMessage,
            [styles.success]: success,
            [styles.hasRightButton]: !!rightButton,
          })}
          name={name}
          maxLength={maxlength}
          max={max}
          min={min}
          minLength={minlength}
          onKeyDown={onKeyDown}
          required={isRequired}
        />
        {rightButton && rightButton}
        {rightIcon && (
          <div className={styles.rightIcon}>
            {rightIcon}
          </div>
        )}
      </div>
      {hint && !errorMessage && (
        <div className={styles.hintWrapper}>
          <span className="smallLabel">{hint}</span>
        </div>
      )}
      <Alert message={errorMessage} type="error" />
    </div>
  );
});

export default TextInput;
