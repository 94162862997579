/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import { useLazyQuery, useMutation } from '@apollo/client';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import styles from '../sass/components/PayoutReportDetails.module.scss';
import {
  ROUTE_FRANCHISE_MANAGER_INVOICES,
  ROUTE_FRANCHISE_MANAGER_PAYOUTS,
  ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
} from '../util/constants';
import { api } from '../util/api';
import { PayoutReport, TotalInvoicesPayoutPeriod, TotalPaymentsPayoutPeriod } from '../interfaces/PayoutReport';
import {
  GET_PAYOUT_REPORT_FRANCHISE_MANAGER_BY_ID,
  PAY_PAYOUT_REPORT,
  GET_PAYOUT_REPORT_PAYMENTS,
  GET_PAYOUT_REPORT_INVOICES,
} from '../util/gql';
import Button from './Button';
import ConfirmationModal from './ConfirmationModal';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import {
  dayTimeString,
  dateString2,
} from '../util/formatDate';
import { setInvoiceId } from '../features/invoice/invoiceSlice';

interface TableData {
    id: number;
    name: string;
    value: string;
  }

  interface ErrorModalProps {
    message: string,
    title: string
  }

export default function PayoutReportDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [payoutReport, setPayoutReport] = useState<PayoutReport>();
  const [paymentsPayoutReports, setPaymentsPayoutReport] = useState<TotalPaymentsPayoutPeriod[]>();
  const [invoicesPayoutReports, setInvoicesPayoutReports] = useState<TotalInvoicesPayoutPeriod[]>();

  const payoutReportId = useAppSelector((state) => state.payoutReport.id)
    || Number(id);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [expandedPayments, setExpandedPayments] = useState<boolean>(false);
  const [expandedInvoices, setExpandedInvoices] = useState<boolean>(false);
  const [
    getPayoutReport, { loading },
  ] = useLazyQuery(GET_PAYOUT_REPORT_FRANCHISE_MANAGER_BY_ID, {
    onCompleted: (data) => {
      const payoutReportData = data.getPayoutReportFranchiseManagerById;
      setPayoutReport(payoutReportData);
    },
    fetchPolicy: 'network-only',
  });

  const [
    getReportDetails,
  ] = useLazyQuery(GET_PAYOUT_REPORT_PAYMENTS, {
    onCompleted: (data) => {
      const details = data.getPayoutReportPayments;
      setPaymentsPayoutReport(details.paymentDetails);
    },
    fetchPolicy: 'network-only',
  });

  const [
    getReportInvoices,
  ] = useLazyQuery(GET_PAYOUT_REPORT_INVOICES, {
    onCompleted: (data) => {
      const details = data.getPayoutReportInvoices;
      setInvoicesPayoutReports(details.invoiceDetails);
    },
    fetchPolicy: 'network-only',
  });

  const backToPayoutReports = (): void => navigate(
    ROUTE_FRANCHISE_MANAGER_PAYOUTS,
  );

  useEffect(() => {
    if (!payoutReportId) backToPayoutReports();
    getPayoutReport({ variables: { id: payoutReportId } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPayoutReport, payoutReportId]);

  useEffect(() => {
    if (payoutReport) {
      const invoicedAmount = (payoutReport.invoicedAmount / 100).toFixed(2);
      const totalPayments = (payoutReport.totalPayments / 100).toFixed(2);
      const totalTaxes = (payoutReport.totalTax / 100).toFixed(2);
      const transactionFee = (payoutReport.transactionFees / 100).toFixed(2);
      const marketingFee = (payoutReport.marketingFee / 100).toFixed(2);
      const royalties = (payoutReport.royaltyFee / 100).toFixed(2);
      const technologyFee = (payoutReport.technologyFee / 100).toFixed(2);
      const insurance = (payoutReport.insurance / 100).toFixed(2);
      const thirdPartyFleetFee = (payoutReport.thirdPartyFleetFee / 100).toFixed(2);
      const fleetFees = (payoutReport.nationalFleetFee / 100).toFixed(2);
      const cashPayments = (payoutReport.cashPayments / 100).toFixed(2);
      const total = (Math.abs(payoutReport.total) / 100).toFixed(2);
      const credit = (Math.abs(payoutReport.creditGoOil) / 100).toFixed(2);
      let totalText;
      if (payoutReport.total < 0) {
        totalText = 'Owing';
      } else {
        totalText = 'Owed';
      }
      let uniqueId = 0;
      const newTableData: TableData[] = [];

      newTableData.push({ id: uniqueId, name: 'Invoiced Amount Before Tax', value: `$${invoicedAmount}` });
      uniqueId += 1;

      newTableData.push({ id: uniqueId, name: 'Total Payments Received', value: `$${totalPayments}` });
      uniqueId += 1;

      newTableData.push({ id: uniqueId, name: 'Tax', value: `- $${totalTaxes}` });
      uniqueId += 1;

      newTableData.push({ id: uniqueId, name: 'Transaction Fee', value: `- $${transactionFee}` });
      uniqueId += 1;

      newTableData.push({ id: uniqueId, name: 'Marketing Fee', value: `- $${marketingFee}` });
      uniqueId += 1;

      newTableData.push({ id: uniqueId, name: 'Royalty Fee', value: `- $${royalties}` });
      uniqueId += 1;

      newTableData.push({ id: uniqueId, name: 'Technology Fee', value: `- $${technologyFee}` });
      uniqueId += 1;

      newTableData.push({ id: uniqueId, name: 'Insurance Fee', value: `- $${insurance}` });
      uniqueId += 1;

      newTableData.push({ id: uniqueId, name: 'Fleet Management Remittance Fee', value: `- $${thirdPartyFleetFee}` });
      uniqueId += 1;

      newTableData.push({ id: uniqueId, name: 'National Fleet Fee', value: `- $${fleetFees}` });
      uniqueId += 1;

      newTableData.push({ id: uniqueId, name: 'Collected Cash Payments', value: `- $${cashPayments}` });
      uniqueId += 1;

      if (payoutReport.creditGoOil < 0) {
        newTableData.push({ id: uniqueId, name: 'Unpaid Amount Previous Payout', value: `- $${credit}` });
        uniqueId += 1;
      } else {
        newTableData.push({ id: uniqueId, name: 'Unpaid Amount Previous Payout', value: ` $${credit}` });
        uniqueId += 1;
      }

      if (payoutReport.total >= 0) {
        newTableData.push({ id: uniqueId, name: `Total ${totalText}`, value: ` $${total}` });
      } else {
        newTableData.push({ id: uniqueId, name: `Total ${totalText}`, value: `- $${total}` });
      }
      setTableData(newTableData);
    }
  }, [payoutReport]);

  useEffect(() => {
    if (expandedPayments && payoutReport) {
      getReportDetails({
        variables: {
          id: payoutReportId,
          startDate: payoutReport.startDate,
          endDate: payoutReport.endDate,
          franchiseId: payoutReport.franchiseId,
        },
      });
    }
  }, [expandedPayments]);

  useEffect(() => {
    if (expandedInvoices && payoutReport) {
      getReportInvoices({
        variables: {
          id: payoutReportId,
          startDate: payoutReport.startDate,
          endDate: payoutReport.endDate,
          franchiseId: payoutReport.franchiseId,
        },
      });
    }
  }, [expandedInvoices]);

  const payoutName = () => {
    if (payoutReport) {
      const adjStartDate = dateString2(payoutReport.startDate);
      const adjEndDate = dateString2(payoutReport.endDate);
      let paid = '';
      if (payoutReport.status === 'PAID') {
        paid = ' - Paid';
      }
      if (payoutReport.status === 'PARTIALLY_PAID') {
        paid = ' - Partially Paid';
      }
      return (`Payout Statement for ${adjStartDate} - ${adjEndDate} ${paid}`);
    }
    return 'Payout Statement';
  };

  const [payPayoutReport] = useMutation(PAY_PAYOUT_REPORT, {
    onCompleted: (data: any) => {
      const checkoutUrl = data?.payPayoutReport.checkOutId;
      if (checkoutUrl) {
        window.open(checkoutUrl, '_blank');
      }
    },
    fetchPolicy: 'network-only',
  });

  const handlePayPayoutReport = (): void => {
    payPayoutReport({
      variables: { payoutReportId: payoutReportId || payoutReport?.id },
    });
  };

  const backToPayoutReport = (): void => navigate(
    `${ROUTE_FRANCHISE_MANAGER_PAYOUTS}/${payoutReportId}`,
  );

  const [
    downloadPayoutReportErrorModal,
    setDownloadPayoutReportErrorModal,
  ] = useState<boolean>(false);

  const handleCloseErrorModal = () => {
    setDownloadPayoutReportErrorModal(false);
    backToPayoutReport();
  };

  const DownloadPayoutReportErrorModal = ({ message, title }: ErrorModalProps) => (
    <ConfirmationModal
      onClose={handleCloseErrorModal}
      message={message}
      title={title}
      open={downloadPayoutReportErrorModal}
    />
  );

  const generateCSVPayments = () => {
    const headers = [
      'Invoice',
      'Invoiced Amount',
      'Remittance',
      'Paid Amount',
      'Method',
      'Status',
      'Created On',
      'Created By',
    ];

    const rows = paymentsPayoutReports?.map((row) => [
      row.invoice.id,
      `$${row.amount / 100}`,
      `$${row.remittanceFee / 100}`,
      `$${row.paidAmount / 100}`,
      row.paymentMethod,
      row.status,
      dayTimeString(row.createdAt),
      row.author.name,
    ]);

    let csvContent = `data:text/csv;charset=utf-8,${headers.join(',')}\n`;
    rows?.forEach((rowArray) => {
      const row = rowArray.join(',');
      csvContent += `${row}\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'payments_payout_reports.csv');
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

  const generateCSVInvoices = () => {
    const headers = [
      'Invoice Id',
      'Work Order Id',
      'Subtotal',
      'Total',
      'Created On',
      'Status',
      'Contact',
    ];

    const rows = invoicesPayoutReports?.map((row) => [
      row.id,
      row.workOrder.id,
      `$${row.subTotal / 100}`,
      `$${row.billedAmount / 100}`,
      dayTimeString(row.createdAt),
      row.status,
      row.contact?.name || 'Contact was deleted',
    ]);

    let csvContent = `data:text/csv;charset=utf-8,${headers.join(',')}\n`;
    rows?.forEach((rowArray) => {
      const row = rowArray.join(',');
      csvContent += `${row}\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'invoices_payout_reports.csv');
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

  const downloadPayoutReport = async () => {
    try {
      const response = await api.get(
        `account/franchisee/payout-report/${payoutReportId}`, {
          responseType: 'arraybuffer',
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payoutReport.pdf');
      document.body.appendChild(link);
      link.click();
    } catch {
      setDownloadPayoutReportErrorModal(true);
    }
  };

  const handleRedirectInvoice = (invoiceId: number) => {
    dispatch(setInvoiceId(invoiceId));
    navigate(`${ROUTE_FRANCHISE_MANAGER_INVOICES}/${invoiceId}`);
  };

  const handleRedirectWorkOrder = (workOrderId: number) => {
    dispatch(setInvoiceId(workOrderId));
    navigate(`${ROUTE_FRANCHISE_MANAGER_WORK_ORDERS}/${workOrderId}`);
  };

  return (
    <div className={styles.workOrderDetailsContainer}>
      <div className={styles.backButtonContainer}>
        <div className={styles.backButtonContent}>
          <Button
            className={cx(styles.caret)}
            type="button"
            onClick={backToPayoutReports}
            variant="icon"
          >
            <Arrow />
          </Button>
          <Button
            onClick={downloadPayoutReport}
            className={styles.downloadButton}
          >
            Download Payout Statement
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        <br />
        <h4>{payoutName()}</h4>
        <br />
      </div>
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th className={styles.tableHeader}>Type of Payment</th>
            <th className={styles.tableHeader}>Value</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => {
            const isLastRow = index === tableData.length - 1;
            return (
              <tr key={row.id} className={isLastRow ? styles.tableLastRow : ''}>
                <td className={isLastRow ? styles.tableLastRowName : styles.tableCellName}>{row.name}</td>
                <td className={isLastRow ? styles.tableLastRowValue : styles.tableHeader}>{row.value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      { payoutReport?.total && payoutReport.total < 0 && payoutReport?.status !== 'PAID' && payoutReport?.status !== 'PARTIALLY_PAID' && (
        <div className={styles.content}>
          {/* <h4> Pay Amount Owed </h4> */}
          <Button
            onClick={() => handlePayPayoutReport()}
            variant="primary"
            className={styles.editButton}
          >
            <h4> Pay Amount Owed </h4>
          </Button>
          <br />
          <br />
        </div>
      )}
      {(payoutReport?.status === 'PARTIALLY_PAID')
        && (
        <p className={styles.subtitle}>
          This payout report has already been partially paid.
          For further assistance or to complete the payment,
          please contact the admin.
        </p>
        )}
      {downloadPayoutReportErrorModal && (
      <DownloadPayoutReportErrorModal
        message="Error downloading the payout report, please try again."
        title="Payout Report Download"
      />
      )}
      <div className={styles.detailsToggleContainer}>
        <h4>
          {!expandedPayments ? 'Show Payments Received' : 'Hide Details Payments'}
        </h4>
        <Button
          className={cx(styles.caretToggle, { [styles.expanded]: expandedPayments })}
          type="button"
          onClick={() => setExpandedPayments(!expandedPayments)}
          variant="icon"
        >
          <Arrow />
        </Button>
      </div>
      { expandedPayments && (
        <>
          <div className={styles.paymentDetails}>
            <h5>
              Total Payments Received:
            </h5>
            <br />
            <p>
              Note: This table shows all payments in your account, only approved Payments are included in the Statement
            </p>
            <br />
          </div>
          <table className={styles.tableContainer2}>
            <thead>
              <tr>
                {/* <th className={styles.tableHeader2}>Payment Id</th> */}
                <th className={styles.tableHeader2}>Invoice</th>
                <th className={styles.tableHeader2}>Invoiced Amount</th>
                <th className={styles.tableHeader2}>Remittance</th>
                <th className={styles.tableHeader2}>Paid Amount</th>
                <th className={styles.tableHeader2}>Method</th>
                <th className={styles.tableHeader2}>Status</th>
                <th className={styles.tableHeader2}>Created On</th>
                <th className={styles.tableHeader2}>Created By</th>
              </tr>
            </thead>
            <tbody>
              {paymentsPayoutReports?.map((row, index) => (
                <tr key={row.id}>
                  {/* <td className={styles.tableHeader2}>{row.id}</td> */}
                  <td className={styles.tableHeaderBold}>
                    <Button
                      type="button"
                      onClick={() => handleRedirectInvoice(row.invoice.id)}
                      variant="icon"
                    >
                      {row.invoice.id}
                    </Button>
                  </td>
                  <td className={styles.tableHeader2}>{`$ ${row.amount / 100}`}</td>
                  <td className={styles.tableHeader2}>{`$ ${row.remittanceFee / 100} `}</td>
                  <td className={styles.tableHeader2}>{`$ ${row.paidAmount / 100}`}</td>
                  <td className={styles.tableHeader2}>{row.paymentMethod}</td>
                  <td className={styles.tableHeader2}>{row.status}</td>
                  <td className={styles.tableHeader2}>{dayTimeString(row.createdAt)}</td>
                  <td className={styles.tableHeader2}>{row.author.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <div className={styles.content}>
            <Button
              type="button"
              onClick={generateCSVPayments}
              variant="primary"
              className={styles.downloadButton2}
            >
              Download Payments CSV
            </Button>
          </div>
          <br />
        </>
      )}
      <div className={styles.detailsToggleContainer}>
        <h4>
          {!expandedInvoices ? 'Show Total Sales' : 'Hide Details Sales'}
        </h4>
        <Button
          className={cx(styles.caretToggle, { [styles.expanded]: expandedInvoices })}
          type="button"
          onClick={() => setExpandedInvoices(!expandedInvoices)}
          variant="icon"
        >
          <Arrow />
        </Button>
      </div>
      { expandedInvoices && (
        <>
          <div className={styles.paymentDetails}>
            <h5>
              Total Invoices Received:
            </h5>
            <br />
          </div>
          <table className={styles.tableContainer2}>
            <thead>
              <tr>
                <th className={styles.tableHeader2}>Invoice Id</th>
                <th className={styles.tableHeader2}>Work Order Id</th>
                <th className={styles.tableHeader2}>Subtotal</th>
                <th className={styles.tableHeader2}>Total</th>
                <th className={styles.tableHeader2}>Created On</th>
                <th className={styles.tableHeader2}>Status</th>
                <th className={styles.tableHeader2}>Contact</th>
              </tr>
            </thead>
            <tbody>
              {invoicesPayoutReports?.map((row, index) => (
                <tr key={row.id}>
                  <td className={styles.tableHeaderBold}>
                    <Button
                      type="button"
                      onClick={() => handleRedirectInvoice(row.id)}
                      variant="icon"
                    >
                      {row.id}
                    </Button>
                  </td>
                  <td className={styles.tableHeaderBold}>
                    <Button
                      type="button"
                      onClick={() => handleRedirectWorkOrder(row.workOrder.id)}
                      variant="icon"
                    >
                      {row.workOrder.id}
                    </Button>

                  </td>
                  <td className={styles.tableHeader2}>{`$ ${row.subTotal / 100}`}</td>
                  <td className={styles.tableHeader2}>{`$ ${row.billedAmount / 100}`}</td>
                  <td className={styles.tableHeader2}>{dayTimeString(row.createdAt)}</td>
                  <td className={styles.tableHeader2}>{row.status}</td>
                  <td className={styles.tableHeader2}>{row.contact?.name || 'Contact was deleted'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <div className={styles.content}>
            <Button
              type="button"
              onClick={generateCSVInvoices}
              variant="primary"
              className={styles.downloadButton2}
            >
              Download Sales CSV
            </Button>
          </div>
          <br />
        </>
      )}
    </div>
  );
}
