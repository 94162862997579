import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logout } from '../features/auth/authSlice';
import { clearPostalCode } from '../features/postalCode/postalCodeSlice';
import { api } from '../util/api';
import { ROUTE_AUTH_LOGIN, ROUTE_ROOT } from '../util/constants';

const useAuthentication = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const loggedIn = useAppSelector((
    state,
  ) => state.auth.isAuthenticated);
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);

  const logOut = () => {
    api.post('auth/logout', {}, {
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    }).finally(() => {
      if (loggedIn) {
        dispatch(logout());
        dispatch(clearPostalCode());
        if (location.pathname !== ROUTE_ROOT
          && location.pathname !== ROUTE_AUTH_LOGIN) {
          navigate(ROUTE_ROOT);
        }
      }
    });
  };

  const handleLogin = () => {
    if (loggedIn) {
      logOut();
    } if (location.pathname !== ROUTE_AUTH_LOGIN) {
      navigate(ROUTE_AUTH_LOGIN);
    }
  };

  return {
    loggedIn, handleLogin, logOut,
  };
};

export default useAuthentication;
