import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { ROUTE_ROOT } from '../util/constants';

interface RequireBookingProps {
  children: JSX.Element;
}

export default function RequireSelectedServices({
  children,
}: RequireBookingProps) {
  const bookingSuccess = useAppSelector((state) => (
    state.booking.success
  ));

  const location = useLocation();
  if (!bookingSuccess) {
    // redirect to the location page because you don't have a successful booking
    return <Navigate to={ROUTE_ROOT} state={{ from: location }} />;
  }

  return children;
}
