import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import {
  setCustomerId,
  setCustomerName,
} from '../features/customer/customerSlice';
import { User } from '../interfaces/User';
import styles from '../sass/components/TableRowView.module.scss';
import {
  ROUTE_BOOK_LOCATION,
  ROUTE_FRANCHISE_MANAGER_CUSTOMERS,
} from '../util/constants';
import Button from './Button';

export default function CustomerRowView(props: { data: User }) {
  const { data } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    name, id,
  } = data;

  const setCustomer = () => {
    dispatch(setCustomerId(id));
    dispatch(setCustomerName(name));
  };

  const handleNewBooking = () => {
    setCustomer();
    navigate(ROUTE_BOOK_LOCATION);
  };

  const viewCustomerDetails = () => {
    setCustomer();
    navigate((`${ROUTE_FRANCHISE_MANAGER_CUSTOMERS}/${id}`));
  };

  return (
    <div>
      <div className={styles.row}>
        <Button
          variant="link"
          className={styles.rowContent}
          onClick={viewCustomerDetails}
        >
          {name}
        </Button>
        <Button
          variant="secondary"
          className={styles.actions}
          onClick={handleNewBooking}
        >
          New Booking
        </Button>
      </div>
    </div>
  );
}
