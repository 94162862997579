import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FranchiseState {
  id?: number;
  name?: string;
}

const initialState: FranchiseState = {
  name: '',
};

export const franchiseSlice = createSlice({
  name: 'franchise',
  initialState,
  reducers: {
    setFrnchiseId: (state, action: PayloadAction<number>) => ({
      ...state,
      id: action.payload,
    }),
    setFranchiseName: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      name: action.payload,
    }),
  },
});

export const {
  setFrnchiseId,
  setFranchiseName,
} = franchiseSlice.actions;

export default franchiseSlice.reducer;
