import styles from '../sass/components/ServiceSelector.module.scss';
import AddOnList from './AddOnList';
import RegularServiceList from './RegularServiceList';
import Tab from './Tab';
import TabContainer from './TabContainer';

interface ServiceSelectorProps {
  type: 'primary' | 'secondary';
}

const primaryService = (
  <div>
    <span>Select One Service</span>
    <p>
      Battery replacement and tire change
      can be selected as add-ons in the next step
    </p>
  </div>
);

export default function ServiceSelector({ type }: ServiceSelectorProps) {
  return (
    <div className={styles.services}>
      <TabContainer>
        <Tab
          className="container__col-xs-12"
          tab
          selected={false}
          presentational
        >
          {
            type === 'primary'
              ? primaryService
              : 'Select Optional Add-Ons'
          }
        </Tab>
      </TabContainer>
      <div className={styles.scrollable}>
        {
          type === 'primary' ? (
            <RegularServiceList />
          ) : (
            <AddOnList />
          )
        }
      </div>
    </div>
  );
}
