import { ApolloError, useMutation } from '@apollo/client';
import { FormEvent, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useAppSelector } from '../app/hooks';
import styles from '../sass/components/TableRowEdit.module.scss';
import { getFieldErrors } from '../util/getFieldErrors';
import {
  CREATE_CONTACT,
  GET_CONTACTS_BY_USER_ID,
  UPDATE_CONTACT,
} from '../util/gql';
import Button from './Button';
import TextInput from './TextInput';

export default function FranchiseContactRowEdit(
  props: {
    data: {
      id: number;
      name: string;
      email: string;
      phoneNumber: string;
    }, setIsEditing: () => void },
) {
  const {
    data, setIsEditing,
  } = props;

  const [constraints, setConstraints] = useState([]);
  const customer = useAppSelector((state) => state.customer);
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const [
    currentName,
    setCurrentName,
  ] = useState<string | undefined>(
    data.name,
  );

  const [
    currentPhone,
    setCurrentPhone,
  ] = useState<string | undefined>(
    data.phoneNumber,
  );

  const [
    currentEmail,
    setCurrentEmail,
  ] = useState<string | undefined>(
    data.email,
  );

  const [createContact, {
    loading: createContactLoading,
  }] = useMutation(CREATE_CONTACT);

  const [updateContact, {
    loading: updateContactLoading,
  }] = useMutation(UPDATE_CONTACT);

  const refetchQueries = [
    {
      query: GET_CONTACTS_BY_USER_ID,
      variables: { userId: customer.id },
    },
  ];

  const handleSave = async () => {
    setConstraints([]);
    if (data.id) {
      try {
        await updateContact({
          variables: {
            name: currentName,
            phoneNumber: currentPhone,
            email: currentEmail,
            id: data.id || 0,
          },
          refetchQueries,
        });
        setIsEditing();
      } catch (ex) {
        if (ex instanceof ApolloError) {
          const {
            message,
          } = ex.graphQLErrors[0].extensions.response;
          setConstraints(message);
        }
      }
    } else {
      try {
        await createContact({
          variables: {
            name: currentName,
            phoneNumber: currentPhone,
            email: currentEmail,
          },
          refetchQueries,
        });
        setIsEditing();
      } catch (ex) {
        if (ex instanceof ApolloError) {
          const {
            message,
          } = ex.graphQLErrors[0].extensions.response;
          setConstraints(message);
        }
      }
    }
  };

  return (
    <div className={styles.row}>
      <div className={styles.rowContent}>
        <div className={styles.subRow}>
          <TextInput
            id="name"
            value={currentName}
            title="Name"
            className={styles.input}
            onChange={
                      (event: FormEvent<HTMLInputElement>) => (
                        setCurrentName(event.currentTarget.value))
                    }
          />
          <NumberFormat
            id="phone"
            value={currentPhone}
            title="Phone Number"
            className={styles.input}
            errorMessage={
                      getFieldErrors(constraints, ['phoneNumber'])
                    }
            onChange={
                      (event: FormEvent<HTMLInputElement>) => (
                        setCurrentPhone(event.currentTarget.value))
                    }
            format="(###) ###-####"
            mask="_"
            allowEmptyFormatting
            customInput={TextInput}
          />
        </div>
        <div className={styles.subRow}>
          <TextInput
            id="email"
            value={currentEmail}
            title="Email"
            className={styles.input}
            errorMessage={
                      getFieldErrors(constraints, ['email'])
                    }
            onChange={
                      (event: FormEvent<HTMLInputElement>) => (
                        setCurrentEmail(event.currentTarget.value))
                    }
          />
          <div className={styles.buttons}>
            <Button
              variant="tertiary"
              onClick={() => setIsEditing()}
              key={`cancel-${data?.id}`}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSave}
              key={`save-${data?.id}`}
              inactive={!currentName || !currentPhone || !currentEmail}
              loading={createContactLoading || updateContactLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
