/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { EditNotesProps } from '../interfaces/Components';
import styles from '../sass/components/LineItemEditor.module.scss';
import ModalButtonsContainer from './ModalButtonsContainer';
import TextInput from './TextInput';

export default function LineItemsEditor({
  onClose,
  onSubmit,
  workOrderDetails,
  setWorkOrderDetails,
  notes,
  setNotes,
}: EditNotesProps) {
  return (
    <div className={styles.body}>
      <h4>Edit Notes</h4>
      <p>
        Notes:
      </p>
      <br />
      {/* <TextInput
        className={styles.poNumber}
        title=""
        placeholder="Notes"
        type="text"
        onChange={(e) => setNotes(e.currentTarget.value)}
        value={notes}
      /> */}
      <textarea
        className={styles.poNumber}
        placeholder="Notes"
        onChange={(e) => setNotes(e.target.value)}
        value={notes}
        rows={4}
        cols={100}
        style={{ resize: 'both' }}
      />

      <br />
      <ModalButtonsContainer
        onClose={onClose}
        onClick={onSubmit}
        label="Submit"
      />
    </div>
  );
}
