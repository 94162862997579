import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import {
  ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
}
  from '../util/constants';

interface RequireCustomerProps {
  children: JSX.Element;
}

export default function RequiredCustomer({
  children,
}: RequireCustomerProps) {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const location = useLocation();

  if (currentUser.roles?.includes('manager')
  || currentUser.roles?.includes('technician')) {
    return (
      <Navigate
        to={ROUTE_FRANCHISE_MANAGER_WORK_ORDERS}
        state={{ from: location }}
      />
    );
  }

  return children;
}
