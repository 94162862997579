import { ApolloError, useMutation } from '@apollo/client';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styles from '../sass/components/LocationNotFoundModal.module.scss';
import { getFieldErrors } from '../util/getFieldErrors';
import { CREATE_BOOKING_ASSISTANCE_NOTIFICATION } from '../util/gql';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';
import TextArea from './TextArea';
import TextInput from './TextInput';

interface LocationNotFoundModalProps {
  postalCode: string;
  onTryAnotherPostalCode: () => void;
  onSubmit: () => void;
}

export default function LocationNotFoundModal({
  postalCode,
  onTryAnotherPostalCode,
  onSubmit,
}: LocationNotFoundModalProps) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [constraints, setConstraints] = useState([]);
  const [createBookingAssistanceNotification, {
    loading,
  }] = useMutation(CREATE_BOOKING_ASSISTANCE_NOTIFICATION);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const sendMessage = useCallback(async (token: string) => {
    try {
      await createBookingAssistanceNotification({
        variables: {
          name,
          phoneNumber: phone,
          email,
          body: messageBody,
          recaptchaToken: token,
          postalCode,
        },
      });
    } catch (ex) {
      if (ex instanceof ApolloError) {
        const {
          message,
          statusCode,
        } = ex.graphQLErrors[0].extensions.response;
        if (statusCode === 422) {
          setConstraints(message);
        }
      }
      return;
    }
    onSubmit();
  },
  [createBookingAssistanceNotification,
    email,
    messageBody,
    name,
    onSubmit,
    phone,
    postalCode]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      // recaptcha is not available
      return;
    }

    const token = await executeRecaptcha('BookingAssistance');
    sendMessage(token);
  }, [executeRecaptcha, sendMessage]);

  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onTryAnotherPostalCode} />
      <div className={styles.header}>
        <Button
          variant="tertiary"
          onClick={onTryAnotherPostalCode}
        >
          <ChevronLeft />
          Back to Booking
        </Button>
      </div>
      <div className={styles.body}>

        <h4>Let&apos;s try to book you an appointment</h4>
        <p>
          Sorry, the postal code you provided is outside of our default
          service area, but that doesn&apos;t mean we can&apos;t help!
          Contact our team to see if we can still make an
          appointment happen for you.
        </p>
        <div>
          <form>
            <div className={styles.upperForm}>
              <TextInput
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                title="Name"
                id="name"
                className={styles.formElement}
                errorMessage={getFieldErrors(constraints, ['message', 'name'])}
              />
              <TextInput
                value={postalCode}
                onChange={() => undefined}
                title="Postal Code"
                id="postal-code"
                disabled
                className={styles.formElement}
              />
              <TextInput
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                title="Email"
                id="email"
                className={styles.formElement}
                errorMessage={getFieldErrors(constraints, ['message', 'email'])}
              />
              <TextInput
                value={phone}
                onChange={(e) => setPhone(e.currentTarget.value)}
                title="Phone Number"
                id="phone"
                className={styles.formElement}
                errorMessage={
                  getFieldErrors(constraints, ['message', 'phoneNumber'])
                }
              />
            </div>
            <TextArea
              value={messageBody}
              onChange={(e) => setMessageBody(e.currentTarget.value)}
              title="Message"
              id="message"
              rows={4}
            />
          </form>
          <div className={styles.actionButtons}>
            <Button
              variant="tertiary"
              onClick={onTryAnotherPostalCode}
              className={styles.tryAnotherButton}
            >
              Try another postal code
            </Button>
            <Button
              variant="primary"
              onClick={handleReCaptchaVerify}
              className={styles.submitButton}
              loading={loading}
            >
              Send Message
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
