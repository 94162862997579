import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface PostalCodeState {
  postalCode: string;
  isValid: boolean;
}

const initialState: PostalCodeState = {
  postalCode: '',
  isValid: false,
};

export const postalCodeSlice = createSlice({
  name: 'postalCode',
  initialState,
  reducers: {
    setPostalCode: (state, action: PayloadAction<string>) => ({
      ...state,
      postalCode: action.payload,
    }),
    setIsPostalCodeValid: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isValid: action.payload,
    }),
    clearPostalCode: (state) => ({
      ...state,
      isValid: false,
      postalCode: '',
    }),
  },
});

export const {
  setPostalCode,
  setIsPostalCodeValid,
  clearPostalCode,
} = postalCodeSlice.actions;

export const selectPostalCode = (
  state: RootState,
) => state.postalCode.postalCode;

export const selectIsPostalCodeValid = (
  state: RootState,
) => state.postalCode.isValid;

export default postalCodeSlice.reducer;
