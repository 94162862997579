/* eslint-disable max-len */
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import styles from '../sass/components/FranchiseList.module.scss';
import { GET_FRANCHISES_OF_FRANCHISE_MANAGER } from '../util/gql';
import { useAppDispatch } from '../app/hooks';
import {
  setFrnchiseId,
  setFranchiseName,
} from '../features/franchise/franchiseSlice';
import Button from './Button';
import {
  ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
} from '../util/constants';

interface Franchise {
  __typename: string;
  id: number;
  name: string;
}

export default function FranchisesList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    data,
  } = useQuery(GET_FRANCHISES_OF_FRANCHISE_MANAGER, {
    fetchPolicy: 'network-only',
  });

  const setFranchise = (id: number, name: string) => {
    dispatch(setFrnchiseId(id));
    dispatch(setFranchiseName(name));
  };

  const handleFranchiseSelection = (id: number, name: string) => {
    setFranchise(id, name);
    navigate(ROUTE_FRANCHISE_MANAGER_WORK_ORDERS);
  };
  return (

    <div className={styles.customersInformation}>
      {data && data.getFranchisesOfFranchiseManager && (
      <div className={styles.franchiseItem}>
        <table className={styles.franchiseListTable}>
          <tbody>
            {data.getFranchisesOfFranchiseManager.map((franchise: Franchise) => (
              <tr key={franchise.id} className={styles.franchiseRow}>
                <td className={styles.franchiseNameCell}>
                  {franchise.name}
                </td>
                <td className={styles.selectFranchiseBtnCell}>
                  <Button
                    variant="secondary"
                    className={styles.selectFranchiseBtn}
                    onClick={() => handleFranchiseSelection(franchise.id, franchise.name)}
                  >
                    Select Franchise
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      )}
    </div>
  );
}
