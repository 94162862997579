import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { ROUTE_AUTH_LOGIN } from '../util/constants';

interface RequireLoggedInProps {
  children: JSX.Element;
}

export default function RequireLoggedIn({ children }: RequireLoggedInProps) {
  const isAuthenticated = useAppSelector((state) => (
    state.auth.isAuthenticated
  ));

  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={ROUTE_AUTH_LOGIN} state={{ from: location }} />;
  }

  return children;
}
