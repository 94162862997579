import { GroupedPayoutReports, PayoutReport } from '../interfaces/PayoutReport';
import styles from '../sass/components/WorkOrderList.module.scss';
import PayoutReportRow from './PayoutReportRow';

interface PayoutReportListProps {
  payoutReports: GroupedPayoutReports
}

export default function WorkOrderList({ payoutReports }:
  PayoutReportListProps) {
  return (
    <>
      {Object.keys(payoutReports).map((endDate) => (
        <div key={endDate}>
          <div className={styles.date}>
            {endDate}
          </div>
          {payoutReports[endDate].map((pr: PayoutReport) => (
            <div key={pr.id}>
              <PayoutReportRow payoutReport={pr} />
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
