import { useLazyQuery, useQuery } from '@apollo/client';
import cx from 'classnames';
import pick from 'lodash/pick';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Button from '../components/Button';
import Form from '../components/Form';
import { Column, Container, Row } from '../components/Grid';
import LocationNotFoundModal from '../components/LocationNotFoundModal';
import MessageSentModal from '../components/MessageSentModal';
import Modal from '../components/Modal';
import PreviousSelector, { Selection } from '../components/PreviousSelector';
import SearchLocationInput from '../components/SearchLocationInput';
import {
  clearAllAddOns,
} from '../features/addOns/addOnsSlice';
import {
  setPostalCode,
} from '../features/postalCode/postalCodeSlice';
import {
  Address,
  setNewServiceAddress,
  setServiceAddress,
} from '../features/serviceLocation/serviceLocationSlice';
import {
  clearAllSelectedServices,
} from '../features/serviceTypes/serviceTypesSlice';
import useAuthentication from '../hooks/useAuthentication';
import styles from '../sass/components/Location.module.scss';
import {
  ROUTE_AUTH_LOGIN,
  ROUTE_BOOK_SERVICES,
} from '../util/constants';
import { getFieldErrors } from '../util/getFieldErrors';
import {
  GET_ADDRESSES,
  GET_POSTAL,
  GET_CUSTOMERS_OF_FRANCHISE_MANAGER,
  GET_ADDRESSES_BY_USER_ID,
} from '../util/gql';
import { User } from '../interfaces/User';
import {
  setCustomerId,
  setCustomerName,
} from '../features/customer/customerSlice';
import { selectNewVehicle } from '../features/car/carSlice';
import Badge from '../components/Badge';
import SearchableSelect from '../components/SearchableSelect';

export default function Location() {
  const navigate = useNavigate();
  const [notInServiceModalOpen, setNotInServiceModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [constraints, setConstraints] = useState([]);
  const [
    isValidAddress,
    setIsValidAddress,
  ] = useState<undefined | boolean>(false);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [customers, setCustomers] = useState<User[]>([]);
  const postalCode = useAppSelector((state) => (
    state.postalCode.postalCode
  ));
  const [location, setLocation] = useState(postalCode);

  const loggedIn = useAppSelector((
    state,
  ) => state.auth.isAuthenticated);

  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const franchiseIdSelect = useAppSelector((state) => state.franchise.id);
  const customer = useAppSelector((state) => state.customer);

  const {
    selectedAddress,
    serviceAddress,
    newServiceAddress,
  } = useAppSelector((s) => s.serviceLocation);

  const dispatch = useAppDispatch();

  useQuery(GET_POSTAL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { isInService, code } = data.getPostal;
      if (isInService) {
        dispatch(setPostalCode(code));
      }
      setIsValidAddress(isInService);
    },
    variables: {
      code: serviceAddress?.postalCode,
    },
    skip: !serviceAddress?.postalCode,
  });

  const handleClearSelectedServices = useCallback(() => {
    dispatch(clearAllSelectedServices());
  }, [dispatch]);

  const handleClearSelectedAddOns = useCallback(() => {
    dispatch(clearAllAddOns());
  }, [dispatch]);

  const { logOut, loggedIn: isLoggedIn } = useAuthentication();

  const cleanSelected = () => {
    handleClearSelectedServices();
    handleClearSelectedAddOns();
  };

  const {
    data: addressesData,
    loading: addressesLoading,
    error: addressesError,
  } = useQuery(GET_ADDRESSES, {
    skip: !isLoggedIn || currentUser.roles?.includes('manager')
    || currentUser.roles?.includes('technician'),
    fetchPolicy: 'network-only',
  });

  const [
    loadCustomerAddressData,
    { data: customerAddressesData },
  ] = useLazyQuery(GET_ADDRESSES_BY_USER_ID);

  useEffect(() => {
    if (customer.id !== undefined) {
      loadCustomerAddressData({
        variables: {
          userId: customer.id,
        },
      });
    }
  }, [customer.id, loadCustomerAddressData]);

  useEffect(() => {
    let retrievedAddress;
    if ((currentUser.roles?.includes('manager')
      || currentUser.roles?.includes('technician'))
      && customerAddressesData) {
      retrievedAddress = customerAddressesData.getAddressesByUserId;
    } else if (addressesData) {
      retrievedAddress = addressesData.getAddresses;
    }

    if (retrievedAddress) {
      setAddresses(retrievedAddress
        .map((a: Address) => (
          pick(
            a,
            [
              'street',
              'city',
              'postalCode',
              'lat',
              'lng',
              'country',
              'province',
              'countryCode',
              'provinceCode',
            ],
          ))));
    }
  }, [addressesData, currentUser.roles,
    customerAddressesData, selectedAddress]);

  useEffect(() => {
    if (selectedAddress === 'unset' && addresses.length > 0) {
      dispatch(setServiceAddress([0, addresses[0]]));
    } else if (selectedAddress !== 'new' && selectedAddress !== 'unset') {
      dispatch(
        setServiceAddress([selectedAddress, addresses[selectedAddress]]),
      );
    }
  }, [addresses, selectedAddress, dispatch]);

  const [validateLocation, { loading }] = useLazyQuery(GET_POSTAL, {
    fetchPolicy: 'no-cache',
    onError: (e) => {
      const { message, statusCode } = e.graphQLErrors[0].extensions.response;
      if (statusCode === 422) {
        setConstraints(message);
      }
    },
    onCompleted: (data) => {
      if (data?.getPostal.isInService) {
        if (location !== postalCode) {
          cleanSelected();
        }
        setIsValidAddress(true);
        navigate(ROUTE_BOOK_SERVICES);
      } else {
        setNotInServiceModalOpen(true);
        setIsValidAddress(false);
        cleanSelected();
      }
    },
  });

  const addressStrings = addresses
    .map((a) => `${a.street}, ${a.city} ${a.postalCode}`);

  const checkLocation = () => {
    setConstraints([]);
    if (postalCode !== '') {
      validateLocation({
        variables: {
          code: postalCode,
        },
      });
    }
  };

  const onChangeAddressSelector = useCallback((s: Selection) => {
    if (s === 'new' || s === 'unset') {
      setIsValidAddress(false);
      dispatch(setNewServiceAddress(newServiceAddress));
    } else {
      dispatch(setServiceAddress([s, addresses[s]]));
    }
  }, [dispatch, newServiceAddress, addresses]);

  const goToLogin = () => {
    navigate(ROUTE_AUTH_LOGIN);
  };

  const tryAnotherCode = () => {
    setNotInServiceModalOpen(!notInServiceModalOpen);
    setLocation('');
  };

  const displaySuccess = () => {
    setNotInServiceModalOpen(false);
    setSuccessModalOpen(true);
  };

  const {
    data: customersData,
  } = useQuery(GET_CUSTOMERS_OF_FRANCHISE_MANAGER, {
    variables: {
      franchiseId: franchiseIdSelect || currentUser.franchiseId,
    },
    skip: (!currentUser.roles?.includes('manager')
    && !currentUser.roles?.includes('technician')),
    fetchPolicy: 'network-only',
  });

  const selectCustomer = useCallback((id, name) => {
    if (id !== customer.id) {
      dispatch(selectNewVehicle());
      dispatch(setCustomerId(id));
    }
    if (name !== customer.name) {
      dispatch(setCustomerName(name));
    }
  }, [customer.id, customer.name, dispatch]);

  const handleCustomerChange = (selectedCustomerId: number) => {
    const customerName = customers.find(
      (user: User) => user.id === selectedCustomerId,
    );
    selectCustomer(selectedCustomerId, customerName?.name);
  };

  useEffect(() => {
    if (customersData) {
      setCustomers(customersData.getCustomersOfFranchiseManager
        .map((u: User) => (
          pick(
            u,
            [
              'name',
              'id',
            ],
          ))));
    }
  }, [customersData]);

  return (
    <div className={styles.wrapper}>
      <div>
        <Container>
          <Row>
            <Column
              sm={12}
              md={6}
              mdOffset={3}
            >
              <div className={styles.card}>
                <Container fluid>
                  <Row>
                    <Column
                      xs={12}
                    >
                      {(currentUser.roles?.includes('manager')
                      || currentUser.roles?.includes('technician'))
                      && (
                      <Badge
                        variant="primary"
                      >
                        Franchise Manager
                      </Badge>
                      )}
                      <div className={styles.header}>
                        <h1>
                          {(currentUser.roles?.includes('manager')
                          || currentUser.roles?.includes('technician'))
                            ? 'Select Customer and Address'
                            : 'What is your location?'}
                        </h1>
                        <p>
                          {(currentUser.roles?.includes('manager')
                          || currentUser.roles?.includes('technician'))
                            // eslint-disable-next-line max-len
                            ? 'If you are creating a booking for a new customer, leave the customer field blank and only enter the service address.'
                            : ''}
                        </p>
                        <br />
                        <p>
                          {(currentUser.roles?.includes('manager')
                          || currentUser.roles?.includes('technician'))
                            // eslint-disable-next-line max-len
                            ? 'Select the customer you are booking for and their service address.'
                            : 'Enter your Canadian or US Address'}
                        </p>
                      </div>
                    </Column>
                  </Row>
                  {(currentUser.roles?.includes('manager')
                  || currentUser.roles?.includes('technician')) && (
                    <Row>
                      <SearchableSelect
                        title="Customer"
                        value={customer.id
                          ? { label: customer.name, value: customer.id }
                          : undefined}
                        placeholder="Select a customer"
                        customers={customers.map((m: {
                        name: string;
                        id: number;
                      }) => ({
                          value: m.id,
                          label: m.name,
                        }))}
                        onSelect={(e) => handleCustomerChange(e)}
                      />
                    </Row>
                  )}
                  <Row>
                    {customer.id
                       && (
                       <div className={styles.label}>
                         <br />
                         <p className="label"> Service Address </p>
                       </div>
                       )}
                    <Column xs={12}>
                      <PreviousSelector
                        data={addressStrings}
                        newLabel="Add another address"
                        loading={addressesLoading}
                        error={!!addressesError}
                        name="address"
                        selected={selectedAddress}
                        onChange={onChangeAddressSelector}
                        className={styles.addressSelector}
                      >
                        <SearchLocationInput
                          className={styles.locationInput}
                          id="address"
                          value={newServiceAddress}
                          onChange={(a) => dispatch(setNewServiceAddress(a))}
                          title={(currentUser.roles?.includes('manager')
                            || currentUser.roles?.includes('technician'))
                            && customer.id ? '' : 'Service Address'}
                          errorMessage={
                            !!serviceAddress
                            && !!getFieldErrors(
                              constraints, ['address', 'postalCode'],
                            )
                              ? 'Please enter a valid address' : ''
                            }
                          success={!!serviceAddress && isValidAddress}
                        />
                      </PreviousSelector>
                      <Button
                        variant="primary"
                        size="large"
                        loading={loading}
                        onClick={checkLocation}
                        className={styles.goButton}
                        inactive={isValidAddress === false}
                      >
                        Get Started
                      </Button>
                      <div className={styles.loginSection}>
                        {!loggedIn ? (
                          <>
                            <span className={cx(styles.loginHeader, 'label')}>
                              Have an Account?
                            </span>
                            <Button
                              variant="secondary"
                              className={styles.loginButton}
                              onClick={goToLogin}
                              size="large"
                            >
                              Log In
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="secondary"
                            className={styles.loginButton}
                            onClick={logOut}
                            size="large"
                          >
                            Log Out
                          </Button>
                        )}
                      </div>
                    </Column>
                  </Row>
                </Container>
              </div>
              <Modal
                open={notInServiceModalOpen}
                disableBackdropClick
                onClose={tryAnotherCode}
              >
                <Form>
                  <LocationNotFoundModal
                    onSubmit={displaySuccess}
                    onTryAnotherPostalCode={tryAnotherCode}
                    postalCode={location}
                  />
                </Form>
              </Modal>
              <Modal
                open={successModalOpen}
                disableBackdropClick
                onClose={() => setSuccessModalOpen(false)}
              >
                <MessageSentModal
                  onClose={() => setSuccessModalOpen(false)}
                />
              </Modal>
            </Column>
          </Row>
        </Container>
      </div>
    </div>
  );
}
