import { Address } from '../features/serviceLocation/serviceLocationSlice';

export function computeAddressFromGooglePlacesResult(
  newServiceAddress?: google.maps.places.PlaceResult,
): Address | null {
  if (newServiceAddress) {
    const streetNumber = newServiceAddress?.address_components?.find(
      (component: google.maps.GeocoderAddressComponent) => (
        component.types.includes('street_number')),
    )?.long_name || '';
    const street = newServiceAddress?.address_components?.find(
      (component: google.maps.GeocoderAddressComponent) => (
        component.types.includes('route')),
    )?.long_name || '';
    const city = newServiceAddress?.address_components?.find(
      (component: google.maps.GeocoderAddressComponent) => (
        component.types.includes('locality')),
    )?.long_name || '';
    const postalCode = newServiceAddress?.address_components?.find(
      (component: google.maps.GeocoderAddressComponent) => (
        component.types.includes('postal_code')),
    )?.long_name || '';
    const country = newServiceAddress?.address_components?.find(
      (component) => component.types.includes('country'),
    )?.long_name || '';
    const province = newServiceAddress?.address_components?.find(
      (component) => component.types.includes('administrative_area_level_1'),
    )?.long_name || '';
    const countryCode = newServiceAddress?.address_components?.find(
      (component) => component.types.includes('country'),
    )?.short_name || '';
    const provinceCode = newServiceAddress?.address_components?.find(
      (component) => component.types.includes('administrative_area_level_1'),
    )?.short_name || '';
    const lat = newServiceAddress?.geometry?.location?.lat();
    const lng = newServiceAddress?.geometry?.location?.lng();

    return {
      street: `${streetNumber} ${street}`,
      city,
      postalCode,
      province,
      country,
      countryCode,
      provinceCode,
      lat,
      lng,
    };
  }
  return null;
}
