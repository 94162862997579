import cx from 'classnames';
import { useState } from 'react';
import { TabDictionary } from '../interfaces/TabDictionary';
import styles from '../sass/components/InformationSection.module.scss';

interface TabProps {
  tabs: TabDictionary,
  initialState: string
}

export default function InformationSection({ tabs, initialState }: TabProps) {
  const [tabOpen, setTabOpen] = useState(initialState);
  return (
    <div className={styles.yourInformation}>
      <div className={styles.tabs}>
        {Object.keys(tabs).map((selectedTab: string) => (
          <button
            key={selectedTab}
            type="button"
            className={cx(styles.tab, {
              [styles.tabSelected]: selectedTab === tabOpen,
            })}
            onClick={() => setTabOpen(selectedTab)}
          >
            <h6 className={styles.desktopTab}>
              {tabs[selectedTab].desktopValue}
            </h6>
            <h6 className={styles.mobileTab}>
              {tabs[selectedTab].mobileValue}
            </h6>
          </button>
        ))}
      </div>
      <div className={styles.content}>
        {tabOpen && tabs[tabOpen].component}
      </div>
    </div>
  );
}
