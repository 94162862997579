import { useState } from 'react';
import { AssignWorkOrderProps } from '../interfaces/Components';
import styles from '../sass/components/ErrorModal.module.scss';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';
import Select from './Select';

export default function AssignWorkOrder({
  onClose,
  franchises,
  franchiseId,
  assignedFranchise,
  setAssignedFranchise,
  onClick,
}: AssignWorkOrderProps) {
  const [newFranchise, setNewFranchise] = useState<number>();
  const findFranchiseId = (id: number) => {
    // eslint-disable-next-line max-len
    const franchise = franchises.find((franchiseSelect) => franchiseSelect.id === id);
    return franchise ? franchise.id.toString() : '1';
  };

  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Assign Work Order</h4>
        <p> Select a Franchise this WorkOrder should be assigned to </p>
        <br />
        <Select
          label=""
          loading={false}
          id="Franchise Select"
          placeholder="Select a Franchise"
          value={
                assignedFranchise ? findFranchiseId(assignedFranchise)
                  : ''
                }
          onChange={(e) => {
            const selectedId = Number(e.currentTarget.value);
            setAssignedFranchise(selectedId);
          }}
        >
          {franchises.map((franch) => (
            <option key={franch.id} value={franch.id}>
              {franch.id === franchiseId
                ? `${franch.name} (currently selected)`
                : franch.name}
            </option>
          ))}
        </Select>

        <br />
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={onClose}
            variant="tertiary"
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            onClick={onClick}
            variant="primary"
          >
            Confirm
          </Button>

        </div>
      </div>
    </div>
  );
}
