import { GroupedWorkOrders } from '../interfaces/WorkOrder';
import styles from '../sass/components/WorkOrderList.module.scss';
import WorkOrderRow from './WorkOrderRow';

interface WorkOrderListProps {
  workOrders: GroupedWorkOrders
}

export default function WorkOrderList({ workOrders }: WorkOrderListProps) {
  return (
    <>
      {Object.keys(workOrders).map((scheduledDate) => (
        <div key={scheduledDate}>
          <div className={styles.date}>
            {scheduledDate}
          </div>
          {workOrders[scheduledDate].map((wo) => (
            <div key={wo.id}>
              <WorkOrderRow workOrder={wo} />
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
