import { forwardRef } from 'react';
import styles from '../sass/components/Alert.module.scss';

export interface AlertProps {
  message?: string;
  type?: string;
}

const Alert = forwardRef<HTMLInputElement | null, AlertProps>((
  props,
) => {
  const { message, type } = props;

  return message ? (
    <div className={`${styles.alert} ${type}`}>
      {message}
    </div>
  ) : null;
});

export default Alert;
