import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import styles from '../sass/components/CarViewer.module.scss';
import { ROUTE_BOOK_SERVICES } from '../util/constants';
import Button from './Button';

export default function CarViewer() {
  const car = useAppSelector((state) => state.car);
  const navigate = useNavigate();
  return (
    <div className={styles.carViewerBox}>
      <div className={styles.header}>
        <h4>Your Vehicle</h4>
        <Button
          variant="tertiary"
          onClick={() => navigate(ROUTE_BOOK_SERVICES)}
        >
          Edit
        </Button>
      </div>
      <p>{`${car.make} ${car.model} ${car.year}`}</p>
    </div>
  );
}
