/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import cx from 'classnames';
import {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import Tesseract from 'tesseract.js';
import { VehicleInspectionProps } from '../interfaces/Components';
import styles from '../sass/components/VehicleInspection.module.scss';
import ModalButtonsContainer from './ModalButtonsContainer';
import TextInput from './TextInput';
import Checkbox from './Checkbox';
import CameraAccess from './CameraAccess';
import AwsUpload from './AwsUpload';

export default function VehicleInspection({
  onClose,
  onClick,
  inspectionData,
  setInspectionData,
  checkedFrontLeft,
  setCheckedFrontLeft,
  checkedFrontRight,
  setCheckedFrontRight,
  checkedRearLeft,
  setCheckedRearLeft,
  checkedRearRight,
  setCheckedRearRight,
  isContinueButtonInactive,
  tinLocationFrontLeft,
  setTinLocationFrontLeft,
  tinLocationFrontRight,
  setTinLocationFrontRight,
  tinLocationRearLeft,
  setTinLocationRearLeft,
  tinLocationRearRight,
  setTinLocationRearRight,
}: VehicleInspectionProps) {
  const [cameraAccessTinFrontLeft, setCameraAccessTinFrontLeft] = useState(false);
  const [cameraAccessTinFrontRight, setCameraAccessTinFrontRight] = useState(false);
  const [cameraAccessTinRearLeft, setCameraAccessTinRearLeft] = useState(false);
  const [cameraAccessTinRearRight, setCameraAccessTinRearRight] = useState(false);

  const [tinFrontLeft, setTinFrontLeft] = useState(false);
  const [tinFrontRight, setTinFrontRight] = useState(false);
  const [tinRearLeft, setTinRearLeft] = useState(false);
  const [tinRearRight, setTinRearRight] = useState(false);

  const [imageToProcess, setImageToProcess] = useState(null);
  const [tinImageName, setTinImageName] = useState<string>('');

  const cameraAccessRef = useRef<{ openCamera:() => void }>(null);

  const handleTinFrontLeft = () => {
    setCameraAccessTinFrontLeft(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  const handleTinFrontRight = () => {
    setCameraAccessTinFrontRight(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  const handleTinRearLeft = () => {
    setCameraAccessTinRearLeft(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  const handleTinRearRight = () => {
    setCameraAccessTinRearRight(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  useEffect(() => {
    handleTinFrontLeft();
    handleTinFrontRight();
    handleTinRearLeft();
    handleTinRearRight();
  }, []);
  const handleCheckboxChangeFrontLeft = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedFrontLeft(event.target.checked);
  };
  const handleCheckboxChangeFrontRight = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedFrontRight(event.target.checked);
  };
  const handleCheckboxChangeRearLeft = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedRearLeft(event.target.checked);
  };
  const handleCheckboxChangeRearRight = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedRearRight(event.target.checked);
  };

  const handleCaptureTinFrontLeft = async (capturedImage: any) => {
    const imageName = `${Date.now()}-VehicleTinFrontLeft.png`;
    setTinImageName(imageName);
    setImageToProcess(capturedImage);
    setCameraAccessTinFrontLeft(false);
    setTinFrontLeft(true);
  };

  const handleCaptureTinFrontRight = async (capturedImage: any) => {
    const imageName = `${Date.now()}-VehicleTinFrontRight.png`;
    setTinImageName(imageName);
    setImageToProcess(capturedImage);
    setCameraAccessTinFrontRight(false);
    setTinFrontRight(true);
  };
  const handleCaptureTinRearLeft = async (capturedImage: any) => {
    const imageName = `${Date.now()}-VehicleTinRearLeft.png`;
    setTinImageName(imageName);
    setImageToProcess(capturedImage);
    setCameraAccessTinRearLeft(false);
    setTinRearLeft(true);
  };
  const handleCaptureTinRearRight = async (capturedImage: any) => {
    const imageName = `${Date.now()}-VehicleTinRearRight.png`;
    setTinImageName(imageName);
    setImageToProcess(capturedImage);
    setCameraAccessTinRearRight(false);
    setTinRearRight(true);
  };

  const onUploadComplete = (success: any, url: string) => {
    if (success) {
      if (url.includes('FrontLeft')) {
        setTinLocationFrontLeft(url);
        setTinFrontLeft(false);
      } else if (url.includes('FrontRight')) {
        setTinLocationFrontRight(url);
        setTinFrontRight(false);
      } else if (url.includes('RearLeft')) {
        setTinLocationRearLeft(url);
        setTinRearLeft(false);
      } else if (url.includes('RearRight')) {
        setTinLocationRearRight(url);
        setTinRearRight(false);
      } else {
        console.log('URL does not specify a known position');
      }
    } else {
      console.error('');
    }
    setImageToProcess(null);
    setTinImageName('');
  };
  const combinedClickHandlerFrontLeft = () => {
    setCameraAccessTinFrontLeft(true);
    handleTinFrontLeft();
  };
  return (
    <div className={styles.body}>
      <h4>Step 1/6: Enter vehicle inspection data</h4>
      <p>
        Before completing this order, please enter the vehicle inspection data.
        Once the information is filled,
        continue to enter services.
      </p>
      <div className={styles.individualTireWrapper}>
        <label className={styles.title}>Front Right</label>
        <TextInput
          title="Tire Condition"
          placeholder="Front Right"
          type="number"
          className={styles.individualTireInput}
          min={1}
          max={32}
          onChange={(e) => setInspectionData({
            ...inspectionData,
            tireConditionFrontRight: Number(e.currentTarget.value),
          })}
          value={
            inspectionData?.tireConditionFrontRight
              ? inspectionData.tireConditionFrontRight.toString()
              : ''
            }
        />
        {
        tinLocationFrontRight === '' ? (
          <>
            {cameraAccessTinFrontRight
            && <CameraAccess onCapture={handleCaptureTinFrontRight} className={styles.editButtonInline} buttonLabel="Add TIN" />}
            {imageToProcess && tinImageName && tinFrontRight && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={tinImageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <>
            <TextInput
              title="TIN"
              placeholder="Front Right"
              type="number"
              className={styles.individualTireInput}
              onChange={(e) => setInspectionData({
                ...inspectionData,
                tireIdNumberFrontRight: Number(e.currentTarget.value),
              })}
              value={inspectionData?.tireIdNumberFrontRight != null ? inspectionData.tireIdNumberFrontRight.toString() : ''}
              min={0}
            />
          </>
        )
      }
        <TextInput
          title="Brake Condition"
          placeholder="Front Right"
          type="number"
          className={styles.individualTireInput}
          min={1}
          max={13}
          onChange={(e) => setInspectionData({
            ...inspectionData,
            brakeConditionFrontRight: Number(e.currentTarget.value),
          })}
          value={
            inspectionData?.brakeConditionFrontRight
              ? inspectionData.brakeConditionFrontRight.toString()
              : ''
            }
        />
        <div className={styles.individualCeckBox}>
          <p className={styles.textstyle}>estimated?</p>
          <Checkbox
            isChecked={checkedFrontRight}
            onChange={handleCheckboxChangeFrontRight}
          />
        </div>
        { tinLocationFrontRight !== '' ? (
          <>
            <p>
              Picture successfully uploaded
            </p>
          </>
        ) : (
          <p> </p>
        )}
      </div>
      <br />
      <div className={styles.individualTireWrapper}>
        <label className={styles.title}>Rear Right</label>
        <TextInput
          title="Tire Condition"
          placeholder="Rear Right"
          type="number"
          className={styles.individualTireInput}
          min={1}
          max={32}
          onChange={(e) => setInspectionData({
            ...inspectionData,
            tireConditionRearRight: Number(e.currentTarget.value),
          })}
          value={
            inspectionData?.tireConditionRearRight
              ? inspectionData.tireConditionRearRight.toString()
              : ''
            }
        />
        {
        tinLocationRearRight === '' ? (
          <>
            {cameraAccessTinRearRight
            && <CameraAccess onCapture={handleCaptureTinRearRight} className={styles.editButtonInline} buttonLabel="Add TIN" />}
            {imageToProcess && tinImageName && tinRearRight && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={tinImageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <>
            <TextInput
              title="TIN"
              placeholder="Rear Left"
              type="number"
              className={styles.individualTireInput}
              onChange={(e) => setInspectionData({
                ...inspectionData,
                tireIdNumberRearRight: Number(e.currentTarget.value),
              })}
              value={inspectionData?.tireIdNumberRearRight != null ? inspectionData.tireIdNumberRearRight.toString() : ''}
              min={0}
            />
          </>
        )
      }
        <TextInput
          title="Brake Condition"
          placeholder="Rear Right"
          type="number"
          className={styles.individualTireInput}
          min={1}
          max={13}
          onChange={(e) => setInspectionData({
            ...inspectionData,
            brakeConditionRearRight: Number(e.currentTarget.value),
          })}
          value={
            inspectionData?.brakeConditionRearRight
              ? inspectionData.brakeConditionRearRight.toString()
              : ''
            }
        />
        <div className={styles.individualCeckBox}>
          <p className={styles.textstyle}>estimated?</p>
          <Checkbox
            isChecked={checkedRearRight}
            onChange={handleCheckboxChangeRearRight}
          />
        </div>
        { tinLocationRearRight !== '' ? (
          <>
            <p>
              Picture successfully uploaded
            </p>
          </>
        ) : (
          <p> </p>
        )}
      </div>
      <br />
      <div className={styles.individualTireWrapper}>
        <label className={styles.title}>Rear Left</label>
        <TextInput
          title="Tire Condition"
          placeholder="Rear Left"
          type="number"
          className={styles.individualTireInput}
          min={1}
          max={32}
          onChange={(e) => setInspectionData({
            ...inspectionData,
            tireConditionRearLeft: Number(e.currentTarget.value),
          })}
          value={
            inspectionData?.tireConditionRearLeft
              ? inspectionData.tireConditionRearLeft.toString()
              : ''
            }
        />
        {
        tinLocationRearLeft === '' ? (
          <>
            {/* <Button
              onClick={() => setCameraAccessTinRearLeft(true)}
              variant="primary"
              className={styles.editButtonInline}
            >
              Add TIN
            </Button> */}
            {cameraAccessTinRearLeft
            && <CameraAccess onCapture={handleCaptureTinRearLeft} className={styles.editButtonInline} buttonLabel="Add TIN" />}
            {imageToProcess && tinImageName && tinRearLeft && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={tinImageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <>
            <TextInput
              title="TIN"
              placeholder="Rear Left"
              type="number"
              className={styles.individualTireInput}
              onChange={(e) => setInspectionData({
                ...inspectionData,
                tireIdNumberRearLeft: Number(e.currentTarget.value),
              })}
              value={inspectionData?.tireIdNumberRearLeft != null ? inspectionData.tireIdNumberRearLeft.toString() : ''}
              min={0}
            />
          </>
        )
      }
        <TextInput
          title="Brake Condition"
          placeholder="Rear Left"
          type="number"
          className={styles.individualTireInput}
          min={1}
          max={13}
          onChange={(e) => setInspectionData({
            ...inspectionData,
            brakeConditionRearLeft: Number(e.currentTarget.value),
          })}
          value={
            inspectionData?.brakeConditionRearLeft
              ? inspectionData.brakeConditionRearLeft.toString()
              : ''
            }
        />
        <div className={styles.individualCeckBox}>
          <p className={styles.textstyle}>estimated?</p>
          <Checkbox
            isChecked={checkedRearLeft}
            onChange={handleCheckboxChangeRearLeft}
          />
        </div>
        { tinLocationRearLeft !== '' ? (
          <>
            <p>
              Picture successfully uploaded
            </p>
          </>
        ) : (
          <p> </p>
        )}
      </div>
      <br />
      <div className={styles.individualTireWrapper}>
        <label className={styles.title}>Front Left</label>
        <TextInput
          title="Tire Condition"
          placeholder="Front Left"
          type="number"
          className={styles.individualTireInput}
          min={1}
          max={32}
          onChange={(e) => setInspectionData({
            ...inspectionData,
            tireConditionFrontLeft: Number(e.currentTarget.value),
          })}
          value={
            inspectionData?.tireConditionFrontLeft
              ? inspectionData.tireConditionFrontLeft.toString()
              : ''
            }
        />
        {
        tinLocationFrontLeft === '' ? (
          <>
            {cameraAccessTinFrontLeft
            && <CameraAccess ref={cameraAccessRef} onCapture={handleCaptureTinFrontLeft} className={styles.editButtonInline} buttonLabel="Add TIN" />}
            {imageToProcess && tinImageName && tinFrontLeft && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={tinImageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <>
            <TextInput
              title="TIN"
              placeholder="Front Left"
              type="number"
              className={styles.individualTireInput}
              onChange={(e) => setInspectionData({
                ...inspectionData,
                tireIdNumberFrontLeft: Number(e.currentTarget.value),
              })}
              value={inspectionData?.tireIdNumberFrontLeft != null ? inspectionData.tireIdNumberFrontLeft.toString() : ''}
              min={0}
            />
          </>
        )
      }
        <TextInput
          title="Brake Condition"
          placeholder="Front Left"
          type="number"
          className={styles.individualTireInput}
          min={1}
          max={13}
          onChange={(e) => setInspectionData({
            ...inspectionData,
            brakeConditionFrontLeft: Number(e.currentTarget.value),
          })}
          value={
            inspectionData?.brakeConditionFrontLeft
              ? inspectionData.brakeConditionFrontLeft.toString()
              : ''
            }
        />
        <div className={styles.individualCeckBox}>
          <p className={styles.textstyle}>estimated?</p>
          <Checkbox
            isChecked={checkedFrontLeft}
            onChange={handleCheckboxChangeFrontLeft}
          />
        </div>
        { tinLocationFrontLeft !== '' ? (
          <>
            <p>
              Picture successfully uploaded
            </p>
          </>
        ) : (
          <p> </p>
        )}
      </div>
      <ModalButtonsContainer
        onClose={onClose}
        onClick={onClick}
        inactive={isContinueButtonInactive}
        label="Continue"
      />
    </div>
  );
}
