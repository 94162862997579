import axios from 'axios';

export const decodeVin = async (vin: string): Promise<any> => {
  const url = 'https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVINValuesBatch/';
  const postFields = new URLSearchParams({
    format: 'json',
    data: vin,
  });

  try {
    const response = await axios.post(url, postFields.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    if (response.data.Results && response.data.Results.length > 0) {
      return response.data.Results[0];
    }
    throw new Error('No results found for the provided VIN.');
  } catch (error) {
    console.error('Failed to decode VIN:', error);
    throw error;
  }
};
