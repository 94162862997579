import cx from 'classnames';
import { ReactNode, useState } from 'react';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import styles from '../sass/components/Card.module.scss';
import Button from './Button';

interface CardProps {
  collapsible?: boolean;
  openOnRender?: boolean;
  title: string;
  action?: ReactNode;
  children?: ReactNode;
}

export default function Card({
  collapsible = true,
  openOnRender = false,
  title,
  action,
  children,
}: CardProps) {
  const [expanded, setExpanded] = useState(openOnRender);

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {collapsible && (
          <Button
            variant="icon"
            className={styles.expandCollapse}
            onClick={() => setExpanded(!expanded)}
          >
            <Arrow className={cx(
              { [styles.expandedArrow]: expanded },
            )}
            />
          </Button>
          )}
          <h4 className={styles.headerText}>
            {title}
          </h4>
        </div>
        {action}
      </div>
      <div
        className={cx(
          styles.body,
          { [styles.expanded]: expanded },
        )}
      >
        {children}
      </div>
    </div>
  );
}
