import { ReactNode } from 'react';
import styles from '../sass/components/EmptyList.module.scss';

interface Props {
  msg: string
  children?: ReactNode
}

export default function EmptyWorkOrderList({ children, msg }: Props) {
  return (
    <div className={styles.noItems}>
      <p className={styles.noItemsMsg}>
        {msg}
      </p>
      {children}
    </div>
  );
}
