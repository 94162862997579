import Add from '@mui/icons-material/Add';
import CalendarToday from '@mui/icons-material/CalendarToday';
import LocalCarWash from '@mui/icons-material/LocalCarWash';
import LocationOn from '@mui/icons-material/LocationOn';
import cx from 'classnames';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import Tab from '../components/Tab';
import TabContainer from '../components/TabContainer';
import styles from '../sass/components/Booking.module.scss';
import {
  ROUTE_BOOK_ADD_ONS,
  ROUTE_BOOK_LOCATION,
  ROUTE_BOOK_ROOT,
  ROUTE_BOOK_SCHEDULE,
  ROUTE_BOOK_SERVICES,
  ROUTE_ROOT,
} from '../util/constants';

export default function Booking() {
  const location = useLocation().pathname;
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const hasSelectedServices = useAppSelector((state) => (
    state.serviceTypes.services.length
  ));

  const {
    serviceAddress,
  } = useAppSelector((s) => s.serviceLocation);

  return (
    <main className={styles.main}>
      <TabContainer>
        <Tab
          className="container__col-xs-3"
          step
          selected={
            location === ROUTE_BOOK_ROOT
            || location === ROUTE_BOOK_LOCATION
            || location === ROUTE_BOOK_SERVICES
            || location === ROUTE_BOOK_ADD_ONS
            || location === ROUTE_BOOK_SCHEDULE
          }
        >
          <Link className={styles.link} to={ROUTE_BOOK_LOCATION}>
            <LocationOn />
            <div className={cx(
              styles.tabText, {
                [styles.selectedTab]: (location === ROUTE_ROOT
                || location === ROUTE_BOOK_LOCATION),
              },
            )}
            >
              <span
                className={cx(
                  {
                    [styles.selected]: (location === ROUTE_ROOT
                    || location === ROUTE_BOOK_LOCATION),
                  },
                  styles.includeMobile,
                )}
              >
                Step 1
              </span>
              <span className={styles.doNotIncludeMobile}>
                {(currentUser.roles?.includes('manager')
                || currentUser.roles?.includes('technician'))
                  ? ': Customer + Location' : ': Location'}
              </span>
            </div>
          </Link>
        </Tab>
        <Tab
          className="container__col-xs-3"
          step
          disabled={!serviceAddress}
          selected={
            location === ROUTE_BOOK_SERVICES
            || location === ROUTE_BOOK_ADD_ONS
            || location === ROUTE_BOOK_SCHEDULE
          }
        >
          <Link
            className={cx(
              styles.link,
              { [styles.disabledLink]: !serviceAddress },
            )}
            to={ROUTE_BOOK_SERVICES}
          >
            <LocalCarWash />
            <div className={cx(
              styles.tabText, {
                [styles.selectedTab]: location === ROUTE_BOOK_SERVICES,
              },
            )}
            >
              {' '}
              <span
                className={cx(
                  {
                    [styles.selected]: (location === ROUTE_BOOK_SERVICES),
                  },
                  styles.includeMobile,
                )}
              >
                Step 2
              </span>
              <span className={styles.doNotIncludeMobile}>
                : Select Services
              </span>
            </div>
          </Link>
        </Tab>
        <Tab
          className="container__col-xs-3"
          step
          disabled={!hasSelectedServices}
          selected={
            location === ROUTE_BOOK_ADD_ONS
            || location === ROUTE_BOOK_SCHEDULE
          }
        >
          <Link
            className={cx(
              styles.link,
              { [styles.disabledLink]: !hasSelectedServices },
            )}
            to={ROUTE_BOOK_ADD_ONS}
          >
            <Add />
            <div className={cx(
              styles.tabText, {
                [styles.selectedTab]: location === ROUTE_BOOK_ADD_ONS,
              },
            )}
            >
              <span
                className={cx(
                  {
                    [styles.selected]: (location === ROUTE_BOOK_ADD_ONS),
                  },
                  styles.includeMobile,
                )}
              >
                Step 3
              </span>
              <span className={styles.doNotIncludeMobile}>
                : Select Add Ons
              </span>
            </div>
          </Link>
        </Tab>
        <Tab
          className="container__col-xs-3"
          step
          disabled={!hasSelectedServices}
          selected={location === ROUTE_BOOK_SCHEDULE}
        >
          <Link
            className={cx(
              styles.link,
              { [styles.disabledLink]: !hasSelectedServices },
            )}
            to={ROUTE_BOOK_SCHEDULE}
          >
            <CalendarToday />
            <div className={cx(
              styles.tabText, {
                [styles.selectedTab]: location === ROUTE_BOOK_SCHEDULE,
              },
            )}
            >
              <span
                className={cx(
                  { [styles.selected]: location === ROUTE_BOOK_SCHEDULE },
                  styles.includeMobile,
                )}
              >
                Step 4
              </span>
              <span className={styles.doNotIncludeMobile}>
                : Schedule And Book
              </span>
            </div>
          </Link>
        </Tab>
      </TabContainer>
      <Outlet />
    </main>
  );
}
