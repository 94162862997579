import SettingsIcon from '@mui/icons-material/Settings';
import { Outlet } from 'react-router-dom';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import useAuthentication from '../hooks/useAuthentication';
import styles from '../sass/components/Account.module.scss';
import AccountSettings from './AccountSettings';

export default function Account() {
  const { handleLogin, loggedIn } = useAuthentication();

  return (
    <div className={styles.accountPage}>
      <div className={styles.header}>
        <div className={styles.leftHeader}>
          <h1 className={styles.headerDesktop}>Account Settings</h1>
          <h4 className={styles.headerMobile}>Account Settings</h4>
        </div>
        <div className={styles.rightHeader}>
          <Dropdown
            tetherComponent={<SettingsIcon />}
            id="settings-dropdown"
            className={styles.mobileHeaderActions}
          >
            <div className={styles.dropdownItem}>
              <button
                onClick={handleLogin}
                type="button"
                className={styles.dropdownButton}
              >
                <p>Logout</p>
              </button>
            </div>
          </Dropdown>
          <div className={styles.desktopHeaderActions}>
            <Button
              variant="secondary"
              onClick={handleLogin}
              className={styles.editButton}
            >
              {loggedIn ? 'Log Out' : 'Log In'}
            </Button>
          </div>
        </div>
      </div>
      <AccountSettings>
        <Outlet />
      </AccountSettings>
    </div>
  );
}
