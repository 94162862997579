import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import cx from 'classnames';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAuthentication from '../hooks/useAuthentication';
import goOilLogo from '../images/gooil-logo.png';
import styles from '../sass/components/NavBar.module.scss';
import {
  ROUTE_ACCOUNT_ROOT,
  ROUTE_AUTH_LOGIN,
  ROUTE_BOOK_LOCATION,
  ROUTE_BOOK_ROOT,
  ROUTE_ROOT,
} from '../util/constants';

export default function NavBar() {
  const currentRoutePath = useLocation().pathname;
  const [navOpen, setNavOpen] = useState(false);
  const { loggedIn: isLoggedIn, handleLogin } = useAuthentication();

  return (
    <nav className={cx(styles.navBar, {
      [styles.navBarClosed]: !navOpen,
    }, {
      [styles.navBarOpen]: navOpen,
    })}
    >
      <Link
        className={styles.logo}
        to={ROUTE_ROOT}
        onClick={() => setNavOpen(false)}
      >
        <img src={goOilLogo} alt="Go oil logo" className={styles.logoSvg} />
      </Link>
      <div className={cx(styles.navLinks, {
        [styles.navLinksClosed]: !navOpen,
      }, {
        [styles.navLinksOpen]: navOpen,
      })}
      >
        <Link
          className={cx(styles.link, {
            [styles.linkClosed]: !navOpen,
          }, {
            [styles.linkOpen]: navOpen,
          }, {
            [styles.currentPage]: currentRoutePath.includes(ROUTE_BOOK_ROOT),
          })}
          to={ROUTE_BOOK_LOCATION}
          onClick={() => setNavOpen(false)}
        >
          BOOK APPOINTMENT
        </Link>
        <Link
          className={cx(styles.link, {
            [styles.linkClosed]: !navOpen,
          }, {
            [styles.linkOpen]: navOpen,
          }, {
            [styles.currentPage]: currentRoutePath.includes(ROUTE_ACCOUNT_ROOT),
          })}
          to={isLoggedIn ? ROUTE_ACCOUNT_ROOT : ROUTE_AUTH_LOGIN}
          onClick={() => setNavOpen(false)}
        >
          ACCOUNT
        </Link>
        {isLoggedIn
        && navOpen && (
        <Link
          className={cx(styles.link, {
            [styles.linkOpen]: navOpen,
          })}
          to={ROUTE_AUTH_LOGIN}
          onClick={handleLogin}
        >
          LOG OUT
        </Link>
        )}
        <button
          type="button"
          className={cx(styles.button, {
            [styles.menuButton]: !navOpen,
          }, {
            [styles.closeButton]: navOpen,
          })}
          onClick={() => setNavOpen(!navOpen)}
        >
          {navOpen
            ? <Close />
            : <Menu />}
        </button>
      </div>
    </nav>
  );
}
