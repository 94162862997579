import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { setPayoutReportId } from '../features/payoutReport/payoutReportSlice';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import styles from '../sass/components/PayoutReportRow.module.scss';
import Button from './Button';
import { dateString } from '../util/formatDate';
import { PayoutReport } from '../interfaces/PayoutReport';
import { ROUTE_FRANCHISE_MANAGER_PAYOUTS } from '../util/constants';

interface PayoutReportRowProps {
  payoutReport: PayoutReport
}

export default function PayoutReportRow({
  payoutReport: {
    id,
    startDate,
    endDate,
  },
}: PayoutReportRowProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const payoutName = () => {
    const adjStartDate = dateString(startDate);
    const adjEndDate = dateString(endDate);
    return (`Statement from ${adjStartDate} - ${adjEndDate}`);
  };

  const setPayoutReportDetails = () => {
    dispatch(setPayoutReportId(id));
    navigate(`${ROUTE_FRANCHISE_MANAGER_PAYOUTS}/${id}`);
  };

  return (
    <div className={styles.rowContainer}>
      <div className={styles.descriptionContainer}>
        <div className={styles.mainContent}>
          <h6><strong>{payoutName()}</strong></h6>
        </div>
        <Button
          className={cx(styles.caret)}
          type="button"
          onClick={setPayoutReportDetails}
          variant="icon"
        >
          <Arrow />
        </Button>
      </div>
    </div>
  );
}
