import { useAppSelector } from '../app/hooks';
import Card from './Card';
import Invoice from './Invoice';

interface OrderSummaryProps {
  onlyServices?: boolean;
}

export default function OrderSummary({
  onlyServices = false,
}: OrderSummaryProps) {
  const make = useAppSelector((state) => (
    state.car.make
  ));
  const model = useAppSelector((state) => (
    state.car.model
  ));
  const year = useAppSelector((state) => (
    state.car.year
  ));

  return (
    <Card
      title={onlyServices
        ? 'Selected Services'
        : 'Order Summary'
      + `${(!!make || !!model || !!year)
        ? ` for your \n ${make} ${model} ${year ? `(${year})` : ''}`
        : ' for your Vehicle'}`}
    >
      <Invoice onlyServices={onlyServices} />
    </Card>
  );
}
