/* eslint-disable camelcase */
import cx from 'classnames';
import { FormEvent, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Address } from '../features/serviceLocation/serviceLocationSlice';
import styles from '../sass/components/SearchLocationInput.module.scss';
import TextInput from './TextInput';

export type PlaceResult = google.maps.places.PlaceResult;

export function placeResultToAddress(
  newAddr: PlaceResult | undefined,
): Address | undefined {
  if (!newAddr) {
    return undefined;
  }

  const streetNumber = newAddr?.address_components?.find(
    (component) => component.types.includes('street_number'),
  )?.long_name;
  const street = newAddr?.address_components?.find(
    (component) => component.types.includes('route'),
  )?.long_name;
  const city = newAddr?.address_components?.find(
    (component) => component.types.includes('locality'),
  )?.long_name || '';
  const postalCode = newAddr?.address_components?.find(
    (component) => component.types.includes('postal_code'),
  )?.long_name || '';
  const country = newAddr?.address_components?.find(
    (component) => component.types.includes('country'),
  )?.long_name || '';
  const province = newAddr?.address_components?.find(
    (component) => component.types.includes('administrative_area_level_1'),
  )?.long_name || '';
  const countryCode = newAddr?.address_components?.find(
    (component) => component.types.includes('country'),
  )?.short_name || '';
  const provinceCode = newAddr?.address_components?.find(
    (component) => component.types.includes('administrative_area_level_1'),
  )?.short_name || '';
  const lat = newAddr?.geometry?.location?.lat();
  const lng = newAddr?.geometry?.location?.lng();

  return {
    street: `${streetNumber} ${street}`,
    city,
    postalCode,
    province,
    country,
    countryCode,
    provinceCode,
    lat,
    lng,
  };
}

interface SearchLocationProps {
  onChange: (value: PlaceResult | undefined) => void;
  value?: PlaceResult | undefined;
  title: string;
  id: string;
  errorMessage?: string;
  className?: string;
  success?: boolean;
  disabled?: boolean;
}

function Input({
  onChange,
  value,
  title,
  id,
  errorMessage,
  className,
  success,
  disabled,
}: SearchLocationProps) {
  const [inputValue, setInputValue] = useState(value?.formatted_address || '');

  const handleSelectPlace = (selectedPlace: PlaceResult | undefined) => {
    if (!selectedPlace) {
      onChange(undefined);
    } else if (selectedPlace.address_components) {
      onChange(selectedPlace);
      setInputValue(selectedPlace.formatted_address || '');
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: handleSelectPlace,
    options: {
      types: ['address'],
      fields: ['address_components', 'formatted_address', 'geometry.location'],
      componentRestrictions: { country: ['us', 'ca'] },
    },
  });

  const textInputChange = (event: FormEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  };

  const setToSelectedPlace = () => {
    setInputValue(value?.formatted_address || '');
  };

  return (
    <TextInput
      onChange={textInputChange}
      onBlur={setToSelectedPlace}
      value={inputValue}
      title={title}
      id={id}
      ref={ref}
      errorMessage={errorMessage}
      className={className}
      success={success}
      disabled={disabled}
    />
  );
}

export default function SearchLocationInput({
  onChange,
  value,
  title,
  id,
  errorMessage,
  className,
  success,
  disabled,
}: SearchLocationProps) {
  return (
    <Input
      onChange={onChange}
      value={value}
      title={title}
      id={id}
      errorMessage={errorMessage}
      success={success}
      className={cx(styles.locationInput, className)}
      disabled={disabled}
    />
  );
}
