import { useEffect, useState } from 'react';
import styles from '../sass/components/DiscountContainer.module.scss';
import Button from './Button';
import TextInput from './TextInput';

interface InvoiceDiscountProps {
  discountApplied: boolean
  totalOriginal: number
  setDiscountNum: (discountNum: number) => void
  discountType: string,
  discountNum: number,
  setDiscountType: (discountType: string) => void
  calculateDiscount: () => void
  removeDiscount: () => void
  settingDefaultDiscount: boolean
}

export default function DiscountContainer({
  discountApplied,
  totalOriginal,
  setDiscountNum,
  discountType,
  discountNum,
  setDiscountType,
  calculateDiscount,
  removeDiscount,
  settingDefaultDiscount,
}:InvoiceDiscountProps) {
  const [readyToDiscount, setReadyToDiscount] = useState<boolean>(false);
  const [autofillDiscount, setAutofillDiscount] = useState<string>('');

  useEffect(() => {
    const isDiscountValid = () => {
      if (settingDefaultDiscount) {
        const isValidDiscountNum = discountNum > 0;
        const isValidDiscountType = discountType !== '';
        const isValidPercentDiscount = discountType === 'percent'
          ? discountNum / 100 <= 100 : true;

        return isValidDiscountNum
          && isValidDiscountType && isValidPercentDiscount;
      }
      const isValidDiscountNum = discountNum > 0;
      const isValidDiscountType = discountType !== '';
      const isValidDollarDiscount = discountType === 'dollar'
          && discountNum / 100 <= totalOriginal;
      const isValidPercentDiscount = discountType === 'percent'
          && discountNum / 100 <= 100;

      return isValidDiscountNum
          && isValidDiscountType
          && (isValidDollarDiscount || isValidPercentDiscount);
    };

    setReadyToDiscount(isDiscountValid());
  }, [discountNum, discountType, settingDefaultDiscount, totalOriginal]);

  useEffect(() => {
    if (discountNum) {
      const num = discountNum / 100;
      setAutofillDiscount(num.toString());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!discountApplied && (
      <div className={settingDefaultDiscount
        ? styles.default_discount_input : styles.invoice_discount_input}
      >
        {!settingDefaultDiscount && (
        <p className={styles.discount_title}>
          Discount
        </p>
        )}
        <div className={styles.discount_setting}>
          <TextInput
            className={styles.discount_input_box}
            onChange={(e) => setDiscountNum(
              Math.round(parseFloat(e.currentTarget.value) * 100),
            )}
            placeholder={autofillDiscount}
            min={0}
          />
          <Button
            className={`${styles.discount_type}
            ${discountType === 'dollar' && styles.active}`}
            onClick={() => setDiscountType('dollar')}
            variant="secondary"
          >
            $
          </Button>
          <Button
            className={`${styles.discount_type}
            ${discountType === 'percent' && styles.active}`}
            onClick={() => setDiscountType('percent')}
            variant="secondary"
          >
            %
          </Button>
        </div>
        <Button
          className={styles.apply_remove_discount}
          onClick={calculateDiscount}
          variant="secondary"
          inactive={!readyToDiscount}
        >
          Apply Discount
        </Button>
      </div>
      )}
      { discountApplied && (
      <div className={settingDefaultDiscount
        ? styles.default_discount_input : styles.invoice_discount_input}
      >
        {!settingDefaultDiscount && (
        <p className={styles.discount_title}>
          Discount
        </p>
        )}
        <p className={styles.discount_applied}>
          -
          {' '}
          {discountType === 'dollar' ? '$' : ''}
          {discountNum / 100}
          {discountType === 'percent' ? '%' : ''}
          {' '}
          applied
        </p>
        <Button
          className={styles.apply_remove_discount}
          onClick={() => removeDiscount()}
          variant="secondary"
        >
          Remove Discount
        </Button>
      </div>
      )}
    </>
  );
}
