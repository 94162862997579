import { ChangeEvent } from 'react';
import styles from '../sass/components/CheckBox.module.scss';

interface CheckProps {
    isChecked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function Checkbox({ isChecked, onChange }: CheckProps) {
  return (
    <div className={styles.box}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
    </div>
  );
}
