import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { ROUTE_ACCOUNT_ROOT } from '../util/constants';

interface RequireFranchiseManagerProps {
  children: JSX.Element;
}

export default function RequiredFranchiseManager({
  children,
}: RequireFranchiseManagerProps) {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const location = useLocation();

  if (!currentUser.roles?.includes('manager')
    && !currentUser.roles?.includes('technician')) {
    return <Navigate to={ROUTE_ACCOUNT_ROOT} state={{ from: location }} />;
  }

  return children;
}
