import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import styles from '../sass/components/TableRowEdit.module.scss';
import { computeAddressFromGooglePlacesResult } from '../util/addressHelper';
import { getFieldErrors } from '../util/getFieldErrors';
import {
  CREATE_ADDRESS,
  GET_ADDRESSES_BY_USER_ID,
  GET_POSTAL,
  UPDATE_ADDRESS,
} from '../util/gql';
import { extractGraphqlErrors } from '../util/validationErrorHelper';
import Alert from './Alert';
import Button from './Button';
import SearchLocationInput from './SearchLocationInput';

interface AddressRowProps {
  data?: {
    id: number;
    street?: string;
    city?: string;
    postalCode?: string;
  };
  index?: number;
  setIsEditing: () => void;
}

export default function AddressRowView({
  data,
  index,
  setIsEditing,

}: AddressRowProps) {
  const [validPostal, setValidPostal] = useState('');
  const [constraints, setConstraints] = useState([]);
  const customer = useAppSelector((state) => state.customer);
  const addressString = `${data?.street} ${data?.city}`
   + `${data?.postalCode ? `, ${data?.postalCode}` : ''}`;

  const existingLocation: google.maps.places.PlaceResult = {
    formatted_address: addressString,
  };

  const [
    currentValue,
    setCurrentValue,
  ] = useState<google.maps.places.PlaceResult | undefined>(
    data?.id ? existingLocation : undefined,
  );

  const [validateLocation, {
    error: validateLocationError,
  }] = useLazyQuery(GET_POSTAL, {
    onCompleted: (d) => {
      const { isInService } = d.getPostal;
      if (isInService) {
        setValidPostal('');
      }
      if (!isInService) {
        setValidPostal('This address is not in service');
      }
    },
  });

  const [createAddress, {
    loading: createAddressLoading,
    error: createAddressError,
  }] = useMutation(CREATE_ADDRESS);

  const [updateAddress, {
    loading: updateAddressLoading,
    error: updateAddressError,
  },
  ] = useMutation(UPDATE_ADDRESS);

  const locationError = validateLocationError
    ?.graphQLErrors[0]
    .extensions
    ?.response
    ?.message;

  useEffect(() => {
    if (locationError) {
      setConstraints(locationError);
    } else {
      setConstraints([]);
    }
  }, [locationError]);

  const handleSetPostal = async (value?: google.maps.places.PlaceResult) => {
    const addressExists = value && value.address_components;
    if (addressExists) {
      const addressContainsPostal = value?.address_components?.find((
        component: google.maps.GeocoderAddressComponent,
      ) => (
        component.types.includes('postal_code')
      ));
      if (addressContainsPostal) {
        const selectedPostal = addressContainsPostal?.long_name;
        validateLocation({
          variables: {
            code: selectedPostal,
          },
        });
      }
    }
  };

  const handleLocationChange = (value?: google.maps.places.PlaceResult) => {
    setCurrentValue(value);
    handleSetPostal(value);
  };

  const refetchQueries = [
    {
      query: GET_ADDRESSES_BY_USER_ID,
      variables: { userId: customer.id },
    },
  ];

  const handleSave = async () => {
    const computedAddress = computeAddressFromGooglePlacesResult(currentValue);
    if (data?.id) {
      await updateAddress({
        variables: {
          ...computedAddress,
          id: data?.id || 0,
        },
        refetchQueries,
      });
    } else {
      await createAddress({
        variables: {
          ...computedAddress,
        },
        refetchQueries,
      });
    }
    setIsEditing();
  };

  const handleCancel = () => {
    setCurrentValue(existingLocation);
    setConstraints([]);
    setIsEditing();
    setValidPostal('');
  };

  const editTitle = data?.id
    ? `Address ${(index || 0) + 1}`
    : 'New address';

  const hasChanged = !!currentValue?.address_components;

  return (
    <div
      className={styles.row}
    >
      <div className={styles.rowContent}>
        <SearchLocationInput
          className={styles.input}
          onChange={handleLocationChange}
          id={`${data?.id}` || 'editing'}
          title={editTitle}
          value={currentValue}
          errorMessage={
                getFieldErrors(constraints, ['code'])
                || validPostal
              }
        />
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            variant="tertiary"
            onClick={handleCancel}
            key={`cancel-${data?.id}`}
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            variant="primary"
            onClick={handleSave}
            key={`save-${data?.id}`}
            inactive={
                getFieldErrors(constraints, ['code'])
                || validPostal
                || !hasChanged
              }
            loading={
                updateAddressLoading
                || createAddressLoading
              }
          >
            Save
          </Button>
        </div>
        <Alert
          message={
            extractGraphqlErrors(createAddressError)
            || extractGraphqlErrors(updateAddressError)
            }
        />
      </div>
    </div>
  );
}
