import { useMutation } from '@apollo/client';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { useAppSelector } from '../app/hooks';
import styles from '../sass/components/TableRowView.module.scss';
import { DELETE_ADDRESS, GET_ADDRESSES } from '../util/gql';
import { extractGraphqlErrors } from '../util/validationErrorHelper';
import Alert from './Alert';
import Button from './Button';

interface AddressRowProps {
  data?: {
    id: number;
    street?: string;
    city?: string;
    postalCode?: string;
  };
  index?: number;
  setIsEditing: () => void
}

export default function AddressRowView({
  data,
  index,
  setIsEditing,
}: AddressRowProps) {
  const addressString = `${data?.street} ${data?.city}`;
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const isManager = currentUser.roles?.includes('manager')
  || currentUser.roles?.includes('technician');
  const isTechnician = currentUser.roles?.includes('technician');

  const [deleteAddress, {
    error: deleteAddressError,
  }] = useMutation(DELETE_ADDRESS, {
    onCompleted: (addressData) => {
      if (addressData.deleteAddress.id === 0) {
        // eslint-disable-next-line max-len, no-alert
        alert('Could not delete address because an assigned work order still exists.');
      }
    },
  });

  const handleDelete = () => {
    deleteAddress({
      variables: {
        id: data?.id || 0,
      },
      refetchQueries: [
        GET_ADDRESSES,
        'getAddresses',
      ],
    });
  };

  return (
    <div
      className={styles.row}
    >
      <div className={styles.left}>
        <h5 className={styles.rowContent}>
          {addressString}
        </h5>
      </div>
      <div
        className={styles.right}
      >
        <div className={styles.actions}>
          <Button
            variant="icon"
            onClick={() => setIsEditing()}
            key={`set-edit-${data?.id}`}
          >
            <Edit />
          </Button>
          <Button
            key={`delete-${data?.id}`}
            variant="icon"
            onClick={handleDelete}
            inactive={index === 0}
          >
            <Delete />
          </Button>
        </div>
        <Alert
          message={extractGraphqlErrors(deleteAddressError)}
        />
      </div>
    </div>
  );
}
