import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  condenseTaxInformation,
  TaxInformation,
  taxMapper,
} from '../../util/taxCalculatorHelpers';

export interface ServiceType {
  id: number;
  name: string;
  value: number;
  isEstimate: boolean,
  description: string;
  sortOrder: number | null;
  serviceTypeId: number,
  taxName: string;
  taxPercent: number;
  taxId: number;
  discountedValue?: number
}
export interface ServiceTypesState {
  services: ServiceType[];
}

const initialState: ServiceTypesState = {
  services: [],
};

export const serviceTypesSlice = createSlice({
  name: 'serviceTypes',
  initialState,
  reducers: {
    addService: (state, action: PayloadAction<ServiceType>) => {
      state.services.push(action.payload);
    },
    removeService: (state, action: PayloadAction<number>) => (
      {
        ...state,
        services: state
          .services
          .filter((service) => service.id !== action.payload),
      }
    ),
    clearAllSelectedServices: (state) => (
      {
        ...state,
        services: [],
      }
    ),
  },
});

export const selectServicesTotal = (
  state: RootState,
) => (state.serviceTypes.services.length
  ? state.serviceTypes.services
    .map((service) => (
      service.discountedValue && Number.isFinite(service.discountedValue)
        ? service.discountedValue
        : service.value
    ))
    .reduce((prev, current) => prev + current)
  : 0);

export const selectServiceTaxTypesAndAmounts = (
  state: RootState,
) => {
  const mappedTaxes: TaxInformation[] = state.serviceTypes.services.length
    ? state.serviceTypes.services.map((item) => taxMapper(item))
    : [];

  return condenseTaxInformation(mappedTaxes);
};

export const {
  addService,
  removeService,
  clearAllSelectedServices,
} = serviceTypesSlice.actions;

export const selectServiceTypes = (
  state: RootState,
) => state.serviceTypes.services;

export default serviceTypesSlice.reducer;
