import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { ROUTE_ROOT } from '../util/constants';

interface RequireLocationProps {
  children: JSX.Element;
}

export default function RequireLocation({ children }: RequireLocationProps) {
  const serviceLocation = useAppSelector((state) => (
    state.serviceLocation));

  const location = useLocation();

  if (!serviceLocation.serviceAddress) {
    return <Navigate to={ROUTE_ROOT} state={{ from: location }} />;
  }

  return children;
}
