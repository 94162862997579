import cx from 'classnames';
import { ReactNode } from 'react';
import styles from '../sass/components/Badge.module.scss';

interface BadgeProps {
  children?: ReactNode;
  variant?: 'primary' | 'secondary';
  className?: string;
}

function Badge({ children, variant = 'primary', className }: BadgeProps) {
  return (
    <div
      className={cx(
        styles.badge,
        styles[variant],
        'smallLabel',
        className,
      )}
    >
      {children}
    </div>
  );
}

export default Badge;
