import { format } from 'date-fns';

export const weekdayDateString = (
  date: Date,
): string => new Intl.DateTimeFormat('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}).format(new Date(date));

export const dateString = (
  date: Date,
): string => new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}).format(new Date(date));

export const dateString2 = (date: Date | string): string => {
  const validDate = new Date(date);

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'America/Chicago',
  }).format(validDate);
};

export const dayTimeString = (
  date: Date,
): string => format(new Date(date), 'yyyy-MM-dd hh:mm aaa');
