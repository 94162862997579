import {
  ApolloError,
  useMutation,
  useQuery,
} from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import { ChangeEvent, FormEvent, useState } from 'react';
import styles from '../sass/components/UserInformation.module.scss';
import { getFieldErrors } from '../util/getFieldErrors';
import {
  GET_CURRENT_USER,
  UPDATE_USER_INFO,
  UPDATE_EMAIL_PREFERENCE,

} from '../util/gql';
import Alert from './Alert';
import Button from './Button';
import Loader from './Loader';
import { PasswordInput } from './PasswordInput';
import TextInput from './TextInput';
import Checkbox from './Checkbox';

export default function UserInformation() {
  const [editing, setEditing] = useState(false);

  const [checkedEmail, setCheckedEmail] = useState<boolean>(true);
  const [name, setName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);

  const [updateEmailPreference] = useMutation(UPDATE_EMAIL_PREFERENCE);

  // eslint-disable-next-line max-len
  const handleCheckboxChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedEmail(event.target.checked);
    updateEmailPreference({
      variables: {
        checkedEmail: event.target.checked,
      },
    });
  };

  const {
    data: userData,
    loading: userLoading,
  } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setName(data.getCurrentUser.user.name);
      setEmail(data.getCurrentUser.user.email);
      setCheckedEmail(data.getCurrentUser.user.notificationRequests);
    },
  });

  const [updateUserInfo, {
    loading: saveLoading,
  }] = useMutation(UPDATE_USER_INFO);

  const viewForm = (
    userData
    && (
      <table className={styles.table}>
        <tbody>
          <tr className={styles.row}>
            <th>Account Holder</th>
            <td>{name}</td>
          </tr>
          <tr className={styles.row}>
            <th>Email</th>
            <td>{email}</td>
          </tr>
          <tr className={styles.row}>
            <th>Password</th>
            <td>•••••••</td>
          </tr>
        </tbody>
      </table>
    )
  );

  const [newName, setNewName] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  const [constraints, setConstraints] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const onSave = async () => {
    try {
      const {
        data,
      } = await updateUserInfo({
        variables: {
          name: newName || undefined,
          email: newEmail || undefined,
          password: newPassword || undefined,
        },
      });

      setName(data.updateUserInfo.name);
      setEmail(data.updateUserInfo.email);
      setConstraints([]);
      setErrorMessage('');
      setEditing(false);
      setNewName('');
      setNewEmail('');
      setNewPassword('');
    } catch (ex) {
      if (ex instanceof ApolloError) {
        const {
          message,
          statusCode,
        } = ex.graphQLErrors[0].extensions.response;
        switch (statusCode) {
          case 422:
            setConstraints(message);
            break;

          case 400:
            setErrorMessage(message);
            break;

          default:
            throw ex;
        }
      }
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    return onSave();
  };

  const editForm = (
    <div>
      <p>We will never share your contact information with anyone else.</p>
      <form className={styles.form} onSubmit={onSubmit}>
        <TextInput
          className={styles.formInput}
          title="Account Holder"
          placeholder={name}
          onChange={(e) => setNewName(e.currentTarget.value)}
          errorMessage={getFieldErrors(constraints, ['name'])}
        />
        <TextInput
          className={styles.formInput}
          title="Email"
          placeholder={email}
          onChange={(e) => setNewEmail(e.currentTarget.value)}
          errorMessage={getFieldErrors(constraints, ['email'])}
        />
        <PasswordInput
          className={styles.formInput}
          title="Password"
          placeholder="•••••••"
          onChange={(e) => setNewPassword(e.currentTarget.value)}
          errorMessage={getFieldErrors(constraints, ['password'])}
        />
        <Alert message={errorMessage} type="error" />
        <div className={styles.footer}>
          <Button
            variant="tertiary"
            onClick={() => setEditing(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={saveLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );

  const eitherForm = editing ? editForm : viewForm;

  return (
    <>
      <div className={styles.userInfo}>
        <div className={styles.header}>
          <h4>Account Information</h4>
          {!editing
          && (
            <Button onClick={() => setEditing(true)} variant="secondary">
              <span>Edit</span>
              <EditIcon />
            </Button>
          )}
        </div>
        <div className={styles.wrapper}>
          {userLoading ? <Loader wrapperClass={styles.loader} /> : eitherForm}
        </div>
      </div>
      <div className={styles.customerInformation}>
        <div className={styles.contacts}>
          <h4 className={styles.header}> Booking Notification Email </h4>
          <p>
            {' '}
            If you do not wish to receive a notification email upon
            booking an appointment, please unselect the box below.
            Note that you will still receive invoices via email.
          </p>
        </div>
        <div className={styles.notificationBox}>
          <span>Do you wish to receive booking notifications?</span>
          <div className={styles.checkBox}>
            <Checkbox
              isChecked={checkedEmail}
              onChange={handleCheckboxChangeEmail}
            />
          </div>
        </div>
      </div>
    </>
  );
}
