import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { InvoiceProps } from '../interfaces/Invoice';
import styles from '../sass/components/PayInvoiceModal.module.scss';
import {
  VOID_INVOICE,
} from '../util/gql';
import {
  ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
} from '../util/constants';
import Button from './Button';
import Modal from './Modal';
import ModalCloseButton from './ModalCloseButton';

interface VoidInvoiceModalProps {
  invoiceDetails?: InvoiceProps;
  voidInvoiceModal: boolean;
  onClose: () => void;
  setErrorModal: (status: boolean) => void;
}

export default function VoidInvoiceModal({
  invoiceDetails,
  voidInvoiceModal,
  onClose,
  setErrorModal,
}: VoidInvoiceModalProps) {
  const navigate = useNavigate();

  const invoiceId = invoiceDetails?.id;

  const [voidInvoice] = useMutation(VOID_INVOICE, {
    variables: { id: invoiceId },
    onCompleted: () => {
      onClose();
    },
    onError: () => {
      onClose();
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const handleSubmit = async (): Promise<void> => {
    const response = await voidInvoice();

    if (response?.data?.voidInvoiceFront?.id) {
      // eslint-disable-next-line max-len
      navigate(`${ROUTE_FRANCHISE_MANAGER_WORK_ORDERS}/${invoiceDetails?.workOrderId}`);
    }
  };

  return (
    <Modal open={voidInvoiceModal} onClose={onClose} disableBackdropClick>
      <div className={styles.modal}>
        <ModalCloseButton onClose={onClose} />
        <div className={styles.body}>
          <h4>Void Invoice</h4>
          <p>
            Voiding this invoice will generate a voided invoice and send it to
            the customer. Do you wish to continue?
          </p>
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.button}
              onClick={onClose}
              variant="tertiary"
            >
              No
            </Button>
            <Button
              className={styles.button}
              onClick={handleSubmit}
              variant="primary"
            >
              Void
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
