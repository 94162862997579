import {
  SubscriptionStatus,
  VehicleSubscription,
} from '../interfaces/WorkOrder';

const nonBillableSubscriptions = [
  SubscriptionStatus.active,
  SubscriptionStatus.pending,
];

export const getCurrentVehicleSubscription = (
  vehicleSubscriptions: VehicleSubscription[] = [],
): VehicleSubscription | undefined => {
  const validSubscriptions = vehicleSubscriptions.filter(
    (vehicleSubscription) => nonBillableSubscriptions
      .includes(vehicleSubscription.status),
  );

  return (validSubscriptions.length >= 0) ? validSubscriptions[0] : undefined;
};
