import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { GET_PRESIGNED_URL } from '../util/gql';

interface AwsUploadProps {
    onUploadComplete: (success: any, url: string) => void;
    filename: string
    capturedImage: File;
}

export default function AwsUpload({
  capturedImage,
  filename,
  onUploadComplete,
}: AwsUploadProps) {
  const [signInUrl, setSignInUrl] = useState<string>();

  const [getUrl] = useMutation(GET_PRESIGNED_URL, {
    onCompleted: (data: any) => {
      setSignInUrl(data.getPresignedUrl.presignedUrl);
    },
    onError() {
      console.log('error');
    },
    fetchPolicy: 'no-cache',
  });

  const adjustImageSize = (fileName: File):
  Promise<File> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        // Calculate the new dimensions while maintaining aspect ratio
        let { width } = img;
        let { height } = img;

        if (width > 720 || height > 720) {
          if (width > height) {
            height = (height * 720) / width;
            width = 720;
          } else {
            width = (width * 720) / height;
            height = 720;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Unable to get canvas context');
          return;
        }

        // Draw the resized image onto the canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas to a Blob and then to a File
        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], fileName.name, {
              type: blob.type,
            });
            resolve(resizedFile);
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Unable to create blob from canvas');
          }
        },
        'image/jpeg',
        0.5);
      };

      img.onerror = (error) => reject(error);
      img.src = e.target?.result as string;
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(fileName);
  });

  async function uploadPictureToS3(file: any, presignedUrl: string) {
    try {
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'image/png',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to upload file: ${response.statusText}`);
      }

      console.log('File uploaded successfully!');
      const urlOfUploadedFile = new URL(presignedUrl);
      urlOfUploadedFile.search = '';

      return urlOfUploadedFile.href;
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      throw error;
    }
  }

  useEffect(() => {
    const processAndUploadImage = async () => {
      try {
        const adjustedImage = await adjustImageSize(capturedImage);
        const { data } = await getUrl({ variables: { fileName: filename } });
        const singleSignInUrl = data.getPresignedUrl.presignedUrl;
        if (singleSignInUrl) {
          // eslint-disable-next-line max-len
          const fileLocation = await uploadPictureToS3(adjustedImage, singleSignInUrl);
          onUploadComplete(true, fileLocation);
        } else {
          throw new Error('Failed to receive a valid URL for upload.');
        }
      } catch (error) {
        console.error('Error during image processing and upload:', error);
        onUploadComplete(false, '');
      }
    };

    if (capturedImage && filename) {
      processAndUploadImage();
    }
    return () => {
      onUploadComplete(false, '');
    };
  }, [capturedImage, filename, onUploadComplete]);

  return (
    <>
    </>
  );
}
