export const getConstraintsForProperty = (
  errors: any[],
  properties: string[],
): any => {
  if (properties.length > 1) {
    return getConstraintsForProperty(
      errors?.find((e) => e.property === properties[0])?.children,
      properties.slice(1),
    );
  }
  return errors?.find((e) => e.property === properties[0])?.constraints;
};
