import { useMutation } from '@apollo/client';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { useAppSelector } from '../app/hooks';
import styles from '../sass/components/TableRowView.module.scss';
import { DELETE_CONTACT, GET_CONTACTS } from '../util/gql';
import { extractGraphqlErrors } from '../util/validationErrorHelper';
import Alert from './Alert';
import Button from './Button';

export default function ContactRowView(props: { data: {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
}, index: number, setIsEditing: () => void }) {
  const { data, index, setIsEditing } = props;
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);

  const [deleteContact, {
    error: deleteContactError,
  }] = useMutation(DELETE_CONTACT, {
    onCompleted: (contactData) => {
      if (contactData.deleteContact.id === 0) {
        // eslint-disable-next-line max-len, no-alert
        alert('Could not delete contact because an assigned work order still exists.');
      }
    },
  });
  const handleDelete = () => {
    deleteContact({
      variables: {
        id: data.id || 0,
      },
      refetchQueries: [
        GET_CONTACTS,
        'getContacts',
      ],
    });
  };

  const phoneFormat = (phoneNumber: string) => `${
    phoneNumber.substring(0, 3)}-${
    phoneNumber.substring(3, 6)}-${
    phoneNumber.substring(6)}`;

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.contactInfo}>
          <h5>
            {`${data.name}`}
          </h5>
          {data.id
          && (
          <p className={styles.details}>
            {`${phoneFormat(data.phoneNumber)} | ${data.email}`}
          </p>
          )}
        </div>
        <div className={styles.right}>
          <Button
            variant="icon"
            onClick={() => setIsEditing()}
            key={`set-edit-${data.id}`}
          >
            <Edit />
          </Button>
          <Button
            key={`delete-${data.id}`}
            variant="icon"
            onClick={handleDelete}
            inactive={index === 0}
          >
            <Delete />
          </Button>
          <Alert
            message={
          extractGraphqlErrors(deleteContactError)
          }
          />
        </div>
      </div>
    </div>
  );
}
