import cx from 'classnames';
import React from 'react';
import styles from '../sass/components/PreviousSelector.module.scss';
import Alert from './Alert';
import Loader from './Loader';
import CustomRadio from './Radio';

export type Selection = 'unset' | 'new' | number;

export interface PreviousSelectorProps {
  subtitleNames?: string[];
  data: string[];
  newLabel: string;
  loading: boolean;
  error: boolean;
  name: string;
  selected: Selection;
  onChange: (index: Selection) => void;
  children: React.ReactNode;
  className?: string;
  childClassName?: string;
  hideNewOption?: boolean;
}

export default function PreviousSelector({
  subtitleNames,
  data,
  newLabel,
  children,
  loading,
  name,
  selected,
  onChange,
  className,
  childClassName,
  error,
  hideNewOption = false,
}: PreviousSelectorProps): JSX.Element {
  if (loading) {
    return (
      <div className={cx(className, styles.loading)}>
        <Loader />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={styles.previousSelector}>
        {error && (
          <Alert
            message="Could not load your data, try to add another."
            type="error"
          />
        )}
        {!error && data.length > 0
          && data.map((label, ix) => (
            <CustomRadio
              subtitleLabels={subtitleNames}
              name={name}
              label={label}
              id={`${name}-${ix.toString()}`}
              key={`${name}-${ix.toString()}`}
              value={ix.toString()}
              checked={selected === ix}
              onChange={(s) => onChange(parseInt(s, 10))}
            />
          ))}
        {!hideNewOption
          && (
            <CustomRadio
              name={name}
              label={newLabel}
              value="new"
              id={`${name}-new`}
              key={`${name}-new`}
              checked={selected === 'new' || selected === 'unset'}
              onChange={() => onChange('new')}
            />
          )}
      </div>

      {(selected === 'new' || selected === 'unset')
        && <div className={childClassName}>{children}</div>}
    </div>
  );
}
