import cx from 'classnames';
import { FormEvent, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import Alert from '../components/Alert';
import Button from '../components/Button';
import Field from '../components/Field';
import { Column, Container, Row } from '../components/Grid';
import TextInput from '../components/TextInput';
import { login } from '../features/auth/authSlice';
import styles from '../sass/components/CardPage.module.scss';
import { api } from '../util/api';
import { ROUTE_AUTH_LOGIN, ROUTE_ROOT } from '../util/constants';
import { getFieldErrors } from '../util/getFieldErrors';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [constraints, setConstraints] = useState([]);
  const [error, setError] = useState('');
  const [sendSuccess, setSendSuccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const resetReferrer = params.get('resetReferrer');
  const resetEmail = params.get('resetEmail');

  const handleEmailChange = (event: FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const sendRecoveryEmail = () => {
    setConstraints([]);
    setError('');
    api.post(
      'auth/forgot-password',
      {
        email,
      },
    ).then(() => {
      setSendSuccess(true);
    }).catch((ex) => {
      const { statusCode, message } = ex.response.data;
      if (statusCode === 422) {
        setConstraints(message);
      } else if (statusCode === 400) {
        setError(message);
      } else {
        setError('Whoops, looks like something went wrong!');
      }
    });
  };

  const setUser = (token: string) => {
    dispatch(login({ email, token }));
  };

  const updatePassword = () => {
    setConstraints([]);
    setError('');
    api.post(
      'auth/reset-password',
      {
        email: resetEmail,
        password: newPassword,
        passwordResetToken: resetReferrer,
      },
    ).then((response) => {
      setPasswordResetSuccess(true);
      const token = response.data?.authentication_token;
      if (token) {
        setUser(token);
        navigate(ROUTE_ROOT);
      }
    }).catch((ex) => {
      setPasswordResetSuccess(false);
      const { statusCode, message } = ex.response.data;
      if (statusCode === 422) {
        setConstraints(message);
      } else if (statusCode === 400) {
        setError(message);
      } else {
        setError('Whoops, looks like something went wrong!');
      }
    });
  };

  const handleSetNewPassword = () => {
    updatePassword();
  };

  const handlePasswordChange = (event: FormEvent<HTMLInputElement>) => {
    setNewPassword(event.currentTarget.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const hasAlreadyReset = !!resetReferrer && !!resetEmail;
  const titleCopy = hasAlreadyReset
    ? 'Change Password'
    : 'Reset Password';
  const subtitleCopy = hasAlreadyReset
    ? `Enter a password for you account: ${resetEmail}`
    : 'Don\'t worry, we\'ll email you instructions '
    + 'to reset your password.';
  return (
    <div className={styles.body}>
      <div className={styles.headerWrapperBlue}>
        <h1 className={styles.desktopHeader}>
          {titleCopy}
        </h1>
        <h3 className={styles.mobileHeader}>
          {titleCopy}
        </h3>
        <h5 className={styles.desktopHeader}>
          {subtitleCopy}
        </h5>
        <p className={styles.mobileHeader}>
          {subtitleCopy}
        </p>
      </div>
      <Container>
        <Row>
          {
            sendSuccess ? (
              <Column md={4} mdOffset={4}>
                <Container fluid>
                  <Row>
                    <Column sm={12}>
                      <div className={styles.card}>
                        <p className={styles.sentEmailSuccess}>
                          An email has been sent. Please follow the instructions
                          in the email. If you don&apos;t see it,
                          check your spam folder.
                        </p>
                        <Link
                          className={styles.bottomLink}
                          to={ROUTE_ROOT}
                        >
                          Back to Booking
                        </Link>
                      </div>
                    </Column>
                  </Row>
                </Container>
              </Column>
            ) : (
              <>
                {hasAlreadyReset ? (
                  <Column lg={6} lgOffset={3} md={12} sm={12}>
                    <div className={styles.card}>
                      <Container fluid>
                        <Row>
                          <Column lg={6} md={12} sm={12} xs={12}>
                            <div className={cx(
                              styles.cardColumn,
                              styles.firstCardColumn,
                            )}
                            >
                              <form
                                onSubmit={handleSubmit}
                              >
                                <Field label="New Password" id="new-password">
                                  <TextInput
                                    value={newPassword}
                                    type="password"
                                    onChange={handlePasswordChange}
                                    autocomplete="off"
                                    className={styles.formElement}
                                    errorMessage={
                                      getFieldErrors(constraints, ['password'])
                                    }
                                    success={!!passwordResetSuccess}
                                  />
                                </Field>
                                <Alert message={error} type="error" />
                                <Button
                                  variant="primary"
                                  onClick={handleSetNewPassword}
                                  type="button"
                                  inactive={!newPassword}
                                  className={styles.fullWidthBottomButton}
                                >
                                  Change Password
                                </Button>
                              </form>
                            </div>
                          </Column>
                          <Column lg={6} md={12} sm={12} xs={12}>
                            <div className={cx(styles.centered,
                              styles.cardColumn,
                              styles.lastCardColumn)}
                            >
                              <span className="label">
                                Make sure your password:
                              </span>
                              <ul>
                                <li>is longer than 8 characters</li>
                                <li>does not match your email</li>
                              </ul>
                            </div>
                          </Column>
                        </Row>
                      </Container>
                    </div>
                  </Column>
                ) : (
                  <Column sm={12} md={6} mdOffset={3}>
                    <Container fluid>
                      <Row>
                        <Column xs={12}>
                          <div className={styles.card}>
                            <form
                              onSubmit={handleSubmit}
                            >
                              <Field label="Your email" id="email">
                                <TextInput
                                  value={email}
                                  onChange={handleEmailChange}
                                  placeholder="Email"
                                  errorMessage={
                                    getFieldErrors(constraints, ['email'])
                                  }
                                />
                              </Field>
                              <Alert message={error} type="error" />
                              <Button
                                onClick={sendRecoveryEmail}
                                type="button"
                                inactive={!email}
                                className={styles.fullWidthBottomButton}
                              >
                                Email Me a Recovery Link
                              </Button>
                              <Link
                                className={styles.bottomLink}
                                to={ROUTE_AUTH_LOGIN}
                              >
                                Back to Log In
                              </Link>
                            </form>
                          </div>
                        </Column>
                      </Row>
                    </Container>
                  </Column>
                )}
              </>
            )
          }
        </Row>
      </Container>
    </div>
  );
}
