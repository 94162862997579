/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  EditNotesModalProps,
  EditNotesModals,
  editNotesModalsList,
  Services,
} from '../interfaces/Components';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';

import ModalCloseButton from './ModalCloseButton';
import NotesEditor from './NotesEditor';
import {
  Notes,
} from '../interfaces/WorkOrder';

export default function EditLineItemsModal(
  {
    workOrderDetails, setWorkOrderDetails, onClose, onSubmit,
  }: EditNotesModalProps,
) {
  const [notes, setNotes] = useState<string>('');

  if (workOrderDetails) {
    useEffect(() => {
      if (workOrderDetails?.notes) {
        setNotes(workOrderDetails?.notes);
      } else {
        setNotes('');
      }
    }, [workOrderDetails]);
  }
  const [
    activeModal,
    setActiveModal,
  ] = useState<EditNotesModals>('notes');
  const handleSubmit = () => {
    onSubmit(
      notes,
    );
    onClose();
  };

  const handleChangeActiveModal = (
    modalName: EditNotesModals,
  ) => setActiveModal(modalName);

  const handleModalContinue = () => {
    const currentModalIndex = editNotesModalsList.indexOf(activeModal);
    const nextModal = editNotesModalsList[currentModalIndex + 1];
    handleChangeActiveModal(nextModal);
  };

  const handleModalBack = () => {
    const currentModalIndex = editNotesModalsList.indexOf(activeModal);
    const nextModal = editNotesModalsList[currentModalIndex - 1];
    handleChangeActiveModal(nextModal);
  };

  return (
    <div className={styles.container}>
      <ModalCloseButton onClose={onClose} />
      {activeModal === 'notes' && workOrderDetails && (
        <NotesEditor
          workOrderDetails={workOrderDetails}
          setWorkOrderDetails={setWorkOrderDetails}
          notes={notes}
          setNotes={setNotes}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}
