import { TabDictionary } from '../interfaces/TabDictionary';
import InformationSection from './InformationSection';
import WorkOrderAppointments from './WorkOrderAppointments';
import { WorkOrderStatus } from '../interfaces/WorkOrder';

export default function WorkOrdersInformation() {
  const tabs: TabDictionary = {
    upcomingWorkOrders: {
      desktopValue: 'Upcoming Work Orders',
      mobileValue: 'Upcoming',
      component: <WorkOrderAppointments
        workOrderStatus={WorkOrderStatus.assigned}
        emptyListMsg="No upcoming work orders"
      />,
    },
    pastWorkOrders: {
      desktopValue: 'Completed Work Orders',
      mobileValue: 'Completed',
      component: <WorkOrderAppointments
        workOrderStatus={WorkOrderStatus.complete}
        emptyListMsg="No completed work orders"
      />,
    },
  };

  return <InformationSection tabs={tabs} initialState="upcomingWorkOrders" />;
}
