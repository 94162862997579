import { useMutation, useQuery } from '@apollo/client';
import { ChangeEvent, useState } from 'react';
import { SendPaymentToTerminalProps } from '../interfaces/Components';
import styles from '../sass/components/SendPaymentToTerminal.module.scss';
import {
  GET_READERS_BY_FRANCHISE,
  SEND_PAYMENT_TO_TERMINAL,
  CANCEL_TERMINAL_PAYMENT,
} from '../util/gql';
import Button from './Button';
import CompleteModal from './CompleteModal';
import Field from './Field';
import Loader from './Loader';
import Modal from './Modal';
import ModalCloseButton from './ModalCloseButton';
import Selector from './Selector';

interface Reader {
  id: string
  label: string
  serialNumber: string
}

interface SelectOption {
  value: string
  label: string
}

export default function SendPaymentToTerminal(
  {
    invoiceDetails,
    onClose,
    setErrorModal,
    open,
    setSuccessModal,
    setCurrentReader,
  }: SendPaymentToTerminalProps,
) {
  const [readers, setReaders] = useState<SelectOption[]>([]);
  const [selectedReader, setSelectedReader] = useState<string>('');
  const [errorReadersModal, setErrorReadersModal] = useState<boolean>(false);

  const ListReadersErrorModal = () => (
    <CompleteModal
      currentReader={selectedReader}
      invoiceId={null}
      onClose={onClose}
      open={errorReadersModal}
      title="Terminal Payment"
      // eslint-disable-next-line max-len
      message="There was an error accessing your credit card terminal. Please try again or process a manual payment"
    />
  );

  const { loading: loadingReaders } = useQuery(GET_READERS_BY_FRANCHISE, {
    variables: { franchiseId: invoiceDetails?.franchiseId },
    onCompleted: (data) => {
      const readersList: Reader[] = data?.listReadersByFranchiseId;
      setReaders(readersList.map((reader) => ({
        value: reader.id,
        label: reader.label,
      })));
    },
    onError: () => setErrorReadersModal(true),
    fetchPolicy: 'network-only',
  });

  const [
    sendInvoiceToTerminal,
    { loading: sendPaymentLoading },
  ] = useMutation(SEND_PAYMENT_TO_TERMINAL, {
    onCompleted: () => {
      onClose();
      setSuccessModal();
    },
    onError: () => {
      onClose();
      setErrorModal();
    },
    fetchPolicy: 'network-only',
  });

  const [cancelTerminalPayment] = useMutation(CANCEL_TERMINAL_PAYMENT, {
    fetchPolicy: 'network-only',
  });

  const handleCancelTerminalPayment = async () => {
    const canceledTerminalId = await cancelTerminalPayment({
      variables: {
        readerId: selectedReader,
        invoiceId: invoiceDetails?.id,
      },
    });
    if (canceledTerminalId) {
      onClose();
    }
  };

  const handleSubmit = () => {
    setCurrentReader(selectedReader);
    sendInvoiceToTerminal({
      variables: {
        invoiceId: invoiceDetails?.id,
        readerId: selectedReader,
      },
    });
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { value } = e.target;
    setSelectedReader(value);
  };

  const Loading = () => (
    <div className={styles.empty}>
      <Loader />
      {loadingReaders ? 'Loading Readers' : 'Sending Payment'}
    </div>
  );

  const ModalContainer = () => (
    <div className={styles.modalContainer}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Terminal Payment</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Field id="reader" label="Select your reader">
            <Selector
              name="reader"
              onChange={handleSelectChange}
              value={selectedReader}
              id="reader"
              label="Reader"
              options={readers}
              isRequired
            />
          </Field>
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.button}
              onClick={onClose}
              variant="tertiary"
            >
              Close
            </Button>
            <Button
              className={styles.button}
              inactive={
                selectedReader.length === 0 || invoiceDetails?.id === null
              }
              onClick={handleCancelTerminalPayment}
              variant="primary"
            >
              Cancel Terminal Payment
            </Button>
            <Button
              className={styles.button}
              type="submit"
              inactive={!selectedReader}
            >
              Send to Terminal
            </Button>
          </div>
        </form>
      </div>
    </div>
  );

  const SendPaymentToTerminalForm = () => (
    <Modal
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      {(loadingReaders || sendPaymentLoading)
        ? <Loading /> : <ModalContainer />}
    </Modal>
  );

  if (errorReadersModal) {
    return <ListReadersErrorModal />;
  }

  return <SendPaymentToTerminalForm />;
}
