import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import throttle from 'lodash/throttle';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import './index.scss';
import { ApolloProvider } from '@apollo/client';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { saveState } from './app/localStorage';
import Router from './routes/Router';
import useAuthenticatedApolloClient from './hooks/useAuthenticatedApolloClient';

store.subscribe(throttle(() => {
  saveState({
    postalCode: store.getState().postalCode,
    auth: store.getState().auth,
  });
}, 1000));

interface ApolloClientProps {
  children: React.ReactNode;
}

function Apollo({ children }: ApolloClientProps) {
  const client = useAuthenticatedApolloClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Provider store={store}>
        <BrowserRouter>
          <Apollo>
            <Router />
          </Apollo>
        </BrowserRouter>
      </Provider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
