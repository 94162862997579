import { useEffect, useState } from 'react';
import {
  CancelWorkOrderModalProps,
} from '../interfaces/Components';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import CancelWorkOrder from './CancelWorkOrder';

export default function CancelWorkOrderContainer({
  workOrderDetails,
  setWorkOrderDetails,
  onClose,
  onSubmit,
}: CancelWorkOrderModalProps) {
  const [cancelReason, setCancelReason] = useState<string>('');
  const [
    proofOfCancelLocation,
    setProofOfCancelLocation,
  ] = useState<string>('');

  const handleSubmit = () => {
    onSubmit(
      cancelReason,
      proofOfCancelLocation,
    );
    onClose();
  };

  return (
    <div className={styles.container}>
      {workOrderDetails && (
      <CancelWorkOrder
        proofOfCancelLocation={proofOfCancelLocation}
        setProofOfCancelLocation={setProofOfCancelLocation}
        cancelReason={cancelReason}
        setCancelReason={setCancelReason}
        onClose={onClose}
        onClick={handleSubmit}
      />
      )}
    </div>

  );
}
