/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ServiceType } from '../features/serviceTypes/serviceTypesSlice';

const sortName = (a: string, b: string): number => {
  if (a > b) {
    return 1;
  } if (a < b) {
    return -1;
  }
  return 0;
};

export const sortServices = (serviceList: ServiceType[]) => {
  const arrayForSort = [...serviceList];
  const sortedServices = arrayForSort.sort((a, b) => {
    switch (true) {
      case (a.sortOrder === null && b.sortOrder === null):
        return sortName(a.name, b.name);
      case a.sortOrder === null:
        return 1;
      case b.sortOrder === null:
        return -1;
      case a.sortOrder! < b.sortOrder!:
        return -1;
      case a.sortOrder! > b.sortOrder!:
        return 1;
      case (a.sortOrder === b.sortOrder):
        return sortName(a.name, b.name);
      default:
        break;
    }
    return 0;
  });
  return sortedServices;
};
