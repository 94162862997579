import { ApolloError } from '@apollo/client';

export interface Response {
  data: {
    message: {
      constraints: any;
    }[]
  };
}
export const extractMessages = (response: Response) => {
  const { message } = response.data;
  let errorString = '';

  message.forEach((error: any) => {
    if (error.constraints) {
      errorString += Object.values(error.constraints).join('. ');
    } else if (typeof error === 'string') {
      errorString += error;
    }
  });

  return errorString;
};

export const extractGraphqlErrors = (error?: ApolloError): string => {
  if (error && error.graphQLErrors[0]) {
    const {
      message,
    } = error.graphQLErrors[0].extensions?.response;
    return message || '';
  }

  return '';
};
