import cx from 'classnames';
import { RowProps } from '../interfaces/Components';
import styles from '../sass/components/DetailsRow.module.scss';

export default function DetailsRow({
  title, isItalicText, children, text,
}: RowProps) {
  return (
    <div className={styles.detailsRowContainer}>
      <div className={styles.content}>
        <h4>{title}</h4>
        <p className={cx({ [styles.italicText]: isItalicText })}>{text}</p>
      </div>
      {children}
    </div>
  );
}
