import cx from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import styles from '../sass/components/ApptRow.module.scss';
import Button from './Button';
import { LineItem } from '../interfaces/WorkOrder';
import { useAppDispatch } from '../app/hooks';
import {
  ROUTE_ACCOUNT_APPOINTMENTS,
  ROUTE_ACCOUNT_CARS,
} from '../util/constants';
import { setWorkOrderId, setWorkOrderUnavailabilityReason }
  from '../features/workOrder/workOrderSlice';

interface ApptRowProps {
  workOrderId: number;
  vehicle: string;
  scheduledAt: Date;
  items: [LineItem];
  tab: string;
}

export default function ApptRow({
  workOrderId, vehicle, scheduledAt, items, tab,
}: ApptRowProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const apptItem = items.map((item) => ({
    ...item,
    price: item.discountedPrice && Number.isFinite(item.discountedPrice)
      ? item.discountedPrice : item.price,

  }));

  const [expanded, setExpanded] = useState(false);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const apptDate = new Date(scheduledAt);

  const serviceName = items[0] ? items[0].name : '';

  const totalCost = () => {
    const itemsTotal = apptItem.map((item: LineItem) => (
      // eslint-disable-next-line max-len
      item.quantity * (item.price / 100 + ((item.price * (item.tax / 100000)) / 100))
    ));
    return itemsTotal.reduce((a: number, b: number) => a + b, 0);
  };

  const splitDescriptions = (itemString: string) => itemString.split('\n• ');
  const aptIn = (apptDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);

  const setWorkOrderDetails = () => {
    dispatch(setWorkOrderId(workOrderId));
    dispatch(setWorkOrderUnavailabilityReason('Out of Hours'));
    navigate(`${ROUTE_ACCOUNT_APPOINTMENTS}/${workOrderId}`);
  };

  return (
    <div>
      <div className={styles.date}>
        {new Intl.DateTimeFormat('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }).format(apptDate)}
      </div>
      <div className={styles.apptDetails}>
        <div className={styles.condensedDetails}>

          <div className={styles.mainContent}>
            <h4>{`${serviceName} for ${vehicle}`}</h4>
            {tab === 'upcoming' && (
              <p>
                {`Your Appointment is in ${Math.floor(aptIn)} days`}
              </p>
            )}
          </div>
          <div className={styles.buttons}>
            <Button
              variant="secondary"
              className={styles.detailsButton}
              // onClick={() => setExpanded(!expanded)}
              onClick={setWorkOrderDetails}
            >
              {expanded ? 'Hide Details' : 'See Details'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
