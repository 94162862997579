/* eslint-disable react/button-has-type */
import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import Button from './Button';
import styles from '../sass/components/VehicleInspection.module.scss';

interface CameraAccessProps {
  onCapture: (file: File) => void;
  className?: string;
  buttonLabel?: string;
}

// eslint-disable-next-line max-len
const CameraAccess = forwardRef(({ onCapture, className, buttonLabel = 'Add Picture' }: CameraAccessProps, ref) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    openCamera: () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    },
  }));

  const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onCapture(file);
    }
  };

  return (

    <>
      <Button
        onClick={() => fileInputRef.current?.click()}
        variant="primary"
        className={className}
      >
        {buttonLabel}
      </Button>
      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleCapture}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
    </>
  );
});

export default CameraAccess;
