import { useQuery } from '@apollo/client';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { addAddOn, AddOn, removeAddOn } from '../features/addOns/addOnsSlice';
import styles from '../sass/components/RegularServiceList.module.scss';
import { GET_ADD_ON_PRICES } from '../util/gql';
import { sortServices } from '../util/sortServices';
import Button from './Button';
import Loader from './Loader';
import ProductCard from './ProductCard';

export default function AddOnList() {
  const vehicleId = useAppSelector((state) => state.car.id);
  const { id: customerId } = useAppSelector((state) => state.customer);

  const {
    loading, error, data, refetch,
  } = useQuery(GET_ADD_ON_PRICES, {
    variables: { vehicleId, customerId },
  });

  const dispatch = useAppDispatch();
  const selectedAddOns = useAppSelector((state) => state.addOns.addOns);

  const add = (addon: AddOn) => {
    dispatch(addAddOn(addon));
  };

  if (loading) {
    return (
      <div className={styles.empty}>
        <Loader />
        Loading
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.empty}>
        Oops! We encountered a problem while loading.
        <Button onClick={refetch}>Refresh</Button>
      </div>
    );
  }

  if (data?.getAddOnPrices?.length > 0) {
    const sortedAddOns = sortServices(data.getAddOnPrices);
    return (
      <>
        {sortedAddOns.map((item: AddOn) => (
          <ProductCard
            key={item.id}
            details={item.description}
            title={item.name}
            price={item.value}
            isEstimate={item.isEstimate}
            selected={selectedAddOns.some((addOn) => addOn.id === item.id)}
            onSelect={() => add(item)}
            onRemove={() => dispatch(removeAddOn(item.id))}
            discountedPrice={item.discountedValue}
          />
        ))}
      </>
    );
  }

  return <div>theres nothing here!</div>;
}
