/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { EditPoNumberProps } from '../interfaces/Components';
import styles from '../sass/components/LineItemEditor.module.scss';
import ModalButtonsContainer from './ModalButtonsContainer';
import TextInput from './TextInput';

export default function PoNumberEditor({
  onClose,
  onSubmit,
  workOrderDetails,
  setWorkOrderDetails,
  poNumber,
  setPoNumber,
}: EditPoNumberProps) {
  return (
    <div className={styles.body}>
      <h4>Edit Po Number</h4>
      <br />
      <TextInput
        className={styles.poNumber}
        title=""
        placeholder="PO Number"
        type="text" // Use 'text' for input type instead of 'string'
        onChange={(e) => setPoNumber(e.currentTarget.value)}
        value={poNumber}
      />

      <br />
      <ModalButtonsContainer
        onClose={onClose}
        onClick={onSubmit}
        label="Submit"
      />
    </div>
  );
}
