import { useLazyQuery, useQuery } from '@apollo/client';
import {
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  selectNewVehicle,
  setCar,
  setId,
  setMake,
  setModel,
  setYear,
} from '../features/car/carSlice';
import useAuthentication from '../hooks/useAuthentication';
import styles from '../sass/components/CarSelector.module.scss';
import {
  GET_MAKES_BY_YEAR,
  GET_MODELS_BY_YEAR_AND_MAKE,
  GET_VEHICLES,
  GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR,
  GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR_AND_ENGINE,
  GET_YEARS,
  FIND_ALL_USER_VEHICLES_BY_CURRENT_USER,
  FIND_ALL_USER_VEHICLES_BY_USER_ID,
} from '../util/gql';
import Field from './Field';
import PreviousSelector, { Selection } from './PreviousSelector';
import { ReactComponent as Search } from '../images/search.svg';
import TextInput from './TextInput';
import Selector from './Selector';
import { decodeVin } from '../util/decodeVin';
import { UserVehicle } from '../interfaces/UserVehicle';
import { Vehicle } from '../interfaces/Vehicle';
import { Subscription } from '../interfaces/Subscription';
import Button from './Button';

export default function CarSelector() {
  const dispatch = useAppDispatch();
  const { loggedIn: isLoggedIn } = useAuthentication();
  const car = useAppSelector((state) => state.car);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[]>([]);
  const [exactMatch, setExactMatch] = useState<boolean>(false);
  const [vinDecoded, setVinDecoded] = useState<boolean>(false);
  const [searchMode, setSearchMode] = useState('MakeModel');

  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const customerId = useAppSelector((state) => state.customer.id);

  const { data: yearData } = useQuery(GET_YEARS);

  const [loadMakeData, { data: makeData }] = useLazyQuery(GET_MAKES_BY_YEAR);

  useEffect(() => {
    if (car.year !== '') {
      loadMakeData({
        variables: {
          year: car.year,
        },
      });
    }
  }, [car.year, loadMakeData]);

  const [
    loadModelData,
    { data: modelData },
  ] = useLazyQuery(GET_MODELS_BY_YEAR_AND_MAKE);

  useEffect(() => {
    if (car.year !== '' && car.make !== '') {
      loadModelData({
        variables: {
          year: car.year,
          make: car.make,
        },
      });
    }
  }, [car.year, car.make, loadModelData]);

  const [
    loadVehicleData,
    { data: vehicleData },
  ] = useLazyQuery(GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR);

  const [
    loadAllVehicleData,
    { data: allVehicleData },
  ] = useLazyQuery(GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR_AND_ENGINE);

  useEffect(() => {
    if (car.make !== '' && car.model !== '' && car.year !== '') {
      loadVehicleData({
        variables: {
          make: car.make,
          model: car.model,
          year: parseInt(car.year, 10),
        },
      });
    }
  }, [car.make, car.model, car.year, loadVehicleData]);

  const {
    loading: userVehicleLoading,
    error: userVehicleError,
  } = useQuery(GET_VEHICLES, {
    skip: !isLoggedIn,
    fetchPolicy: 'network-only',
  });

  const getActiveSubscriptionId = (
    userVehicleId: number,
    subscriptions: Subscription[] = [],
  ) => {
    const userVehicleSubscription = subscriptions.find(
      (subscription) => subscription.userVehicleId
        === userVehicleId
        && subscription.status === 'ACTIVE',
    );
    return userVehicleSubscription ? userVehicleSubscription.id : undefined;
  };

  const createVehicleArray = (
    userVehicles: UserVehicle[],
  ): Vehicle[] => userVehicles.map((item) => ({
    id: item.vehicle.id,
    make: item.vehicle.make,
    model: item.vehicle.model,
    year: item.vehicle.year,
    userVehicleId: item.id,
    vin: (item.vin ? item.vin : ''),
    name: (item.name ? item.name : ''),
    recommendedOil: item.vehicle.recommendedOil,
    oilVolume: item.vehicle.oilVolume,
    activeSubscriptionId: getActiveSubscriptionId(item.id, item.subscriptions),
  }));

  const { data: userVehicles } = useQuery(
    FIND_ALL_USER_VEHICLES_BY_CURRENT_USER, {
      onCompleted: (data) => {
        const arr = createVehicleArray(data.findAllUserVehiclesByCurrentUser);
        setVehicles(arr);
      },
      skip: !isLoggedIn
      || currentUser.roles?.includes('manager')
      || currentUser.roles?.includes('technician'),
      fetchPolicy: 'network-only',
    },
  );

  const { data: userVehiclesById } = useQuery(
    FIND_ALL_USER_VEHICLES_BY_USER_ID, {
      variables: {
        userId: customerId,
      },
      onCompleted: (data) => {
        const arr = createVehicleArray(data.findAllUserVehiclesByUserId);
        setVehicles(arr);
      },
      skip: (!currentUser.roles?.includes('manager')
      && !currentUser.roles?.includes('technician')),
      fetchPolicy: 'network-only',
    },
  );

  const selectMake = useCallback((make) => {
    if (make !== car.make) {
      dispatch(setMake(make));
    }
  }, [car.make, dispatch]);

  const handleMakeChange = (event: FormEvent<HTMLSelectElement>) => {
    selectMake(event.currentTarget.value);
  };

  const selectModel = useCallback((model) => {
    if (model !== car.model) {
      dispatch(setModel(model));
    }
  }, [car.model, dispatch]);

  const handleModelChange = (event: FormEvent<HTMLSelectElement>) => {
    selectModel(event.currentTarget.value);
  };

  const selectYear = useCallback((year) => {
    if (year !== car.year) {
      dispatch(setYear(year));
    }
  }, [car.year, dispatch]);

  const handleYearChange = (event: FormEvent<HTMLSelectElement>) => {
    selectYear(event.currentTarget.value);
  };

  const selectId = useCallback((id) => {
    if (id !== car.id) {
      dispatch(setId(id));
    }
  }, [car.id, dispatch]);

  const handleIdChange = (event: FormEvent<HTMLSelectElement>) => {
    selectId(parseInt(event.currentTarget.value, 10));
  };

  const handleSelectVehicle = (selected: Selection) => {
    if (selected === 'unset' || selected === 'new') {
      dispatch(selectNewVehicle());
    } else {
      const arrVehicles = filter ? filteredVehicles : vehicles;
      dispatch(setCar({
        ...arrVehicles[selected],
        selected,
      }));
      setVinDecoded(false);
    }
  };

  useEffect(() => {
    if (car.selected === 'unset' && vehicles.length > 0
        && (userVehicles || userVehiclesById)) {
      dispatch(setCar({
        ...vehicles[0],
        selected: 0,
      }));
    }
  }, [dispatch, car.selected, vehicles, userVehicles, userVehiclesById]);

  const handleFilterChange = (value: string) => {
    setFilter(value);
  };

  const handleNameInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    dispatch(setCar({ ...car, name: value }));
  };

  useEffect(() => {
    dispatch(setCar({
      year: '',
      make: '',
      model: '',
      id: null,
      vin: '',
      selected: 'new',
      name: '',
    }));
    setFilteredVehicles([]);
    setExactMatch(false);
    setVinDecoded(false);
  }, [filter, dispatch]);

  useEffect(() => {
    const filtered = vehicles.filter((vehicle) => vehicle.vin
      ?.toLowerCase()
      .includes(filter.toLowerCase()));
    setFilteredVehicles(filtered);

    if (filtered.length === 1 && filter.length === 17) {
      setExactMatch(true);
      dispatch(setCar({
        ...filtered[0],
        selected: 0,
      }));
    } else {
      setExactMatch(false);
    }
  }, [filter, vehicles, dispatch]);

  useEffect(() => {
    if (filter.length === 17 && filteredVehicles.length === 0
      && car.vin !== filter) {
      const checkAndSetVehicle = async (decodedVehicle: any) => {
        const {
          ModelYear: rawYear,
          Make: make,
          Model,
          DisplacementL,
          EngineCylinders,
        } = decodedVehicle;

        const model = Model.toLowerCase().includes('promaster') ? 'promaster'
          : Model;

        const engine = `${DisplacementL}L ${EngineCylinders}-cyl`;
        const year = parseInt(rawYear, 10);
        let existingVehicle = null;
        if (year) {
          existingVehicle = await loadAllVehicleData({
            variables: {
              make,
              model,
              year,
              engine,
            },
          });
        }

        if (existingVehicle && existingVehicle.data
           && existingVehicle.data.getVehiclesByMakeModelAndYearAndEngine) {
          const {
            year: existingYear,
            make: existingMake,
            model: existingModel,
          } = existingVehicle.data.getVehiclesByMakeModelAndYearAndEngine;

          dispatch(
            setCar({
              ...car,
              year: existingYear,
              make: existingMake,
              model: existingModel,
              vin: filter,
            }),
          );
        } else {
          const notListedMake = 'Vehicle Not listed';
          const notListedModel = 'NOT LISTED';
          const notListedYear = 2024;

          dispatch(
            setCar({
              ...car,
              year: notListedYear.toString(),
              make: notListedMake,
              model: notListedModel,
              vin: filter,
              selected: 'new',
            }),
          );
        }

        setVinDecoded(true);
      };
      if (filter.length === 17 && car.vin !== filter) {
        decodeVin(filter).then((decodedVehicle) => {
          if (decodedVehicle) {
            checkAndSetVehicle(decodedVehicle);
          }
        });
      }
    } else {
      setExactMatch(false);
    }
  }, [filter, filteredVehicles, dispatch, car]);

  const vehicleNames = filteredVehicles.map((vehicle: {
    make: string; model: string; year: string; vin?: string
  }) => `${vehicle.make} ${vehicle.model} ${vehicle.year}`);

  const vinNames = filteredVehicles.map((vehicle: {
    vin?: string
  }) => `${vehicle.vin}`);

  return (
    <div className={styles.carSelector}>
      <h4>Select a vehicle to service</h4>
      <p>
        Provide your vehicle information to get an
        exact quote for our services.
      </p>
      <div className={styles.toggleContainer}>
        <Button
          onClick={() => {
            setSearchMode('MakeModel');
            dispatch(selectNewVehicle());
            handleFilterChange('');
          }}
          className={searchMode === 'MakeModel' ? styles.active : ''}
        >
          Make & Model
        </Button>
        <Button
          onClick={() => {
            setSearchMode('VIN');
            dispatch(selectNewVehicle());
          }}
          // onClick={() => setSearchMode('VIN')}
          className={searchMode === 'VIN' ? styles.active : ''}
        >
          VIN Lookup
        </Button>
      </div>
      {searchMode === 'VIN' && (
      <>
        <br />
        <p>Enter VIN to search for a vehicle</p>
        <div className={styles.headerContainer}>
          <div className={styles.searchBar}>
            <div className={styles.magnifyingGlass}>
              <Search />
            </div>
            <TextInput
              placeholder="Enter VIN to search vehicle"
              maxlength={17}
              value={filter}
              onChange={(e) => handleFilterChange(e.currentTarget.value)}
            />
          </div>
        </div>
        <PreviousSelector
          subtitleNames={vinNames}
          data={vehicleNames}
          newLabel="Add another vehicle"
          loading={userVehicleLoading}
          error={!!userVehicleError}
          name="vehicle"
          selected={car.selected}
          onChange={handleSelectVehicle}
          hideNewOption={exactMatch}
        >
          <Field
            id="year"
            label="Year"
          >
            <Selector
              id="year"
              label="Year"
              value={car.year}
              onChange={handleYearChange}
              disabled={car.year === ''}
              options={yearData?.getAllVehicleYears?.map((m: {
              year: string;
            }) => ({
                value: m.year,
                label: m.year,
              })) || []}
            />
          </Field>
          <Field
            id="make"
            label="Make"
          >
            <Selector
              id="make"
              label="Make"
              value={car.make}
              disabled={car.year === ''}
              onChange={handleMakeChange}
              options={makeData?.getMakesByYear?.map((m: {
              make: string;
            }) => ({
                value: m.make,
                label: m.make,
              })) || []}
            />
          </Field>
          <Field
            id="model"
            label="Model"
          >
            <Selector
              id="model"
              label="Model"
              value={car.model}
              disabled={car.make === ''}
              onChange={handleModelChange}
              options={modelData?.getModelsByYearAndMake?.map((m: {
              model: string;
            }) => ({
                value: m.model,
                label: m.model,
              })) || []}
            />
          </Field>
          <Field
            id="engine"
            label="Engine"
          >
            <Selector
              id="engine"
              label="Engine"
              value={car.id || ''}
              disabled={car.make === '' || car.model === '' || car.year === ''}
              onChange={handleIdChange}
              options={vehicleData?.getVehiclesByMakeModelAndYear?.map((v: {
              id: number;
              engine: string;
            }) => ({
                value: v.id,
                label: v.engine,
              })) || []}
            />
          </Field>
        </PreviousSelector>
      </>
      )}
      {searchMode === 'MakeModel' && (
        <>

          <PreviousSelector
            subtitleNames={vinNames}
            data={vehicleNames}
            newLabel="Add another vehicle"
            loading={userVehicleLoading}
            error={!!userVehicleError}
            name="vehicle"
            selected={car.selected}
            onChange={handleSelectVehicle}
            hideNewOption={exactMatch}
          >
            <p className={styles.subtitle}>
              Select the year, make and model and engine of your vehicle.
            </p>
            <Field
              id="year"
              label="Year"
            >
              <Selector
                id="year"
                label="Year"
                value={car.year}
                onChange={handleYearChange}
                options={yearData?.getAllVehicleYears?.map((m: {
              year: string;
            }) => ({
                  value: m.year,
                  label: m.year,
                })) || []}
              />
            </Field>
            <Field
              id="make"
              label="Make"
            >
              <Selector
                id="make"
                label="Make"
                value={car.make}
                disabled={car.year === ''}
                onChange={handleMakeChange}
                options={makeData?.getMakesByYear?.map((m: {
              make: string;
            }) => ({
                  value: m.make,
                  label: m.make,
                })) || []}
              />
            </Field>
            <Field
              id="model"
              label="Model"
            >
              <Selector
                id="model"
                label="Model"
                value={car.model}
                disabled={car.make === ''}
                onChange={handleModelChange}
                options={modelData?.getModelsByYearAndMake?.map((m: {
              model: string;
            }) => ({
                  value: m.model,
                  label: m.model,
                })) || []}
              />
            </Field>
            <Field
              id="engine"
              label="Engine"
            >
              <Selector
                id="engine"
                label="Engine"
                value={car.id || ''}
                disabled={car.make === '' || car.model === '' || car.year === ''}
                onChange={handleIdChange}
                options={vehicleData?.getVehiclesByMakeModelAndYear?.map((v: {
              id: number;
              engine: string;
            }) => ({
                  value: v.id,
                  label: v.engine,
                })) || []}
              />
            </Field>
          </PreviousSelector>
        </>
      )}

      {car.id && (
      <Field
        id="name"
        label="Name"
      >
        <TextInput
          id="name"
          title="Optional Vehicle Information"
          placeholder="Enter Name, Plate Nr. or Fleet Identifier"
          type="text"
          value={car.name}
          disabled={car.make === '' || car.model === '' || car.year === ''}
          onChange={handleNameInputChange}
        />
      </Field>
      )}
    </div>
  );
}
