import Popover from '@mui/material/Popover';
import cx from 'classnames';
import { MouseEvent, ReactNode, useState } from 'react';
import styles from '../sass/components/Dropdown.module.scss';

interface DropdownProps {
  tetherComponent: ReactNode;
  id: string;
  children?: ReactNode;
  className?: string;
}

export default function Dropdown({
  tetherComponent,
  id,
  children,
  className,
}: DropdownProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget || null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className={cx(styles.dropdown, className)}>
      <button
        id="open-dropdown-button"
        type="button"
        onClick={handleClick}
        className={styles.dropdownTrigger}
      >
        {tetherComponent}
      </button>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        className={styles.dropdownBody}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ square: true }}
      >
        <div className={styles.dropdownContainer}>
          {children}
        </div>
      </Popover>
    </div>
  );
}
